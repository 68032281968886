import React from 'react';

function getStatus(status) {
    switch (status) {
        case 'Pending':
            return { initials: 'Pending', className: 'badge bg-warning' };
        case 'Delivered':
            return { initials: 'Delivered', className: 'badge bg-primary' };
        case 'Cancel':
            return { initials: 'Cancel', className: 'badge bg-secondary' };
        case 'Not delivered':
            return { initials: 'Not delivered', className: 'badge bg-danger' };
        default:
            return { initials: 'Unknown', className: 'badge bg-info' };
    }
}

const DeliveryStatus = ({ status }) => {
    const { initials, className } = getStatus(status);

    return (
        <div className={`${className}`} style={styles.back}>
            {initials}
        </div>
    );
};

const styles = {
    back: {
        width: '150px',
        marginRight: 'auto',
        borderRadius: '10px',
        marginLeft: 'auto',
        height: '40px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '12px',
        fontWeight: '300',
    },
};

export default DeliveryStatus;
