import React, { useState } from "react";
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Card from "../../../components/card";
import { _post } from "../../../apiClient";

// img
import auth09 from "../../../assets/images/auth/09.png";
import logo from "../../../assets/images/logo.svg";

const SignUp = () => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  let history = useHistory();

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/; // Simple validation for a 10-digit phone number
    return phoneRegex.test(phone);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setValidationMessage(""); 

    if (!fname || !lname || !phone || !password || !confirmPassword) {
      setValidationMessage("All fields are required");
      return;
    }
    
    if (!validatePhone(phone)) {
      setValidationMessage("Please enter a valid 10-digit phone number");
      return;
    }

    if (password !== confirmPassword) {
      setValidationMessage("Passwords do not match");
      return;
    }

    try {
      const newData = { fname, lname, phone, password };
      const response = await _post("/signup", newData);
      if (response.data.status === 'success') {
        history.push("/auth/sign-in");
      } else {
        setValidationMessage(response.data.msg || "Signup failed");
      }
    } catch (error) {
      setValidationMessage("Error during sign up, please try again");
      console.error("Error during sign up:", error);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handlePhoneInput = (e) => {
    const value = e.target.value;
    const regex = /^[0-9\b]+$/; // Allow only numbers and backspace
    if (value === '' || regex.test(value)) {
      setPhone(value);
    }
  };

  return (
    <>
      <section className="container-fluid bg-circle-login">
        <Row className="align-items-center">
          <Col md="12" lg="7" xl="4">
            <div className="d-flex justify-content-center mb-0">
              <Card.Body className="mt-5">
                <img src={logo} className="img-fluid logo-img" alt="logo" />
                <h2 className="mb-2 text-center">Sign Up</h2>
                <p className="text-center">Create your SRT Food account.</p>
                <Form onSubmit={handleSignUp}>
                  <Row>
                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="fname">First Name</Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control-sm"
                          id="fname"
                          aria-describedby="fname"
                          placeholder="Enter a First Name"
                          value={fname}
                          onChange={(e) => setFname(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="lname">Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control-sm"
                          id="lname"
                          aria-describedby="lname"
                          placeholder="Enter a Last Name"
                          value={lname}
                          onChange={(e) => setLname(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="phone">Phone No.</Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control-sm"
                          id="phone"
                          aria-describedby="phone"
                          maxLength={10}
                          placeholder="Enter a Mobile Number"
                          value={phone}
                          onChange={handlePhoneInput}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="password">Password</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type={passwordVisible ? "text" : "password"}
                            className="form-control-sm"
                            id="password"
                            aria-describedby="password"
                            placeholder="Enter a password "
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="confirm-password">Confirm Password</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type={passwordVisible ? "text" : "password"}
                            className="form-control-sm"
                            id="confirm-password"
                            placeholder="Enter a Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col lg="12" className="d-flex justify-content-center">
                      {validationMessage && (
                        <p className="text-danger">{validationMessage}</p>
                      )}
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-center">
                    <Button type="submit" variant="primary">
                      Sign Up
                    </Button>
                  </div>
                  <p className="mt-3 text-center">
                    Already have an Account{" "}
                    <Link to="/auth/sign-in" className="text-underline">
                      Sign In
                    </Link>
                  </p>
                </Form>
              </Card.Body>
            </div>
          </Col>
          <Col
            md="12"
            lg="5"
            xl="8"
            className="d-lg-block d-none vh-100 overflow-hidden"
          >
            <img src={auth09} className="img-fluid sign-in-img" alt="sign-in" />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default SignUp;
