import React from 'react';

function getStatus(status) {
  switch (status) {
    case 'New user':
      return { initials: 'New user', backgroundColor: '#4285F4' }; 
    case 'Subscribed user':
      return { initials: 'Subscribed user', backgroundColor: '#34A853' }; 
    case 'Expired user':
      return { initials: 'Expired user', backgroundColor: '#EA6A12' }; 
    case 'Unsubscribed user':
      return { initials: 'Unsubscribed user', backgroundColor: '#EA4335' }; 
    default:
      return { initials: 'Unknown', backgroundColor: '#808080' }; 
  }
}

const Status = ({ status }) => {
  const { initials, backgroundColor } = getStatus(status);

  return (
    <div style={{ ...styles.back, backgroundColor }}>
      {initials}
    </div>
  );
};

const styles = {
  back: {
    width: '150px',
    marginRight: 'auto',
    borderRadius:'10px',
    marginLeft: 'auto',
    height: '40px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    fontWeight: '300',
  },
};

export default Status;
