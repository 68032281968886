import React, { useState } from "react";
import { Row, Col, Form, Button, InputGroup, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Card from "../../../components/card";
import { _post } from "../../../apiClient";
import withAuth from "./withAuth";

// img
import fav from "../../../assets/images/Login/SR-Logo.png";
import auth09 from "../../../assets/images/auth/09.png";

// Validation Schema
const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits')
    .required('Phone number is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
});

// SignIn Component
const SignIn = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSignIn = async (values) => {
    setValidationMessage(""); 
    setLoading(true);

    try {
      const response = await _post("login", values);
      if (response.data.status === 'success') {
        localStorage.setItem("role", response.data.data.role);
        localStorage.setItem("token", response.data.data.token);
        if(response.data.data.role === 'Admin'){
          window.location.reload();
          history.push("/admin");
          return;
        }
        window.location.reload();
        history.push("/");
      } else {
        setValidationMessage("Login failed: " + response.data.msg);
      }
    } catch (error) {
      setValidationMessage("Error during sign in: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <section className="container-fluid bg-circle-login" id="auth-sign">
        <Row className="align-items-center">
          <Col md="6" lg="6" xl="6">
            <Card.Body className="login-cen">
              <div className="logo-main">
                <img src={fav} className="img-fluid logo-img" alt="img4" />
              </div>
              <h2 className="mb-2 text-center">Sign In</h2>
              <p className="text-center">Sign in to stay connected.</p>
              <Card>
                <Card.Body>
                  <Formik
                    initialValues={{ phone: '', password: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleSignIn}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      errors,
                      touched,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col lg="12">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="phone" className="form-title">Mobile</Form.Label>
                              <Form.Control
                                type="tel"
                                className={`form-control-sm ${touched.phone && errors.phone ? 'is-invalid' : ''}`}
                                id="phone"
                                name="phone"
                                aria-describedby="phone"
                                placeholder="Enter a Mobile Number"
                                value={values.phone}
                                maxLength={10}
                                onChange={(e) => {
                                  const value = e.target.value.replace(/\D/g, ''); // Allow only digits
                                  handleChange({
                                    target: {
                                      name: 'phone',
                                      value,
                                    },
                                  });
                                }}
                                onBlur={handleBlur}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.phone}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg="12">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="password" className="form-title">Password</Form.Label>
                              <InputGroup>
                                <Form.Control
                                   type={passwordVisible ? "text" : "password"}
                                   className={`form-control-sm ${touched.password && errors.password ? 'is-invalid' : ''}`}
                                   id="password"
                                   name="password"
                                   aria-describedby="password"
                                   placeholder="Enter a Password"
                                   value={values.password}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   isInvalid={touched.password && !!errors.password}
                                />
                                <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                                  {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                  {errors.password}
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Col lg="12" className="d-flex justify-content-center">
                          {validationMessage && (
                            <p className="text-danger">{validationMessage}</p>
                          )}
                        </Col>
                        <div className="d-flex justify-content-center pt-5">
                          <Button type="submit" variant="saffron" disabled={isSubmitting || loading}>
                            {loading ? <Spinner animation="border" size="sm" /> : "Sign In"}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Card.Body>
          </Col>
          <Col
            md="6"
            lg="6"
            xl="6"
            className="d-lg-block d-none vh-100 overflow-hidden"
          >
          </Col>
        </Row>
      </section>
    </>
  );
};

export default withAuth(SignIn);
