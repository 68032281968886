import { Spinner } from 'react-bootstrap';
import "../../assets/scss/custom/components/menu-style/loader.scss";

// ...

const Loader = () => (
    <div className="loader-container">
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    </div>
);

export default Loader;
