import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { DatePicker } from 'react-rainbow-components';
import dayjs from 'dayjs';

const Toggle = ({
  isSubscribed,
  onSubscribe,
  onUnsubscribe,
  userName,
  userPhone,
  onEditUser,
  errorMessage,
  selectedPlanoption,
  onPlanChange,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [action, setAction] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');
  const [startDate, setStartDate] = useState(dayjs().add(1, 'day').toDate());
  const [endDate, setEndDate] = useState(dayjs().add(1, 'day').toDate());

  const handleToggleClick = () => {
    setShowPopup(true);
    setAction(isSubscribed ? 'unsubscribe' : 'subscribe');
  };

  const handleConfirmAction = async () => {
    try {
      if (action === 'subscribe') {
        const params = {
          plan: selectedPlan,
          ...(selectedPlan === 'Custom' && { startDate, endDate })
        };

        await onSubscribe(setStatusMessage, params);
      } else if (action === 'unsubscribe') {
        await onUnsubscribe(setStatusMessage);
      }
    } catch (error) {
      setStatusMessage('Action failed. Please try again.');
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setStatusMessage('');
    setSelectedPlan('');
    setStartDate(dayjs().add(1, 'day').toDate());
    setEndDate(dayjs().add(1, 'day').toDate());
  };

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    onPlanChange(plan); // Propagate the selected plan type to the parent component
  };

  const handleEdit = () => {
    onEditUser();
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Form>
        <Form.Check
          type="switch"
          id="custom-switch"
          checked={isSubscribed}
          readOnly
          onClick={handleToggleClick}
          style={{ display: 'flex', justifyContent: 'center'}}
        />
      </Form>
      <Modal show={showPopup} onHide={handleClosePopup} centered>
        <Modal.Header closeButton>
          <Modal.Title>{statusMessage ? 'Status' : 'Confirm Action'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {statusMessage ? (
            <p>{statusMessage}</p>
          ) : (
            <>
              <p>
                {action === 'subscribe'
                  ? `Are you sure you want to subscribe this User? ${userName} - (${userPhone})?`
                  : `Are you sure you want to unsubscribe this User? ${userName} - (${userPhone})?`}
              </p>
              {action === 'subscribe' && (
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label="Monthly"
                    name="planType"
                    id="monthly"
                    value="Monthly"
                    onChange={() => handlePlanSelect('Monthly')}
                    checked={selectedPlan === 'Monthly'}
                  />
                  <Form.Check
                    type="radio"
                    label="Daily"
                    name="planType"
                    id="daily"
                    value="Daily"
                    onChange={() => handlePlanSelect('Daily')}
                    checked={selectedPlan === 'Daily'}
                  />
                  <Form.Check
                    type="radio"
                    label="Custom"
                    name="planType"
                    id="custom"
                    value="Custom"
                    onChange={() => handlePlanSelect('Custom')}
                    checked={selectedPlan === 'Custom'}
                  />
                </Form.Group>
              )}
              {selectedPlan === 'Custom' && action === 'subscribe' && (
                <div className="d-flex m-3">
                  <Form.Group className="form-group m-1">
                    <Form.Label htmlFor="startDate">Start Date</Form.Label>
                    <DatePicker
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      minDate={dayjs().add(1, 'day').toDate()}
                      placeholder="Select Start Date"
                    />
                  </Form.Group>
                  <Form.Group className="form-group m-1">
                    <Form.Label htmlFor="endDate">End Date</Form.Label>
                    <DatePicker
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      minDate={startDate || dayjs().add(1, 'day').toDate()}
                      placeholder="Select End Date"
                    />
                  </Form.Group>
                </div>
              )}
            </>
          )}
          {errorMessage && <p style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</p>}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          {!statusMessage && (
            <>
              <Button variant="primary" onClick={handleConfirmAction} className="me-2">
                {action === 'subscribe' ? 'Subscribe' : 'Unsubscribe'}
              </Button>
              <Button variant="secondary" onClick={handleClosePopup}>
                Cancel
              </Button>
            </>
          )}
          {statusMessage && !statusMessage.includes('Successfully') && (
            <Button variant="success" onClick={handleEdit}>
              Add
            </Button>
          )}
          {statusMessage && (
            <Button variant="secondary" onClick={handleClosePopup}>
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Toggle;
