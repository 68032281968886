import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Table, Modal, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Card from '../../components/card'; // Assuming this is your custom Card component
import { _get, _post } from '../../apiClient';
import Loader from '../dashboard/Loader';
import DateFormats from '../dashboard/app/Pipe/dateFormater';

const SubscriptionList = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [responseModalShow, setResponseModalShow] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [filter, setFilter] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const token = localStorage.getItem("token");

    useEffect(() => {
        fetchSubscription();
    }, [token, currentPage]);

    const fetchSubscription = async () => {
        setIsLoading(true);

        if (token) {
            _get(`/subscriberequest?page=${currentPage}&limit=${itemsPerPage}`, token)
                .then(response => {
                    if (response.data.status === 'success') {
                        setSubscriptions(response.data.data);
                        setFilteredSubscriptions(response.data.data);
                    } else {
                        setResponseMessage(response.data.msg);
                        setResponseModalShow(true);
                    }
                })
                .catch(() => {
                    setResponseMessage('Failed to fetch subscriptions');
                    setResponseModalShow(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleFilterChange = (event) => {
        const selectedFilter = event.target.value;
        setFilter(selectedFilter);
        if (selectedFilter === 'all') {
            setFilteredSubscriptions(subscriptions);
        } else {
            setFilteredSubscriptions(subscriptions.filter(sub => sub.planType === selectedFilter));
        }
        setCurrentPage(1); // Reset to first page when filter changes
    };

    const handleAcceptClick = (subscription) => {
        setSelectedSubscription(subscription);
        setShowAcceptModal(true);
    };

    const handleRejectClick = (subscription) => {
        setSelectedSubscription(subscription);
        setShowRejectModal(true);
    };

    const acceptTooltip = (
        <Tooltip id="view-tooltip">
            Accept
        </Tooltip>
    );
    
    const rejectTooltip = (
        <Tooltip id="view-tooltip">
            Reject
        </Tooltip>
    );

    const handleAccept = async () => {
        try {
            const response = await _post('/suscribe', { value: true, phone: selectedSubscription.user.phone, requestId: selectedSubscription.requestId, status: 'accept' }, token);
            if (response.data.value) {
                setResponseMessage('Subscription accepted successfully');
                // Optionally refresh the list
                setSubscriptions(subscriptions.filter(sub => sub.requestId !== selectedSubscription.requestId));
                setFilteredSubscriptions(filteredSubscriptions.filter(sub => sub.requestId !== selectedSubscription.requestId));
            } else {
                setResponseMessage(response.data.msg);
            }
        } catch (error) {
            setResponseMessage('Failed to accept subscription');
        } finally {
            setShowAcceptModal(false);
            setResponseModalShow(true);
        }
    };

    const handleReject = async () => {
        try {
            const rejectRequest = await _post('/suscribe', { phone: selectedSubscription.user.phone, requestId: selectedSubscription.requestId, status: 'reject' }, token);
            setResponseMessage(rejectRequest.data.msg);
            // Optionally refresh the list
            setSubscriptions(subscriptions.filter(sub => sub.requestId !== selectedSubscription.requestId));
            setFilteredSubscriptions(filteredSubscriptions.filter(sub => sub.requestId !== selectedSubscription.requestId));
        } catch (error) {
            setResponseMessage('Failed to reject subscription');
        } finally {
            setShowRejectModal(false);
            setResponseModalShow(true);
        }
    };

    const handleResponseModalClose = () => {
        setResponseModalShow(false);
        fetchSubscription();
        setResponseMessage('');
    };

    const totalPages = Math.ceil(filteredSubscriptions.length / itemsPerPage);
    const currentData = filteredSubscriptions.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className='container'>
                <Row>
                    <Col lg='12' md='12'>
                        <Card>
                            <Card.Header className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                                <div className="mb-3 mb-sm-0">
                                    <Card.Header.Title>
                                        <h4 className="card-title mb-0 daily-tilte">Subscription Request List</h4>
                                    </Card.Header.Title>
                                </div>
                                <div className="d-flex align-items-center">
                                    <Form.Select className="w-auto me-3 mb-3 mb-sm-0" value={filter} onChange={handleFilterChange}>
                                        <option value="all">All</option>
                                        <option value="Daily">Daily</option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Custom">Custom</option>
                                    </Form.Select>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table id="user-list-table" className="table" role="grid" data-toggle="data-table">
                                        <thead>
                                            <tr className="ligth text-center">
                                                <th className="text-center">Name</th>
                                                <th className="text-center">Phone</th>
                                                <th className="text-center">Plan</th>
                                                <th className="text-center">Start Date</th>
                                                <th className="text-center">End Date</th>
                                                <th className="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentData.length === 0 ? (
                                                <tr>
                                                    <td colSpan="6" className="text-center no-date pt-5">No Requests found</td>
                                                </tr>
                                            ) : (
                                                currentData.map(subscription => (
                                                    <tr key={subscription.requestId}>
                                                        <td className="text-center">
                                                            {subscription.user ? `${subscription.user.fname} ${subscription.user.lname}` : 'N/A'}
                                                        </td>
                                                        <td className="text-center">
                                                            {subscription.user ? subscription.user.phone : 'N/A'}
                                                        </td>
                                                        <td className="text-center">{subscription.planType}</td>
                                                        <td className="text-center">{new Date(subscription.startDate).toLocaleDateString('en-GB')}</td>
                                                        <td className="text-center">{new Date(subscription.endDate).toLocaleDateString('en-GB')}</td>
                                                        <td className="d-flex justify-content-center">
                                                        <OverlayTrigger placement="top" overlay={acceptTooltip}>
                                                            <Button
                                                                className="btn btn-sm btn-icon btn-success m-1"
                                                                onClick={() => handleAcceptClick(subscription)}
                                                            >
                                                                <span className="btn-inner">
                                                                    <svg
                                                                        width="20"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        stroke="currentColor"
                                                                    >
                                                                        <path
                                                                            d="M5 13l4 4L19 7"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            </Button>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger placement="top" overlay={rejectTooltip}>
                                                            <Button
                                                                className="btn btn-sm btn-icon btn-danger m-1"
                                                                onClick={() => handleRejectClick(subscription)}
                                                            >
                                                                <span className="btn-inner">
                                                                    <svg
                                                                        width="20"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        stroke="currentColor"
                                                                    >
                                                                        <path
                                                                            d="M6 18L18 6M6 6l12 12"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            </Button>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </Table>
                                    <Card.Footer>
                                        {filteredSubscriptions.length > itemsPerPage && (
                                            <div className="d-flex justify-content-center">
                                                <Button onClick={prevPage} disabled={currentPage === 1} className="m-2">
                                                    Prev
                                                </Button>
                                                <div className="text-center m-2 p-2">
                                                    {currentPage}/{totalPages}
                                                </div>
                                                <Button
                                                    onClick={nextPage}
                                                    disabled={currentPage === totalPages}
                                                    className="m-2"
                                                >
                                                    Next
                                                </Button>
                                            </div>
                                        )}
                                    </Card.Footer>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                </div>
            )}

            {/* Accept Confirmation Modal */}
            <Modal show={showAcceptModal} onHide={() => setShowAcceptModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Accept</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to accept this subscription request?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAcceptModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={handleAccept}>
                        Accept
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Reject Confirmation Modal */}
            <Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Reject</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to reject this subscription request?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowRejectModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleReject}>
                        Reject
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Response Message Modal */}
            <Modal show={responseModalShow} onHide={handleResponseModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Response Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>{responseMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleResponseModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SubscriptionList;
