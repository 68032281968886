import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import Card from "../../../components/card";
import { _get, _delete } from "../../../apiClient";
import DateFomats from "../app/Pipe/dateFormater";
import Loader from "../Loader";
import StarRating from "./StarRating";
import "../../../assets/scss/custom/components/grid/feed_table.scss";

const FeedBackList = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [userList, setUserList] = useState([]);
    const [filter, setFilter] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const [expandedMessages, setExpandedMessages] = useState({});
    const [expandedItems, setExpandedItems] = useState({});

    const token = localStorage.getItem("token");

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);

    useEffect(() => {
        filterUsers(data);
        setCurrentPage(1);
    }, [filter]);

    useEffect(() => {
        paginate(currentPage);
    }, [filteredData]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await _get("feedback");
            if (response.data.status === "success") {
                const responseData = response.data.data || [];
                console.log('responseData', responseData)
                setData(responseData);
                filterUsers(responseData);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteFeedback = async (feedbackId) => {
        try {
            const response = await _delete(`feedback/${feedbackId}`, token);
            console.log('response', response)
            if (response.data.status === "success") {
                fetchData();
            } else {
                console.error('Failed to delete feedback:', response.data.message);
            }
        } catch (error) {
            console.error('Error deleting feedback:', error);
        }
    };

    const filterUsers = (data) => {
        let filtered = [...data];
        if (filter === 'Best') {
            filtered = filtered.filter(user => user.rating > 3);
        } else if (filter === 'Worst') {
            filtered = filtered.filter(user => user.rating <= 3);
        }
        setFilteredData(filtered);
    };

    const handleShowMoreToggle = (id, type) => {
        if (type === 'message') {
            setExpandedMessages(prev => ({
                ...prev,
                [id]: !prev[id]
            }));
        } else if (type === 'items') {
            setExpandedItems(prev => ({
                ...prev,
                [id]: !prev[id]
            }));
        }
    };

    const renderMessage = (message, id) => {
        const words = message.split(' ');
        const shouldShowMore = words.length > 4;
        const isExpanded = expandedMessages[id];
        const displayMessage = isExpanded ? message : words.slice(0, 4).join(' ') + (shouldShowMore ? '...' : '');

        return (
            <>
                <p className={`card-title mb-md-0 menu-card-title-lun`} style={{ maxHeight: isExpanded ? 'none' : '75px', overflow: 'hidden' }}>
                    {displayMessage}
                </p>
                {shouldShowMore && (
                    <p className="m-0 show-text" onClick={() => handleShowMoreToggle(id, 'message')}>
                        {isExpanded ? 'Show Less' : 'Show More'}
                    </p>
                )}
            </>
        );
    };

    const renderItems = (items, id) => {
        const words = items.split(' ');
        const shouldShowMore1 = words.length > 4;
        const isExpanded1 = expandedItems[id];
        const displayMessage1 = isExpanded1 ? items : words.slice(0, 4).join(' ') + (shouldShowMore1 ? '...' : '');

        return (
            <>
                <p className={`card-title mb-md-0 menu-card-title-lun`} style={{ maxHeight: isExpanded1 ? 'none' : '75px', overflow: 'hidden' }}>
                                       {displayMessage1}
                </p>
                
                {shouldShowMore1 && (
                    <p className="m-0 show-text" onClick={() => handleShowMoreToggle(id, 'items')}>
                        {isExpanded1 ? 'Show Less' : 'Show More'}
                    </p>
                )}
            </>
        );
    };

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        const indexOfLastRecord = pageNumber * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        setUserList(filteredData.slice(indexOfFirstRecord, indexOfLastRecord));
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const prevPage = () => {
        if (currentPage > 1) {
            paginate(currentPage - 1);
        }
    };

    const nextPage = () => {
        const totalPages = Math.ceil(filteredData.length / recordsPerPage);
        if (currentPage < totalPages) {
            paginate(currentPage + 1);
        }
    };

    const deleteTooltip = (
        <Tooltip id="view-tooltip">
            Delete
        </Tooltip>
    );

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="container">
                    <Row>
                        <Col sm="12">
                            <Card>
                                <Card.Header className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                                    <div className="mb-3 mb-sm-0">
                                        <Card.Header.Title>
                                            <h4 className="card-title mb-0 daily-tilte">FeedBack List</h4>
                                        </Card.Header.Title>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Form.Select onChange={handleFilterChange} className="w-auto me-3 mb-3 mb-sm-0">
                                            <option value="all">All</option>
                                            <option value="Best">High</option>
                                            <option value="Worst">Low</option>
                                        </Form.Select>
                                    </div>
                                </Card.Header>
                                <Card.Body className="px-0">
                                    <div className="table-responsive">
                                        <table
                                            id="user-list-table"
                                            className="table"
                                            role="grid"
                                            data-toggle="data-table"
                                        >
                                            <thead>
                                                <tr className="ligth text-center">
                                                    <th>Name</th>
                                                    <th>Contact</th>
                                                    <th>Ratings</th>
                                                    <th>Message</th>
                                                    <th>Items</th>
                                                    <th>Food Type</th>
                                                    <th>Delivery Date</th>
                                                    <th>Created Date</th>
                                                    <th min-width="100px">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userList.length === 0 ? (
                                                    <tr>
                                                        <td colSpan="9" className="text-center no-date pt-5">No data found</td>
                                                    </tr>
                                                ) : (
                                                    userList.map((feedback, index) => (
                                                        <tr key={index} className="text-center">
                                                            <td>{feedback.user.fname + " " + feedback.user.lname}</td>
                                                            <td>{feedback.user.phone}</td>
                                                            <td><StarRating rating={feedback.rating} /></td>
                                                            <td>{renderMessage(feedback.msg, feedback.feedbackId)}</td>
                                                            <td>{renderItems(feedback.items, feedback.feedbackId)}</td>
                                                            <td>{feedback.foodType}</td>
                                                            <td>{feedback.deliveredAt}</td>
                                                            <td>{new Date(feedback.created_at).toLocaleDateString('en-GB')}</td>
                                                            <td>
                                                                <OverlayTrigger placement="top" overlay={deleteTooltip}>
                                                                    <Button variant="danger" className="btn btn-sm btn-icon" onClick={() => deleteFeedback(feedback.feedbackId)}>
                                                                        <span className="btn-inner">
                                                                            <svg width="20" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                                                                <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M17.4401 6.23975C16.6531 6.23975 15.9791 5.68775 15.8251 4.91375L15.5821 3.69975C15.4571 3.09375 14.9201 2.66475 14.2951 2.66475H10.1631C9.53812 2.66475 9.00112 3.09375 8.87612 3.69975L8.63312 4.91375C8.47912 5.68775 7.80512 6.23975 7.01912 6.23975" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                                <Card.Footer>
                                    {filteredData.length > recordsPerPage && (
                                        <div className="d-flex justify-content-center">
                                            <Button onClick={prevPage} disabled={currentPage === 1} className="m-2">
                                                Prev
                                            </Button>
                                            <div className="text-center m-2 p-2">
                                                {currentPage}/{Math.ceil(filteredData.length / recordsPerPage)}
                                            </div>
                                            <Button
                                                onClick={nextPage}
                                                disabled={currentPage === Math.ceil(filteredData.length / recordsPerPage)}
                                                className="m-2"
                                            >
                                                Next
                                            </Button>
                                        </div>
                                    )}
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default FeedBackList;
