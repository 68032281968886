import React from 'react';
import { Redirect } from 'react-router-dom';

const withAuth = (WrappedComponent) => {
  return (props) => {
    const token = localStorage.getItem('token');
    if (token) {
        const role = localStorage.getItem('role')
        if(role === 'Admin'){
            return <Redirect to="/admin" />;
        }
      return <Redirect to="/" />;
    }
    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
