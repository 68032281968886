import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmCancelButton = ({ isOpen, onClose, onConfirm }) => {
    return (
        <Modal show={isOpen} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Cancel Food</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to cancel the food?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={onConfirm}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmCancelButton;
