import '../../../assets/scss/custom/components/menu-style/DateRangeSlider.scss'
import React, { useState, useEffect } from 'react';
import {  useHistory } from "react-router-dom";
import { _get } from '../../../apiClient';
import  { FaArrowCircleRight } from 'react-icons/fa'
import  { FaArrowCircleLeft } from 'react-icons/fa'

const DateRangeSlider = ({ onDayOrderChange }) => {
  useEffect(() => {
    SubscribedData();
  }, []);

  const [currentDate, setCurrentDate] = useState(new Date());
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const day = currentDate.getDate();
  const [selectedDate, setSelectedDate] = useState(new Date(year, month, day));
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dayOrder, setDayorder] = useState(null);

  const history = useHistory();
  const SubscribedData = async () => {
    try {
      const response = await _get('/user/status');
      const responseData = response.data.data;

      const start = new Date(responseData.startdate);
      const end = new Date(responseData.expirydate);

      setStartDate(start);
      setEndDate(end);
    } catch (error) {
      console.error(error);
    }
  };

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const renderDaysOfWeek = () => {
    const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    return daysOfWeek.map((day, index) => (
      <div key={index} className="day-name">
        {day}
      </div>
    ));
  };

 const renderDates = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    const daysInMonth = getDaysInMonth(year, month);
    const firstDay = getFirstDayOfMonth(year, month);

    const dates = [];

    for (let i = 0; i < firstDay; i++) {
      dates.push(<div key={`empty-${i}`} className="empty-date" />);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const isSelected =
        selectedDate &&
        selectedDate.getFullYear() === year &&
        selectedDate.getMonth() === month &&
        selectedDate.getDate() === day;
      const isDisabled = !(startDate && endDate && date >= startDate && date <= endDate);
      
      dates.push(
        <div
          key={day}
          className={`date ${isSelected ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}`}
          onClick={() => !isDisabled && handleDateClick(year, month, day)}
        >
          {day}
        </div>
      );
    }

    return dates;
  };

  const getDayOrder = (dateString) => {
    if (!startDate) return -1;

    const parts = dateString.split('/');
    const date = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);

    const diffTime = Math.abs(date - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays % 19;
  };

  const handleDateClick = (year, month, day) => {
    setSelectedDate(new Date(year, month, day));
    const formattedDate = `${String(day).padStart(2, '0')}/${String(month + 1).padStart(2, '0')}/${year}`;
    console.log('formattedDate', formattedDate)
    
    const dayOrder = getDayOrder(formattedDate);
    setDayorder(dayOrder);

    // Call the callback function to pass the updated day order to the parent component
    if (onDayOrderChange) {
      onDayOrderChange(dayOrder,year, month+1, day);
    }
  };

  const goToPreviousMonth = () => {
    const newDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
    console.log('newDate', newDate)
    setCurrentDate(newDate);
  };

  const goToNextMonth = () => {
    const newDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    setCurrentDate(newDate);
      console.log('goToNextMonth', newDate)
  };
  const handlePreviousMonth = (newDate, mode = "prev") => {
    history.push({
      pathname: "/special-pages/menulist",
      state: { newDate, mode },
    });
  };
  const handleNextMonth = (newDate, mode = "next") => {
    history.push({
      pathname: "/special-pages/menulist",
      state: { newDate, mode },
    });
  };
  return (
    <div className="calendar">
      <div className="header">
        <div className='cal-arrrow' style={{color:"#004F9F"}}   onClick={goToPreviousMonth}>
         <FaArrowCircleLeft />
        </div>
        <div className="current-month" style={{color:"#F5BC26"}}>
          {currentDate.toLocaleString('default', { month: 'short' })} {currentDate.getFullYear()}
        </div>
        <div className='cal-arrrow' style={{color:"#004F9F"}} onClick={goToNextMonth}>
         <FaArrowCircleRight />
        </div>
      </div>
      <div className="days-of-week" style={{color:"#329C6B"}}>{renderDaysOfWeek()}</div>
      <div className="dates">{renderDates()}</div>
    </div>
  );
};

export default DateRangeSlider;
