import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from '@mui/material';
import Card from '../../../components/card';
import { useHistory } from "react-router-dom";
import { _get, _post, _put, _delete } from '../../../apiClient';
import menu10 from '../../../assets/images/menu/10.png';
import userp02 from '../../../assets/images/User-profile/02.png';
import Loader from '../Loader';

const Trip = () => {
    const [trip, setTrip] = useState([]);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPageItems, setCurrentPageItems] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [newTripName, setNewTripName] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [tripToDelete, setTripToDelete] = useState(null);
    const [tripToEdit, setTripToEdit] = useState(null);
    const history = useHistory();

    const formTripNameRef = useRef(null);
    const nextPageRef = useRef(null);

    useEffect(() => {
        fetchTrip();
    }, []);

    useEffect(() => {
        setCurrentPageItems(trip.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
    }, [trip, currentPage]);

    const fetchTrip = async () => {
        setIsLoading(true);
        try {
            const response = await _get('/assignroute');
            const responseData = response.data.data;
            setTrip(responseData);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(trip.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleCreateOrUpdateTrip = async () => {
        setIsButtonLoading(true);
        try {
            if (editMode) {
                await _put(`/assignroute/${tripToEdit.id}`, {
                    routeName: newTripName,
                    userIds: [],
                    delivery_boy_id: 0
                });
                setTrip(trip.map(item => (item.id === tripToEdit.id ? { ...item, routeName: newTripName } : item)));
                setSuccessMsg('Trip updated successfully!');
            } else {
                const response = await _post('/assignroute', {
                    routeName: newTripName,
                    userIds: [],
                    delivery_boy_id: 0
                });
                const newTrip = response.data.data;
                setTrip([...trip, newTrip]);
                setSuccessMsg('Trip created successfully!');
            }
        } catch (error) {
            console.error(`Error ${editMode ? 'updating' : 'creating'} trip:`, error);
            setSuccessMsg(`Failed to ${editMode ? 'update' : 'create'} trip.`);
        } finally {
            setIsButtonLoading(false);
            setShowModal(true);
            setEditMode(false);
            setNewTripName('');
        }
    };
    

    const handleEditClick = (tripItem) => {
        setEditMode(true);
        setNewTripName(tripItem.routeName);
        setTripToEdit(tripItem);
        formTripNameRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleNewTripNameChange = (e) => {
        setNewTripName(e.target.value);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleShowDeleteModal = (tripId) => {
        setTripToDelete(tripId);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleDeleteTrip = async () => {
        setIsButtonLoading(true);
        try {
            await _delete(`/assignroute/${tripToDelete}`);
            setTrip(trip.filter(item => item.id !== tripToDelete));
            setShowDeleteModal(false);
        } catch (error) {
            console.error('Error deleting trip:', error);
        }
        finally {
            setIsButtonLoading(false);
        }
    };
    const handleAdd = () =>{
        history.push({
            pathname:"/app/add-delivery"
        })
    }

    const editTooltip = (
        <Tooltip id="edit-tooltip">
            Edit
        </Tooltip>
    );
    const viewTooltip = (
        <Tooltip id="view-tooltip">
            Assign Users
        </Tooltip>
    );
    const deleteTooltip = (
        <Tooltip id="view-tooltip">
            Delete
        </Tooltip>
    );
    
   
    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className='container'>
                    <Row>
                        <Col lg="6">
                            <div ref={nextPageRef}>
                                <Card>
                                    <Card.Header className="d-flex justify-content-between align-items-center">
                                        <h4 className="card-title list-main daily-tilte">Trip List</h4>
                                        <OverlayTrigger placement="top" overlay={viewTooltip}>
                                                                <Button 
                                                                className="btn btn-sm btn-icon btn-info m-2"
                                                                title="Create"
                                                                data-toggle="tooltip" data-placement="top"
                                                                onClick={() => handleAdd()}
                                                                >
                                                                    <span className='btn-inner'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus" viewBox="0 0 16 16">
  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
  <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5"/>
</svg>                                                                        
                                                                    </span> Assign
                                                                </Button>
                                                                </OverlayTrigger>
                                    </Card.Header>
                                    <Card.Body className="py-0">
                                        <ul className="list-inline chat-list-main1">
                                            {trip.length === 0 ? (
                                                <p className="text-center no-date pt-5">No data found</p>
                                            ) : (
                                                currentPageItems.map((tripItem, index) => (
                                                    <li className="py-5 border-bottom border-soft-primary" key={index}>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex align-items-center col-lg-6 col-md-8">
                                                                <img src={menu10} className="img-fluid avatar avatar-40" alt="profile-img" />
                                                                <div className="d-flex justify-content-between ms-3">
                                                                    <div>
                                                                        <h6 className="mb-1 fw-bolder heading-title">{tripItem.routeName}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                         
                                                                <OverlayTrigger placement="top" overlay={editTooltip}>
                                                                <Button
                                                                    className="btn btn-sm btn-icon btn-warning m-2"
                                                                    title="Edit"
                                                                    data-toggle="tooltip" data-placement="top"
                                                                    onClick={() => handleEditClick(tripItem)}
                                                                >
                                                                    <span className="btn-inner">
                                                                        <svg
                                                                            width="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                clipRule="evenodd"
                                                                                d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                            <path
                                                                                d="M15.1655 4.60254L19.7315 9.16854"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                        </svg>
                                                                    </span>
                                                                </Button>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={deleteTooltip}>
                                                                <Button
                                                                    className="btn btn-sm btn-icon btn-danger m-2"
                                                                    title="Delete"
                                                                    data-toggle="tooltip" data-placement="top"
                                                                    onClick={() => handleShowDeleteModal(tripItem.id)}
                                                                >
                                                                    <span className="btn-inner">
                                                                        <svg
                                                                            width="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                            <path
                                                                                d="M20.708 6.23975H3.75"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                            <path
                                                                                d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                        </svg>
                                                                    </span>
                                                                </Button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                            )}
                                        </ul>
                                        <div className="d-flex justify-content-between align-items-center py-3">
                                            <Button
                                                onClick={handlePrevPage}
                                                disabled={currentPage === 1}
                                            >
                                                Prev
                                            </Button>
                                            <span>{currentPage} / {Math.ceil(trip.length / itemsPerPage)}</span>
                                            <Button
                                                onClick={handleNextPage}
                                                disabled={currentPage >= Math.ceil(trip.length / itemsPerPage)}
                                            >
                                                Next
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col lg="6" className='ryt-sec'>
                            <Card style={{ background: `url(${userp02})`, backgroundSize: "cover" }}>
                                <Card.Body>
                                    <div ref={formTripNameRef}>
                                        <div className='text-center'>
                                            <h2 className="text-primary">{editMode ? 'Edit Trip' : 'Create Trip'}</h2>
                                        </div>
                                        <div className="text-left">
                                            <Form>
                                                <Form.Group controlId="formTripName">
                                                    <Form.Label>Trip Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter trip name"
                                                        value={newTripName}
                                                        onChange={handleNewTripNameChange}
                                                        required
                                                    />
                                                </Form.Group>
                                            </Form>
                                            <Button
    type="button"
    className="btn btn-primary rounded-pill mt-5"
    onClick={handleCreateOrUpdateTrip}
    disabled={isButtonLoading || newTripName.trim() === ''} // Disable button if input is empty or during loading
>
    {isButtonLoading ? (
        <span>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            &nbsp; Loading...
        </span>
    ) : (
        editMode ? 'Update Trip' : 'Save Trip!'
    )}
</Button>


                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>{successMsg}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this trip?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteTrip} disabled={isButtonLoading}>
                    {isButtonLoading ? (
                                                    <span>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp; Loading...
                                                    </span>
                                                ) : (
                                                    'Delete'
                                                )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Trip;
