import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    Accordion,
    useAccordionButton,
    AccordionContext,
} from "react-bootstrap";

function CustomToggle({ children, eventKey, onClick }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, (active) =>
        onClick({ state: !active, eventKey: eventKey })
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <Link
            to="#"
            aria-expanded={isCurrentEventKey ? "true" : "false"}
            className="nav-link"
            role="button"
            onClick={(e) => {
                decoratedOnClick(isCurrentEventKey);
            }}
        >
            {children}
        </Link>
    );
}

const VerticalNav = () => {
    const [showMenu, setShowMenu] = useState(true);
    const [activeMenu, setActiveMenu] = useState(false);
    //location
    let location = useLocation();

    const role = localStorage.getItem("role");
    useEffect(() => {
        if (role === "User") {
            setShowMenu(false);
        } else {
            setShowMenu(true);
        }
    }, [role]);
    return (
        <>
            <Accordion as="ul" className="navbar-nav iq-main-menu">
                <Accordion.Item as="li" eventKey="horizontal-menu" bsPrefix="nav-item">
                    <Accordion>
                        <ul className="sub-nav">
                            {!showMenu && (<li className="nav-item">
                                <Link
                                    className={`${location.pathname === "/" ? "active" : ""
                                        } nav-link`}
                                    to="/"
                                >
                                    <i className="icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                        >
                                            <g>
                                                <circle
                                                    cx="12"
                                                    cy="12"
                                                    r="8"
                                                    fill="currentColor"
                                                ></circle>
                                            </g>
                                        </svg>
                                    </i>
                                    <i className="sidenav-mini-icon"> H </i>
                                    <span className="item-name"> Home </span>
                                </Link>
                            </li>

                            )}

                            {!showMenu && (<li className="nav-item">
                                <Link
                                    className={`${location.pathname === "/fullmenu" ? "active" : ""
                                        } nav-link`}
                                    to="/fullmenu"
                                >
                                    <i className="icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                        >
                                            <g>
                                                <circle
                                                    cx="12"
                                                    cy="12"
                                                    r="8"
                                                    fill="currentColor"
                                                ></circle>
                                            </g>
                                        </svg>
                                    </i>
                                    <i className="sidenav-mini-icon"> ML </i>
                                    <span className="item-name"> MenuList </span>
                                </Link>
                            </li>

                            )}
                            {showMenu && (
                                <li className="nav-item">
                                    <Link
                                        className={`${location.pathname === "/admin" ? "active" : ""
                                            } nav-link`}
                                        to="/admin"
                                    >
                                        <i className="icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                            >
                                                <g>
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="8"
                                                        fill="currentColor"
                                                    ></circle>
                                                </g>
                                            </svg>
                                        </i>
                                        <i className="sidenav-mini-icon"> D </i>
                                        <span className="item-name">Dashboard</span>
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </Accordion>
                </Accordion.Item>

                {/* <li>
                    <hr className="hr-horizontal" />
                </li> */}
                <Accordion.Item as="li" bsPrefix="nav-item">
                    {showMenu && (
                        <Accordion>
                            <ul className="sub-nav">

                                <li className="nav-item">
                                    <Link
                                        className={`${location.pathname === "/special-pages/menu"
                                            ? "active"
                                            : ""
                                            } nav-link`}
                                        to="/special-pages/menu"
                                    >
                                        <i className="icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                            >
                                                <g>
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="8"
                                                        fill="currentColor"
                                                    ></circle>
                                                </g>
                                            </svg>
                                        </i>
                                        <i className="sidenav-mini-icon"> M </i>
                                        <span className="item-name">Meals</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={`${location.pathname === "/special-pages/menulist"
                                            ? "active"
                                            : ""
                                            } nav-link`}
                                        to="/special-pages/menulist"
                                    >
                                        <i className="icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                            >
                                                <g>
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="8"
                                                        fill="currentColor"
                                                    ></circle>
                                                </g>
                                            </svg>
                                        </i>
                                        <i className="sidenav-mini-icon"> M </i>
                                        <span className="item-name">Menu</span>
                                    </Link>
                                </li>
                            </ul>
                        </Accordion>
                    )}
                    {/* {showMenu && (
                        <CustomToggle
                            eventKey="sidebar-special"
                            onClick={(activeKey) => setActiveMenu(activeKey)}
                        >
                            <i className="icon">
                                <svg
                                    width="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        opacity="0.4"
                                        d="M13.3051 5.88243V6.06547C12.8144 6.05584 12.3237 6.05584 11.8331 6.05584V5.89206C11.8331 5.22733 11.2737 4.68784 10.6064 4.68784H9.63482C8.52589 4.68784 7.62305 3.80152 7.62305 2.72254C7.62305 2.32755 7.95671 2 8.35906 2C8.77123 2 9.09508 2.32755 9.09508 2.72254C9.09508 3.01155 9.34042 3.24276 9.63482 3.24276H10.6064C12.0882 3.2524 13.2953 4.43736 13.3051 5.88243Z"
                                        fill="currentColor"
                                    ></path>
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M15.164 6.08279C15.4791 6.08712 15.7949 6.09145 16.1119 6.09469C19.5172 6.09469 22 8.52241 22 11.875V16.1813C22 19.5339 19.5172 21.9616 16.1119 21.9616C14.7478 21.9905 13.3837 22.0001 12.0098 22.0001C10.6359 22.0001 9.25221 21.9905 7.88813 21.9616C4.48283 21.9616 2 19.5339 2 16.1813V11.875C2 8.52241 4.48283 6.09469 7.89794 6.09469C9.18351 6.07542 10.4985 6.05615 11.8332 6.05615C12.3238 6.05615 12.8145 6.05615 13.3052 6.06579C13.9238 6.06579 14.5425 6.07427 15.164 6.08279ZM10.8518 14.7459H9.82139V15.767C9.82139 16.162 9.48773 16.4896 9.08538 16.4896C8.67321 16.4896 8.34936 16.162 8.34936 15.767V14.7459H7.30913C6.90677 14.7459 6.57311 14.4279 6.57311 14.0233C6.57311 13.6283 6.90677 13.3008 7.30913 13.3008H8.34936V12.2892C8.34936 11.8942 8.67321 11.5667 9.08538 11.5667C9.48773 11.5667 9.82139 11.8942 9.82139 12.2892V13.3008H10.8518C11.2542 13.3008 11.5878 13.6283 11.5878 14.0233C11.5878 14.4279 11.2542 14.7459 10.8518 14.7459ZM15.0226 13.1177H15.1207C15.5231 13.1177 15.8567 12.7998 15.8567 12.3952C15.8567 12.0002 15.5231 11.6727 15.1207 11.6727H15.0226C14.6104 11.6727 14.2866 12.0002 14.2866 12.3952C14.2866 12.7998 14.6104 13.1177 15.0226 13.1177ZM16.7007 16.4318H16.7988C17.2012 16.4318 17.5348 16.1139 17.5348 15.7092C17.5348 15.3143 17.2012 14.9867 16.7988 14.9867H16.7007C16.2885 14.9867 15.9647 15.3143 15.9647 15.7092C15.9647 16.1139 16.2885 16.4318 16.7007 16.4318Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </i>
                            <span className="item-name">Special Pages</span>
                            <i className="right-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            </i>
                        </CustomToggle>
                    )} */}

                </Accordion.Item>
                {showMenu && (
                    <Accordion.Item as='li' bsPrefix="nav-item">
                        <Accordion>
                            <ul className="sub-nav">
                                {showMenu && (
                                    <li className="nav-item">
                                        <Link
                                            className={`${location.pathname === "/subcription/subcription-list" ? "active" : ""
                                                } nav-link`}
                                            to="/subcription/subcription-list"
                                        >
                                            <i className="icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="10"
                                                    viewBox="0 0 24 24"
                                                    fill="currentColor"
                                                >
                                                    <g>
                                                        <circle
                                                            cx="12"
                                                            cy="12"
                                                            r="8"
                                                            fill="currentColor"
                                                        ></circle>
                                                    </g>
                                                </svg>
                                            </i>
                                            <i className="sidenav-mini-icon"> SL </i>
                                            <span className="item-name">Subscription List</span>
                                        </Link>
                                    </li>

                                )}
                                {showMenu && (
                                    <li className="nav-item">
                                        <Link
                                            className={`${location.pathname === "/subcription/unsubcription-list" ? "active" : ""
                                                } nav-link`}
                                            to="/subcription/unsubcription-list"
                                        >
                                            <i className="icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="10"
                                                    viewBox="0 0 24 24"
                                                    fill="currentColor"
                                                >
                                                    <g>
                                                        <circle
                                                            cx="12"
                                                            cy="12"
                                                            r="8"
                                                            fill="currentColor"
                                                        ></circle>
                                                    </g>
                                                </svg>
                                            </i>
                                            <i className="sidenav-mini-icon"> UL </i>
                                            <span className="item-name">Unsubscription List</span>
                                        </Link>
                                    </li>

                                )}
                                {showMenu && (
                                    <li className="nav-item">
                                        <Link
                                            className={`${location.pathname === "/feedback" ? "active" : ""
                                                } nav-link`}
                                            to="/feedback"
                                        >
                                            <i className="icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="10"
                                                    viewBox="0 0 24 24"
                                                    fill="currentColor"
                                                >
                                                    <g>
                                                        <circle
                                                            cx="12"
                                                            cy="12"
                                                            r="8"
                                                            fill="currentColor"
                                                        ></circle>
                                                    </g>
                                                </svg>
                                            </i>
                                            <i className="sidenav-mini-icon"> FL </i>
                                            <span className="item-name">FeedBack List</span>
                                        </Link>
                                    </li>

                                )}
                            </ul>
                        </Accordion>
                    </Accordion.Item>
                )}
                {showMenu && (
                    <Accordion.Item as="li" eventKey="sidebar-user" bsPrefix="nav-item">
                        <CustomToggle
                            eventKey="sidebar-user"
                            onClick={(activeKey) => setActiveMenu(activeKey)}
                        >
                            <i className="icon">
                                <svg
                                    width="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z"
                                        fill="currentColor"
                                    ></path>
                                    <path
                                        opacity="0.4"
                                        d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z"
                                        fill="currentColor"
                                    ></path>
                                    <path
                                        opacity="0.4"
                                        d="M21.0881 9.21923C21.6925 6.84176 19.9205 4.70654 17.664 4.70654C17.4187 4.70654 17.1841 4.73356 16.9549 4.77949C16.9244 4.78669 16.8904 4.802 16.8725 4.82902C16.8519 4.86324 16.8671 4.90917 16.8895 4.93889C17.5673 5.89528 17.9568 7.0597 17.9568 8.30967C17.9568 9.50741 17.5996 10.6241 16.9728 11.5508C16.9083 11.6462 16.9656 11.775 17.0793 11.7948C17.2369 11.8227 17.3981 11.8371 17.5629 11.8416C19.2059 11.8849 20.6807 10.8213 21.0881 9.21923Z"
                                        fill="currentColor"
                                    ></path>
                                    <path
                                        d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z"
                                        fill="currentColor"
                                    ></path>
                                    <path
                                        opacity="0.4"
                                        d="M7.04459 4.77973C6.81626 4.7329 6.58077 4.70679 6.33543 4.70679C4.07901 4.70679 2.30701 6.84201 2.9123 9.21947C3.31882 10.8216 4.79355 11.8851 6.43661 11.8419C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.551C6.3999 10.6234 6.04263 9.50765 6.04263 8.30991C6.04263 7.05904 6.43303 5.89462 7.11085 4.93913C7.13234 4.90941 7.14845 4.86348 7.12696 4.82926C7.10906 4.80135 7.07593 4.78694 7.04459 4.77973Z"
                                        fill="currentColor"
                                    ></path>
                                    <path
                                        d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </i>
                            <span className="item-name">Users</span>
                            <i className="right-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            </i>
                        </CustomToggle>
                        <Accordion.Collapse eventKey="sidebar-user">
                            <ul className="sub-nav">
                                <li className="nav-item">
                                    <Link
                                        className={`${location.pathname === "/app/user-add" ? "active" : ""
                                            } nav-link`}
                                        to="/app/user-add"
                                    >
                                        <i className="icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                            >
                                                <g>
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="8"
                                                        fill="currentColor"
                                                    ></circle>
                                                </g>
                                            </svg>
                                        </i>
                                        <i className="sidenav-mini-icon"> E </i>
                                        <span className="item-name">Add User</span>
                                    </Link>
                                </li>
                                {showMenu && (
                                    <li className="nav-item">
                                        <Link
                                            className={`${location.pathname === "/app/user-list" ? "active" : ""
                                                } nav-link`}
                                            to="/app/user-list"
                                        >
                                            <i className="icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="10"
                                                    viewBox="0 0 24 24"
                                                    fill="currentColor"
                                                >
                                                    <g>
                                                        <circle
                                                            cx="12"
                                                            cy="12"
                                                            r="8"
                                                            fill="currentColor"
                                                        ></circle>
                                                    </g>
                                                </svg>
                                            </i>
                                            <i className="sidenav-mini-icon"> U </i>
                                            <span className="item-name">User List</span>
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </Accordion.Collapse>
                    </Accordion.Item>
                )}
                {showMenu && (
                    <Accordion.Item as="li" eventKey="sidebar-delivery" bsPrefix="nav-item">
                        <CustomToggle
                            eventKey="sidebar-delivery"
                            onClick={(activeKey) => setActiveMenu(activeKey)}
                        >
                            <i className="icon">
                                <svg
                                    width="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3 3H16V13H3V3Z"
                                        fill="currentColor"
                                    ></path>
                                    <path
                                        d="M16 13H21L20 10H16V13Z"
                                        fill="currentColor"
                                    ></path>
                                    <path
                                        d="M3 16C3 14.9 3.9 14 5 14C6.1 14 7 14.9 7 16C7 17.1 6.1 18 5 18C3.9 18 3 17.1 3 16ZM16 16C16 14.9 16.9 14 18 14C19.1 14 20 14.9 20 16C20 17.1 19.1 18 18 18C16.9 18 16 17.1 16 16Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>

                            </i>
                            <span className="item-name">Delivery</span>
                            <i className="right-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            </i>
                        </CustomToggle>
                        <Accordion.Collapse eventKey="sidebar-delivery">
                            <ul className="sub-nav">
                                <li className="nav-item">
                                    <Link
                                        className={`${location.pathname === "/delivery-add" ? "active" : ""
                                            } nav-link`}
                                        to="/delivery-add"
                                    >
                                        <i className="icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                            >
                                                <g>
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="8"
                                                        fill="currentColor"
                                                    ></circle>
                                                </g>
                                            </svg>
                                        </i>
                                        <i className="sidenav-mini-icon">DA</i>
                                        <span className="item-name">Add Delivery</span>
                                    </Link>
                                </li>
                                {showMenu && (
                                    <li className="nav-item">
                                        <Link
                                            className={`${location.pathname === "/delivery-list" ? "active" : ""
                                                } nav-link`}
                                            to="/delivery-list"
                                        >
                                            <i className="icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="10"
                                                    viewBox="0 0 24 24"
                                                    fill="currentColor"
                                                >
                                                    <g>
                                                        <circle
                                                            cx="12"
                                                            cy="12"
                                                            r="8"
                                                            fill="currentColor"
                                                        ></circle>
                                                    </g>
                                                </svg>
                                            </i>
                                            <i className="sidenav-mini-icon">DL</i>
                                            <span className="item-name">Delivery List</span>
                                        </Link>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <Link
                                        className={`${location.pathname === "/trip" ? "active" : ""
                                            } nav-link`}
                                        to="/trip"
                                    >
                                        <i className="icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                            >
                                                <g>
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="8"
                                                        fill="currentColor"
                                                    ></circle>
                                                </g>
                                            </svg>
                                        </i>
                                        <i className="sidenav-mini-icon">T</i>
                                        <span className="item-name">Trip</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={`${location.pathname === "/trip-detail" ? "active" : ""
                                            } nav-link`}
                                        to="/trip-detail"
                                    >
                                        <i className="icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                            >
                                                <g>
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="8"
                                                        fill="currentColor"
                                                    ></circle>
                                                </g>
                                            </svg>
                                        </i>
                                        <i className="sidenav-mini-icon">TA</i>
                                        <span className="item-name">Trip Assign</span>
                                    </Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link
                                        className={`${location.pathname === "/app/add-delivery" ? "active" : ""
                                            } nav-link`}
                                        to="/app/add-delivery"
                                    >
                                        <i className="icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                            >
                                                <g>
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="8"
                                                        fill="currentColor"
                                                    ></circle>
                                                </g>
                                            </svg>
                                        </i>
                                        <i className="sidenav-mini-icon">AS</i>
                                        <span className="item-name">Add Assign Delivery</span>
                                    </Link>
                                </li> */}
                               
                                
                            </ul>
                        </Accordion.Collapse>
                    </Accordion.Item>
                )}
                <li>
                    <hr className="hr-horizontal" />
                </li>
            </Accordion>
        </>
    );
};

export default VerticalNav;
