import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { _get } from "../../../apiClient";
import img1 from '../../../assets/images/page-img/07.jpg';
import { FaRupeeSign } from 'react-icons/fa';
// import { FaStar } from 'react-icons/fa';
const Cards = () => {
    const [cardsData, setCardsData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await _get('/otherfoods');
                console.log(response);
                if (response.data.status === 'success') {
                    setCardsData(response.data.data);
                } else {
                    console.error('Failed to fetch data:', response.msg);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <Container fluid className='mb-5'>
            <Row>
                <Col lg="12" sm="12">
                    <h3 className="mb-3 product-title">Our Products</h3>
                    <Row data-masonry='{"percentPosition": true }'>
                        {cardsData.map((card) => (
                            <Col sm="6" lg="3" key={card._id}>
                                <Card className="mb-3" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                    <img src={card.img || img1} className="card-img-top" alt={card.name} />
                                    <Card.Body className='body-card'>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h4 className="card-title pro-head">{card.name} <span>({card.quantity})</span></h4>
                                            </div>
                                            <div>
                                                <div className='review'>
                                                    <p className="card-text pro-amt"><small className="text-muted"><FaRupeeSign />{card.price} INR</small></p>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>

                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Cards;
