import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import Card from '../../../components/card';
import { _get, _post, _put, _delete } from '../../../apiClient';
import menu10 from '../../../assets/images/menu/10.png';
import userp02 from '../../../assets/images/User-profile/02.png';
import Loader from '../Loader';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const Menu = () => {
    const [food, setFood] = useState([]);
    const [filter, setFilter] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [newFoodName, setNewFoodName] = useState('');
    const [newFoodTypes, setNewFoodTypes] = useState([]);
    const [successMsg, setSuccessMsg] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [editFoodId, setEditFoodId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [foodToDelete, setFoodToDelete] = useState(null);
    const itemsPerPage = 10;
    const [isLoading, setIsLoading] = useState(true);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const formFoodNameRef = useRef(null);
    const nextPageRef = useRef(null);

    useEffect(() => {
        fetchFood();
    }, []);

    const fetchFood = async () => {
        setIsLoading(true);
        try {
            const response = await _get('/food');
            const responseData = response.data.data;
            setFood(responseData);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
        setCurrentPage(1);
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
        if (nextPageRef.current) {
            nextPageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => prevPage - 1);
        if (nextPageRef.current) {
            nextPageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleNewFoodNameChange = (event) => {
        setNewFoodName(event.target.value);
    };

    const handleFoodTypeChange = (event) => {
        const value = event.target.value;
        setNewFoodTypes(prevTypes =>
            event.target.checked ? [...prevTypes, value] : prevTypes.filter(type => type !== value)
        );
    };
    const isFormValid = () => {
        return newFoodName.trim() !== '' && newFoodTypes.length > 0;
    };
    const handleCreateOrUpdateFood = async () => {
     
        const newFood = {
            items: newFoodName,
            foodType: newFoodTypes
        };
        setIsButtonLoading(true);
        try {
            let response;
            if (editMode && editFoodId) {
                response = await _put(`/food/${editFoodId}`, newFood);
            } else {
                response = await _post('/food', newFood);
            }

            if (response.data.status === 'success') {
                setSuccessMsg(editMode ? 'Food was updated successfully' : 'Food was added successfully');
                fetchFood();
                clearFormData();
                setShowModal(true); // Show modal
            } else {
                // Handle errors
                console.error('Error adding/updating food:', response.data.msg);
            }
        } catch (error) {
            console.error('Error adding/updating food:', error);
        }finally {
            setIsButtonLoading(false);
        }
    };

    const handleEditClick = (foodItem) => {
        setNewFoodName(foodItem.items);
        setNewFoodTypes(foodItem.foodType);
        setEditFoodId(foodItem.foodId);
        setEditMode(true);

        if (formFoodNameRef.current) {
            formFoodNameRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const clearFormData = () => {
        setNewFoodName('');
        setNewFoodTypes([]);
        setEditMode(false);
        setEditFoodId(null);
    };

    const handleShowDeleteModal = (foodId) => {
        setFoodToDelete(foodId);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setFoodToDelete(null);
        setShowDeleteModal(false);
    };

    const handleDeleteFood = async () => {
        if (foodToDelete) {
            setIsButtonLoading(true);
            try {
                const response = await _delete(`/food/${foodToDelete}`);
                if (response.data.status === 'success') {
                    alert('Food was deleted successfully');
                    fetchFood();
                    handleCloseDeleteModal();
                } else {
                    console.error('Error deleting food:', response.data.msg);
                }
            } catch (error) {
                console.error('Error deleting food:', error);
            }
            finally {
                setIsButtonLoading(false);
            }
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const filteredFood = filter === 'all' ? food : food.filter(foodItem => foodItem.foodType.includes(filter));
    const totalPages = Math.ceil(filteredFood.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentPageItems = filteredFood.slice(startIndex, startIndex + itemsPerPage);
    const hasMoreItems = startIndex + itemsPerPage < filteredFood.length;

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className='container'>
                    <Row>
                        <Col lg="6">
                            <div ref={nextPageRef}>
                                <Card>
                                    <Card.Header className="d-flex justify-content-between align-items-center">
                                        <h4 className="card-title list-main daily-tilte">Food list</h4>
                                        <Form.Select value={filter} onChange={handleFilterChange} className="w-auto">
                                            <option value="all">All</option>
                                            <option value="breakfast">Breakfast</option>
                                            <option value="lunch">Lunch</option>
                                            <option value="dinner">Dinner</option>
                                        </Form.Select>
                                    </Card.Header>
                                    <Card.Body className="py-0">
                                        <ul className="list-inline chat-list-main1">
                                            {food.length === 0 ? (
                                                <p className="text-center no-date pt-5">No data found</p>
                                            ) : (Array.isArray(currentPageItems) && currentPageItems.map((foodItem, index) => (
                                                <li className="py-5 border-bottom border-soft-primary" key={index}>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="d-flex align-items-center col-lg-10 col-md-8">
                                                            <img src={menu10} className="img-fluid avatar avatar-40" alt="profile-img" />
                                                            <div className="d-flex justify-content-between ms-3">
                                                                <div>
                                                                    <h6 className="mb-1 fw-bolder heading-title">{foodItem.items}</h6>
                                                                    <small className="mb-0">{foodItem.foodType.join(', ')}</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <Button
                                                                className="btn btn-sm btn-icon btn-warning m-2"
                                                                title="Edit"
                                                                onClick={() => handleEditClick(foodItem)}
                                                            >
                                                                <span className="btn-inner">
                                                                    <svg
                                                                        width="20"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                                                            stroke="currentColor"
                                                                            strokeWidth="1.5"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                                                            stroke="currentColor"
                                                                            strokeWidth="1.5"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                        <path
                                                                            d="M15.1655 4.60254L19.7315 9.16854"
                                                                            stroke="currentColor"
                                                                            strokeWidth="1.5"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                    </svg>
                                                                </span>

                                                            </Button>
                                                            <Button
                                                                className="btn btn-sm btn-icon btn-danger m-2"
                                                                title="Delete"
                                                                onClick={() => handleShowDeleteModal(foodItem.foodId)}
                                                            >
                                                                <span className="btn-inner">
                                                                    <svg
                                                                        width="20"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        stroke="currentColor"
                                                                    >
                                                                        <path
                                                                            d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                                                            stroke="currentColor"
                                                                            strokeWidth="1.5"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                        <path
                                                                            d="M20.708 6.23975H3.75"
                                                                            stroke="currentColor"
                                                                            strokeWidth="1.5"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                        <path
                                                                            d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                                                            stroke="currentColor"
                                                                            strokeWidth="1.5"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                    </svg>
                                                                </span>

                                                            </Button>
                                                        </div>
                                                    </div>
                                                </li>
                                            )))}
                                        </ul>
                                        <div className="d-flex justify-content-between align-items-center py-3">
                                            <Button
                                                onClick={handlePrevPage}
                                                disabled={currentPage === 1}
                                            >
                                                Prev
                                            </Button>
                                            <span>{currentPage} / {totalPages}</span>
                                            <Button
                                                onClick={handleNextPage}
                                                disabled={!hasMoreItems}
                                            >
                                                Next
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col lg="6" className='ryt-sec'>
                            <Card style={{ background: `url(${userp02})`, backgroundSize: "cover" }}>
                                <Card.Body>
                                    <div ref={formFoodNameRef}>
                                        <div className='text-center'>
                                            <h2 className="text-primary">{editMode ? 'Edit Food' : 'Create Food'}</h2>
                                        </div>
                                        <div className="text-left">
                                            <Form>
                                                <Form.Group controlId="formFoodName">
                                                    <Form.Label>Food Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter food name"
                                                        value={newFoodName}
                                                        onChange={handleNewFoodNameChange}
                                                    />
                                                </Form.Group>
                                                <Form.Group controlId="formFoodType" className='mt-3'>
                                                    <Form.Label>Food Type</Form.Label><br />
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="breakfast"
                                                        label="Breakfast"
                                                        value="breakfast"
                                                        checked={newFoodTypes.includes('breakfast')}
                                                        onChange={handleFoodTypeChange}
                                                    />
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="lunch"
                                                        label="Lunch"
                                                        value="lunch"
                                                        checked={newFoodTypes.includes('lunch')}
                                                        onChange={handleFoodTypeChange}
                                                    />
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="dinner"
                                                        label="Dinner"
                                                        value="dinner"
                                                        checked={newFoodTypes.includes('dinner')}
                                                        onChange={handleFoodTypeChange}
                                                    />
                                                </Form.Group>
                                            </Form>
                                            <Button
                                                type="button"
                                                className="btn btn-primary rounded-pill mt-5"
                                                onClick={handleCreateOrUpdateFood}
                                                disabled={!isFormValid() || isButtonLoading }

                                            >
                                                {isButtonLoading ? (
                                                    <span>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp; Loading...
                                                    </span>
                                                ) : (
                                                    editMode ? 'Update Food' : 'Save Food!'
                                                )}
                                               
                                            </Button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>{successMsg}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this food item?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteFood} disabled={isButtonLoading}>
                    {isButtonLoading ? (
                                                    <span>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp; Loading...
                                                    </span>
                                                ) : (
                                                    'Delete'
                                                )}
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />

        </>
    );
}

export default Menu;
