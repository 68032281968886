import React from 'react';

const StarRating = ({ rating }) => {
  const totalStars = 5;
  const stars = [];

  for (let i = 1; i <= totalStars; i++) {
    if (i <= rating) {
      stars.push(<i key={i} className="fa fa-star text-warning"></i>);
    } else {
      stars.push(<i key={i} className="fa fa-star-o text-warning"></i>);
    }
  }

  return <div>{stars}</div>;
};

export default StarRating;
