import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import Card from "../../../components/card";
import { _get, _put } from "../../../apiClient";
import Avatar from "./avatar/avatar";
import Loader from "../Loader";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const DeliveryAssignAdd = () => {
  const [data, setData] = useState([]);
  const location = useLocation();
  const { mode } = location.state || {};
  const isReadMode = mode === "read";
  const [userList, setUserList] = useState([]);
  const [deliveryList, setDeliveryList] = useState([]);
  const [tripList, setTripList] = useState([]);
  const [tripsId, setTripsId] = useState([]); // Store tripsId as an array since there can be multiple IDs
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(8);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDeliveryBoy, setSelectedDeliveryBoy] = useState('');
  const [selectedTrip, setSelectedTrip] = useState('');
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [error, setError] = useState(null);

  const history = useHistory();
  const token = localStorage.getItem("token");

  useEffect(() => {

    handleUser();

    if (token) {
      fetchData();
      fetchTrips();
      fetchDelivery();
    }
  }, [token]);

  useEffect(() => {
    paginate(currentPage);
  }, [data, currentPage, selectedTrip]);

  useEffect(() => {
    handleUser();
  }, [selectedTrip]);

  const handleTripChange = (event) => {
    console.log('event', event);
    setSelectedTrip(event.target.value);
    setCurrentPage(1); // Reset to the first page whenever the trip changes
  };

  const handleUser = async () => {
    setSelectedUserIds([]);
    var userIDS = data.filter((id) => selectedTrip == id.routeId).map(item => item.userId);
    setSelectedUserIds(userIDS);
    console.log('userIDS', userIDS)
    console.log('selectedTrip', selectedTrip);
    console.log("selectedUserIdshhh", data);
  };

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await _get("userlist");
      if (response.data.status === "success") {
        const responseData = response.data.data || [];
        console.log('responseData', responseData);
        const subscribedUsers = responseData.filter(user => user.issubscribed);
        setData(subscribedUsers);
      } else {
        setError("Failed to fetch user data");
      }
    } catch (error) {
      setError("Error fetching user data");
      console.error("Error fetching user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDelivery = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await _get("delivery");
      if (response.data.status === "success") {
        const deliveryData = response.data.data;
        setDeliveryList(deliveryData);
      } else {
        setError("Failed to fetch delivery data");
      }
    } catch (error) {
      setError("Error fetching delivery data");
      console.error("Error fetching delivery data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTrips = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await _get("assignroute");
      if (response.data.status === "success") {
        const filteredTrips = response.data.data;
        const tripsId = filteredTrips.map(trip => trip.id); // Extract all IDs from the data array

        console.log('tripsId', tripsId);
        setTripList(filteredTrips);
        setTripsId(tripsId); // Store all trip IDs in state
      } else {
        setError("Failed to fetch trip data");
      }
    } catch (error) {
      setError("Error fetching trip data");
      console.error("Error fetching trip data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    const indexOfLastRecord = pageNumber * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

    const filteredData = selectedTrip
      ? [...data.filter(user => user.routeId === selectedTrip), ...data.filter(user => user.routeId !== selectedTrip)]
      : data;

    setUserList(filteredData.slice(indexOfFirstRecord, indexOfLastRecord));
  };

  const prevPage = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const nextPage = () => {
    const totalPages = Math.ceil(data.length / recordsPerPage);
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  const handleDeliveryChange = (event) => {
    setSelectedDeliveryBoy(event.target.value);
  };


  const handleUserSelect = async (userId) => {
    const user = data.find(user => user.userId === userId);
    if (!user) return;

    // Check if the user is already selected
    const isSelected = selectedUserIds.includes(userId);

    setSelectedUserIds((prevSelectedUserIds) => {
      if (!isSelected) {
        // If not selected, add the user ID
        console.log('selectedUserIds-if', selectedUserIds)

        return [...prevSelectedUserIds, userId];
      }
      else {
        // If already selected, remove the user ID
        console.log('selectedUserIds-else', selectedUserIds)

        return prevSelectedUserIds.filter((id) => id !== userId);

      }

    });
    console.log('selectedUserIds-common', selectedUserIds)

  };


  const assignDelivery = async () => {
    setIsLoading(true);
    if (!selectedDeliveryBoy || !selectedTrip) {
      toast.error("Please select a delivery boy, a trip, and at least one user.");
      return;
    }

    const validUserIds = selectedUserIds.filter((id) => id !== null && id !== undefined);

    try {
      // If the delivery boy is different, create a new assignment
      const data = {
        routeId: selectedTrip,
        userIds: validUserIds,
        deliveryBoyId: parseInt(selectedDeliveryBoy),
      };

      const response = await _put(`/assignroute/${selectedTrip}`, data);
      if (response.data.status === "success") {
        toast.success("Delivery boy assigned successfully");
        history.push("/trip-detail");
      } else {
        toast.error("Failed to assign delivery boy");
      }


    } catch (error) {
      console.error("Error assigning delivery boy:", error);
      toast.error("Error assigning delivery boy");
    } finally {
      setIsLoading(false);
    }
  };



  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container">
          <Row>
            <Col sm="12">
              <Card>
                <Card.Header className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                  <div className="mb-3 mb-sm-0">
                    <Card.Header.Title>
                      <h4 className="card-title mb-0 daily-title">
                        {isReadMode ? "Read User" : "Assign Delivery"}
                      </h4>
                    </Card.Header.Title>
                  </div>
                  <div className="d-flex align-items-center justify-center">
                    <h6>Select your delivery Boy</h6>
                    <Form.Select
                      className="w-auto me-3 mb-3 mb-sm-0"
                      value={selectedDeliveryBoy}
                      onChange={handleDeliveryChange}
                      readOnly={isReadMode}
                    >
                      <option value="" disabled>
                        Select a delivery boy
                      </option>
                      {deliveryList.map((delivery, index) => (
                        <option key={index} value={delivery.id}>
                          {delivery.fname + " " + delivery.lname} ({delivery.assignedUsersCount})
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className="d-flex align-items-center justify-center" id="Trip">
                    <h6>Select your Trip</h6>
                    <Form.Select
                      className="w-auto me-3 mb-3 mb-sm-0"
                      value={selectedTrip}
                      onChange={handleTripChange}
                      readOnly={isReadMode}
                    >
                      <option value="" disabled>
                        Select the trip
                      </option>
                      {tripList.map((trip, index) => (
                        <option key={index} value={trip.id}>
                          {trip.routeName} ({trip.userCount})
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </Card.Header>
                <Card.Body className="px-0">
                  <div className="table-responsive">
                    <table
                      id="user-list-table"
                      className="table"
                      role="grid"
                      data-toggle="data-table"
                    >
                      <thead>
                        <tr className="light text-center">
                          <th>Profile</th>
                          <th>Name</th>
                          <th>Contact</th>
                          <th>Address</th>
                          <th>Route Name</th>
                          <th>Select</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan="6" className="text-center">
                              <Loader /> {/* Loader component */}
                            </td>
                          </tr>
                        ) : userList.length === 0 ? (
                          <tr>
                            <td colSpan="6" className="text-center no-data pt-5">
                              No data found
                            </td>
                          </tr>
                        ) : (
                          userList.map((user, index) => (
                            <tr key={index} className="text-center">
                              <td style={{ width: "5%", marginLeft: "auto", marginRight: 'auto' }}>
                                <Avatar firstName={user.fname} lastName={user.lname} />
                              </td>
                              <td>{user.fname + " " + user.lname}</td>
                              <td>{user.phone}</td>
                              <td>
                                {user.address[0].doorNo}, {user.address[0].street}, {user.address[0].city}
                              </td>
                              <td>{user.routeName || "N/A"}</td>
                              <td>
                                <Form.Check
                                  onChange={() => handleUserSelect(user.userId)}
                                  checked={selectedUserIds.includes(user.userId)}
                                />
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
                <Card.Footer>
                  {!isReadMode && (
                    <Button onClick={assignDelivery} className="m-2">
                      Assign Delivery
                    </Button>
                  )}
                  {data.length > recordsPerPage && (
                    <div className="d-flex justify-content-center">
                      <Button
                        onClick={prevPage}
                        disabled={currentPage === 1}
                        className="m-2"
                      >
                        Prev
                      </Button>
                      <div className="text-center m-2 p-2">
                        {currentPage}/{Math.ceil(data.length / recordsPerPage)}
                      </div>
                      <Button
                        onClick={nextPage}
                        disabled={currentPage === Math.ceil(data.length / recordsPerPage)}
                        className="m-2"
                      >
                        Next
                      </Button>
                    </div>
                  )}
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </div>
      )}
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default DeliveryAssignAdd;
