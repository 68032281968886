import React, { useState, useEffect } from "react";
import { Row, Col, Form, Modal, Button } from 'react-bootstrap';
import DateRangeSlider from "../MenuList/DateRangeSlider";
import { useHistory } from "react-router-dom";
import Card from '../../../components/card';
import Avatar from './avatar/avatar';
import "../../../assets/scss/custom/components/profile/profile_button.scss";
import { _get, _put, _post } from "../../../apiClient";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../Loader";
import DateFormats from "../../dashboard/app/Pipe/dateFormater";
import MapComponent from "../maps/mapBound";

const UserProfile = () => {
   const token = localStorage.getItem("token");
   const [userList, setUserList] = useState({});
   const [userList1, setUserList1] = useState({});
   const [address, setAddress] = useState({});
   const [showModal, setShowModal] = useState(false);
   const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
   const [reasons, setReasons] = useState([]);
   const [selectedReasons, setSelectedReasons] = useState([]);
   const [isButtonDisabled, setIsButtonDisabled] = useState(false);
   const [additionalReason, setAdditionalReason] = useState("");
   const [apiResponse, setApiResponse] = useState("");
   const [isLoading, setIsLoading] = useState(true);
   const [showPlan, setshowPlan] = useState(false);
   let history = useHistory();

   useEffect(() => {
      if (token) {
         fetchData();
      }
   }, [token]);

   const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([userProfile(), addressUser()]);
      setIsLoading(false);
   };

   const userProfile = async () => {
      try {
         const response = await _get('user');
         if (response.data.status === 'success') {
            if(response.data.data.issubscribed){
               setshowPlan(true);
            }
            setUserList(response.data.data);
            setUserList1(response.data.data);
         }
      } catch (error) {
         console.error('Error fetching user profile:', error);
      }
   };

   const addressUser = async () => {
      try {
         const response = await _get('address');
         if (response.data.status === 'success') {
            setAddress(response.data.data[0]);
         }
      } catch (error) {
         console.error('Error fetching user address:', error);
      }
   };

   const handleProfileUpdate = async (values) => {
      setIsLoading(true);
      try {
         const profileResponse = await _put('user', {
            fname: values.fname,
            lname: values.lname,
            phone: values.phone
         });

         const addressResponse = await _post(`address?addressId=${address.addressId}`, {
            doorNo: values.doorNo,
            city: values.city,
            street: values.street,
            state: values.state,
            pincode: values.pincode,
            landMark: values.landMark,
            country: values.country,
            // primary: true
         });

         if (profileResponse.data.status === 'success' && addressResponse.data.status === 'success') {
            setApiResponse("Profile and address updated successfully");
         } else {
            setApiResponse("Error updating profile or address");
         }
         setShowModal(true);
      } catch (error) {
         console.error('Error updating profile or address:', error);
         setApiResponse("Error updating profile or address");
         setShowModal(true);
      } finally {
         setIsLoading(false);
      }
   };

   const handleCancel = () => {
      history.push("/");
   };

   const handleCloseModal = () => {
      setShowModal(false);
      fetchData();
   };

   const handleUnsubscribeClick = async () => {
      try {
         const response = await _get('reason');
         if (response.data.status === 'success') {
            setReasons(response.data.data);
            setShowUnsubscribeModal(true);
         }
      } catch (error) {
         console.error('Error fetching unsubscribe reasons:', error);
      }
   };
   const handleCheckboxChange = (reason) => {
      setSelectedReasons((prevSelectedReasons) => {
         if (prevSelectedReasons.includes(reason)) {
            return prevSelectedReasons.filter((r) => r !== reason);
         } else {
            return [...prevSelectedReasons, reason];
         }
      });
   };

   const handleUnsubscribe = async () => {
      try {
         const reasonIds = reasons
            .filter(reason => selectedReasons.includes(reason.reason))
            .map(reason => reason.id);
         const cancelledDate = new Date().toISOString().split('T')[0];

         const requestBody = {
            reason_ids: reasonIds,
            others: selectedReasons.includes("others") ? additionalReason : "",
            cancelleddate: cancelledDate
         };

         const response = await _post('unsubscribereq', requestBody);
         if (response.data.status === 'success') {
            setApiResponse("Successfully unsubscribed");
            setIsButtonDisabled(true); // Disable the unsubscribe button
         } else {
            setApiResponse("Error during unsubscription");
         }
         setShowUnsubscribeModal(false);
         setShowModal(true);
      } catch (error) {
         console.error('Error during unsubscription:', error);
         setApiResponse("Error during unsubscription");
         setShowUnsubscribeModal(false);
         setShowModal(true);
      }
   };




   const formik = useFormik({
      initialValues: {
         fname: userList.fname || '',
         lname: userList.lname || '',
         phone: userList.phone || '',
         startdate: userList.startdate || '',
         expirydate: userList.expirydate || '',
         doorNo: address.doorNo || '',
         city: address.city || '',
         street: address.street || '',
         state: address.state || '',
         pincode: address.pincode || '',
         landMark: address.landMark || '',
         country: address.country || ''
      },
      enableReinitialize: true,
      validationSchema: Yup.object({
         fname: Yup.string().required("First Name is required"),
         lname: Yup.string().required("Last Name is required"),
         phone: Yup.string().required("Phone is required").matches(/^\d{10}$/, 'Phone number is not valid'),
         doorNo: Yup.string().required("Door No is required"),
         city: Yup.string().required("City is required"),
         street: Yup.string().required("Street is required"),
         state: Yup.string().required("State is required"),
         pincode: Yup.string().required("Pincode is required").matches(/^\d{6}$/, 'Pincode is not valid'),
         landMark: Yup.string().required("Land Mark is required"),
         country: Yup.string().required("Country is required"),
      }),
      onSubmit: handleProfileUpdate,
   });

   return (
      <>
         {isLoading ? (
            <Loader />
         ) : (
            <div className="container">
               <Row>
                  <Col lg="12">
                     <div className="profile-content tab-content">
                        <div id="profile-feed" className="tab-pane fade active show">
                           <Card>
                              <Card.Header className="border-0 pb-3 px-2">
                                 <Card.Header.Title>
                                    <div className="d-flex flex-wrap align-items-center">
                                       <div className="media-support-user-img me-4">
                                          <Avatar firstName={userList1.fname} lastName={userList1.lname} />
                                       </div>
                                       <div className="media-support-info mt-2">
                                          <h6 className="mb-0 heading-title fw-bolder">{userList1.fname + " " + userList1.lname}</h6>
                                       </div>
                                    </div>
                                 </Card.Header.Title>
                              </Card.Header>
                              <Card.Body className="p-0">
                                 <div className="comment-area p-4">
                                    <form onSubmit={formik.handleSubmit}>
                                       <h6 className="mb-5 heading-title fw-bolder">Edit Profile</h6>
                                       <div className="row">
                                          <Form.Group className="col-md-6 mb-2">
                                             <Form.Label htmlFor="fname">First Name:</Form.Label>
                                             <Form.Control
                                                type="text"
                                                id="fname"
                                                name="fname"
                                                placeholder="First Name"
                                                value={formik.values.fname}
                                                onChange={formik.handleChange}
                                                isInvalid={!!formik.errors.fname}
                                             />
                                             <Form.Control.Feedback type="invalid">
                                                {formik.errors.fname}
                                             </Form.Control.Feedback>
                                          </Form.Group>
                                          <Form.Group className="col-md-6 mb-2">
                                             <Form.Label htmlFor="lname">Last Name:</Form.Label>
                                             <Form.Control
                                                type="text"
                                                id="lname"
                                                name="lname"
                                                placeholder="Last Name"
                                                value={formik.values.lname}
                                                onChange={formik.handleChange}
                                                isInvalid={!!formik.errors.lname}
                                             />
                                             <Form.Control.Feedback type="invalid">
                                                {formik.errors.lname}
                                             </Form.Control.Feedback>
                                          </Form.Group>
                                       </div>
                                       <div className="row">
                                          <Form.Group className="col-md-6 mb-2">
                                             <Form.Label htmlFor="phone">Mobile No:</Form.Label>
                                             <Form.Control
                                                type="text"
                                                id="phone"
                                                name="phone"
                                                placeholder="Mobile No"
                                                maxLength={10}
                                                value={formik.values.phone}
                                                onChange={formik.handleChange}
                                                isInvalid={!!formik.errors.phone}
                                             />
                                             <Form.Control.Feedback type="invalid">
                                                {formik.errors.phone}
                                             </Form.Control.Feedback>
                                          </Form.Group>
                                       </div>
                                       <h6 className="mb-5 mt-5 heading-title fw-bolder">Address</h6>
                                       <div className="row">
                                          <Form.Group className="col-md-6 mb-2">
                                             <Form.Label htmlFor="city">City:</Form.Label>
                                             <Form.Control
                                                type="text"
                                                id="city"
                                                name="city"
                                                placeholder="City"
                                                value={formik.values.city}
                                                onChange={formik.handleChange}
                                                isInvalid={!!formik.errors.city}
                                             />
                                             <Form.Control.Feedback type="invalid">
                                                {formik.errors.city}
                                             </Form.Control.Feedback>
                                          </Form.Group>
                                          <Form.Group className="col-md-6 mb-2">
                                             <Form.Label htmlFor="doorNo">Door No:</Form.Label>
                                             <Form.Control
                                                type="text"
                                                id="doorNo"
                                                name="doorNo"
                                                placeholder="Door No"
                                                value={formik.values.doorNo}
                                                onChange={formik.handleChange}
                                                isInvalid={!!formik.errors.doorNo}
                                             />
                                             <Form.Control.Feedback type="invalid">
                                                {formik.errors.doorNo}
                                             </Form.Control.Feedback>
                                          </Form.Group>
                                       </div>
                                       <div className="row">
                                          <Form.Group className="col-md-6 mb-2">
                                             <Form.Label htmlFor="street">Street:</Form.Label>
                                             <Form.Control
                                                type="text"
                                                id="street"
                                                name="street"
                                                placeholder="Street"
                                                value={formik.values.street}
                                                onChange={formik.handleChange}
                                                isInvalid={!!formik.errors.street}
                                             />
                                             <Form.Control.Feedback type="invalid">
                                                {formik.errors.street}
                                             </Form.Control.Feedback>
                                          </Form.Group>
                                          <Form.Group className="col-md-6 mb-2">
                                             <Form.Label htmlFor="landMark">Land Mark:</Form.Label>
                                             <Form.Control
                                                type="text"
                                                id="landMark"
                                                name="landMark"
                                                placeholder="Land Mark"
                                                value={formik.values.landMark}
                                                onChange={formik.handleChange}
                                                isInvalid={!!formik.errors.landMark}
                                             />
                                             <Form.Control.Feedback type="invalid">
                                                {formik.errors.landMark}
                                             </Form.Control.Feedback>
                                          </Form.Group>
                                       </div>
                                       <div className="row">
                                          <Form.Group className="col-md-6 mb-2">
                                             <Form.Label htmlFor="state">State:</Form.Label>
                                             <Form.Control
                                                type="text"
                                                id="state"
                                                name="state"
                                                placeholder="State"
                                                value={formik.values.state}
                                                onChange={formik.handleChange}
                                                isInvalid={!!formik.errors.state}
                                             />
                                             <Form.Control.Feedback type="invalid">
                                                {formik.errors.state}
                                             </Form.Control.Feedback>
                                          </Form.Group>
                                          <Form.Group className="col-md-6 mb-2">
                                             <Form.Label htmlFor="country">Country:</Form.Label>
                                             <Form.Control
                                                type="text"
                                                id="country"
                                                name="country"
                                                placeholder="Country"
                                                value={formik.values.country}
                                                onChange={formik.handleChange}
                                                isInvalid={!!formik.errors.country}
                                             />
                                             <Form.Control.Feedback type="invalid">
                                                {formik.errors.country}
                                             </Form.Control.Feedback>
                                          </Form.Group>
                                       </div>
                                       <div className="row">
                                          <Form.Group className="col-md-6 mb-2">
                                             <Form.Label htmlFor="pincode">Pincode:</Form.Label>
                                             <Form.Control
                                                type="text"
                                                id="pincode"
                                                name="pincode"
                                                placeholder="Pincode"
                                                maxLength={6}
                                                value={formik.values.pincode}
                                                onChange={formik.handleChange}
                                                isInvalid={!!formik.errors.pincode}
                                             />
                                             <Form.Control.Feedback type="invalid">
                                                {formik.errors.pincode}
                                             </Form.Control.Feedback>
                                          </Form.Group>
                                       </div>

                                       <div className="d-flex mt-5 justify-content-end save-cancel-buttons">
                                          <button className="btn-cancel me-2" onClick={handleCancel}>Cancel</button>
                                          <button type="submit" className="btn-Add">Save</button>
                                       </div>
                                    </form>
                                 </div>
                              </Card.Body>
                           </Card>
                        </div>

                        
                        {showPlan && (<div id="profile-feed" className="tab-pane fade active show">
                           <Card>
                              <Card.Body>
                                 <h6 className="mb-5 mt-5 heading-title fw-bolder">Plan Type - {userList1.planType}</h6>
                                 <div className="row">
                                    <Form.Group className="col-md-6 mb-2">
                                       <Form.Label htmlFor="startdate">Start Date:</Form.Label>
                                       <Form.Control
                                          type="text"
                                          id="startdate"
                                          name="startdate"
                                          placeholder="Start Date"
                                          value={new Date(formik.values.startdate).toLocaleDateString('en-GB')}

                                          onChange={formik.handleChange}
                                          isInvalid={!!formik.errors.startdate}
                                          readOnly
                                       />
                                       <Form.Control.Feedback type="invalid">
                                          {formik.errors.startdate}
                                       </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="col-md-6 mb-2">
                                       <Form.Label htmlFor="expirydate">Expiry Date</Form.Label>
                                       <Form.Control
                                          type="text"
                                          id="expirydate"
                                          name="expirydate"
                                          placeholder="Expiry Date"
                                          value={new Date(formik.values.expirydate).toLocaleDateString('en-GB')}
                                          onChange={formik.handleChange}
                                          isInvalid={!!formik.errors.expirydate}
                                          readOnly
                                       />
                                       <Form.Control.Feedback type="invalid">
                                          {formik.errors.expirydate}
                                       </Form.Control.Feedback>
                                    </Form.Group>
                                 </div>
                                 <div className="d-flex mt-5 justify-content-end save-cancel-buttons">
                                    <div className="col-md-2 mb-2">
                                       <button className="btn-Unsub" onClick={handleUnsubscribeClick} disabled={isButtonDisabled}>
                                          Unsubscribe
                                       </button>
                                    </div>

                                 </div>

                              </Card.Body>
                           </Card>
                        </div>)}

                        <div className="mb-5">
                           <MapComponent />
                        </div>
                     </div>
                  </Col>
               </Row>
               <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                     <Modal.Title>Success</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{apiResponse}</Modal.Body>
                  <Modal.Footer>
                     <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                     </Button>
                  </Modal.Footer>
               </Modal>
               <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                     <Modal.Title>Success</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{apiResponse}</Modal.Body>
                  <Modal.Footer>
                     <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                     </Button>
                  </Modal.Footer>
               </Modal>
               <Modal show={showUnsubscribeModal} onHide={() => setShowUnsubscribeModal(false)}>
                  <Modal.Header closeButton>
                     <Modal.Title>Unsubscribe</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                     <Form.Group>
                        <Form.Label>Reason for Unsubscribing:</Form.Label>
                        {reasons.map((reason) => (
                           <Form.Check
                              key={reason.id}
                              type="checkbox"
                              label={reason.reason}
                              value={reason.reason}
                              checked={selectedReasons.includes(reason.reason)}
                              onChange={() => handleCheckboxChange(reason.reason)}
                           />
                        ))}
                     </Form.Group>
                     {selectedReasons.includes("others") && (
                        <Form.Group className="mt-3">
                           <Form.Label>Please specify:</Form.Label>
                           <Form.Control
                              type="text"
                              value={additionalReason}
                              onChange={(e) => setAdditionalReason(e.target.value)}
                           />
                        </Form.Group>
                     )}
                  </Modal.Body>
                  <Modal.Footer>
                     <Button variant="secondary" onClick={() => setShowUnsubscribeModal(false)}>
                        Cancel
                     </Button>
                     <Button variant="primary" onClick={handleUnsubscribe}>
                        Confirm
                     </Button>
                  </Modal.Footer>
               </Modal>



            </div>
         )}
      </>
   );
};

export default UserProfile;
