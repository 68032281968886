import React, { useState, useEffect } from "react";
import { Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Card from "../../../components/card";
import { _get } from "../../../apiClient";
import Avatar from "./avatar/avatar";
import Loader from "../Loader";

const TripAssign = () => {
    const [deliveryList, setDeliveryList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const history = useHistory();
    const token = localStorage.getItem("token");

    useEffect(() => {
        if (token) {
            fetchDelivery();
        }
    }, [token]);

    const editTooltip = (
        <Tooltip id="edit-tooltip">
            Edit
        </Tooltip>
    );
    const viewTooltip = (
        <Tooltip id="view-tooltip">
            View
        </Tooltip>
    );

    const fetchDelivery = async () => {
        setIsLoading(true);
        try {
            const response = await _get("assignroute");
            if (response.data.status === "success") {
                const deliveryData = response.data.data.filter(
                    delivery => delivery.userCount !== 0
                );
                console.log('delivery', deliveryData);
                setDeliveryList(deliveryData);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleView = (delivery, mode = 'view') => {
        history.push({
            pathname: "/delivery-details",
            state: { delivery, mode }
        });
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="container">
                    <Row>
                        <Col sm="12">
                            <Card>
                                <Card.Header className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                                    <div className="mb-3 mb-sm-0">
                                        <Card.Header.Title>
                                            <h4 className="card-title mb-0 daily-tilte">Trip Assign List</h4>
                                        </Card.Header.Title>
                                    </div>
                                </Card.Header>
                                <Card.Body className="px-0">
                                    <div className="table-responsive">
                                        <table
                                            id="user-list-table"
                                            className="table"
                                            role="grid"
                                            data-toggle="data-table"
                                        >
                                            <thead>
                                                <tr className="ligth text-center">
                                                    <th>Trip</th>
                                                    <th>Delivery Boy</th>
                                                    <th>Assign Delivery</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {deliveryList.length === 0 ? (
                                                    <tr>
                                                        <td colSpan="5" className="text-center no-date pt-5">
                                                            No data found
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    deliveryList.map((delivery, index) => (
                                                        <tr key={index} className="text-center">
                                                            <td>{delivery.routeName}</td>
                                                            <td>
                                                                {delivery.deliveryBoy ? `${delivery.deliveryBoy.fname} ${delivery.deliveryBoy.lname}` : 'N/A'}
                                                            </td>
                                                            <td>{delivery.userCount}</td>
                                                            <td>
                                                                <div className="flex align-items-center list-user-action">
                                                                    <OverlayTrigger placement="top" overlay={viewTooltip}>
                                                                        <Button className="btn btn-sm btn-icon btn-success m-1" data-toggle="tooltip" data-placement="top" onClick={() => handleView(delivery, "view")}>
                                                                            <span className="btn-inner">
                                                                                <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                    <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                 
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default TripAssign;
