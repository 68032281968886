import React from 'react';

function getInitials(firstName, lastName) {
    const firstInitial = firstName ? firstName[0].toUpperCase() : '';
    const lastInitial = lastName ? lastName[0].toUpperCase() : '';
    return firstInitial + lastInitial;
  }
  
const Avatar = ({ firstName, lastName }) => {
  const initials = getInitials(firstName, lastName);

  return (
    <div style={styles.avatar}>
      {initials}
    </div>
  );
};

const styles = {
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#329C6B',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: '300',
  },
};

export default Avatar;
