import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useLocation, useHistory } from "react-router-dom";
import Card from "../../../components/card";
import { _post, _put } from "../../../apiClient";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const DeliveryBoyAdd = () => {
  const location = useLocation();
  const { user, mode } = location.state || {};
  const isEditMode = mode === "edit";
  const isReadMode = mode === "read";
  const [validationMessage, setValidationMessage] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [repass, setRepass] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const history = useHistory();

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  useEffect(() => {
    if ((isEditMode || isReadMode) && user) {
      setFname(user.fname || "");
      setLname(user.lname || "");
      setPhone(user.phone || "");
    }
  }, [isEditMode, isReadMode, user]);

  const addAddress = async (e) => {
    e.preventDefault();
    setValidationMessage("");

    if (!phone) {
      setValidationMessage("All fields are required");
      return;
    }

    if (!validatePhone(phone)) {
      setValidationMessage("Please enter a valid 10-digit phone number");
      return;
    }

    if (!isReadMode && password !== repass) {
      setValidationMessage("Passwords do not match");
      return;
    }

    try {
      const adduserData = { fname, lname, phone, password };
      const endpoint = isEditMode ? `delivery/${user.id}` : "delivery";
      const response = isEditMode ? await _put(endpoint, adduserData) : await _post(endpoint, adduserData);
    
      if (response.data.status.toLowerCase() === "success") {
        toast(response.data.msg, { type: "success" });
    
        setTimeout(() => {
          history.push("/delivery-list");
        }, 1000);
      } else {
        toast(response.data.msg, { type: "error" });
      }
    } catch (error) {
      console.error("Error adding address:", error);

      toast("An error occurred. Please try again.", { type: "error" });
    }
    
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handlePhoneInput = (e) => {
    const value = e.target.value;
    const regex = /^[0-9\b]+$/;
    if (value === "" || regex.test(value)) {
      setPhone(value);
    }
  };

  return (
    <div className="container">
      <ToastContainer />
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">
                  {isReadMode
                    ? "Read User"
                    : isEditMode
                    ? "Edit Delivery Boy"
                    : "Create Delivery Boy"}
                </h4>
              </div>
              {isReadMode && (
                <Button
                  className="col-md-2"
                  onClick={() =>
                    history.push({
                      pathname: location.pathname,
                      state: { user, mode: "edit" },
                    })
                  }
                >
                  Edit
                </Button>
              )}
            </Card.Header>
            <Card.Body>
              <Col lg="12" className="d-flex justify-content-center">
                {validationMessage && (
                  <p className="text-danger">{validationMessage}</p>
                )}
              </Col>
              <div className="new-user-info">
                <form onSubmit={addAddress}>
                  <div className="row">
                    <Form.Group className="col-md-6 mb-2">
                      <Form.Label htmlFor="fname">First Name:</Form.Label>
                      <Form.Control
                        type="text"
                        id="fname"
                        placeholder="First Name"
                        value={fname}
                        onChange={(e) => setFname(e.target.value)}
                        readOnly={isReadMode}
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6 mb-2">
                      <Form.Label htmlFor="lname">Last Name:</Form.Label>
                      <Form.Control
                        type="text"
                        id="lname"
                        placeholder="Last Name"
                        value={lname}
                        onChange={(e) => setLname(e.target.value)}
                        readOnly={isReadMode}
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6 mb-2">
                      <Form.Label htmlFor="mobno">Mobile Number:</Form.Label>
                      <Form.Control
                        type="text"
                        id="mobno"
                        placeholder="Mobile Number"
                        value={phone}
                        maxLength={10}
                        onChange={handlePhoneInput}
                        readOnly={isReadMode}
                      />
                    </Form.Group>
                  </div>
                  {!isEditMode && !isReadMode && (
                    <>
                      <hr />
                      <h5 className="mb-3">Security</h5>
                      <div className="row">
                        <Form.Group className="col-md-6">
                          <Form.Label htmlFor="password">Password:</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type={passwordVisible ? "text" : "password"}
                              className="form-control-sm"
                              id="password"
                              aria-describedby="password"
                              placeholder="Enter a Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <InputGroup.Text
                              onClick={togglePasswordVisibility}
                              style={{ cursor: "pointer" }}
                            >
                              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group className="col-md-6">
                          <Form.Label htmlFor="rpass">Repeat Password:</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type={passwordVisible ? "text" : "password"}
                              className="form-control-sm"
                              id="confirm-password"
                              placeholder="Confirm Password"
                              value={repass}
                              onChange={(e) => setRepass(e.target.value)}
                            />
                            <InputGroup.Text
                              onClick={togglePasswordVisibility}
                              style={{ cursor: "pointer" }}
                            >
                              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </div>
                      {validationMessage && (
                        <p className="text-danger">{validationMessage}</p>
                      )}
                    </>
                  )}
                  {!isReadMode && (
                    <Button type="submit" variant="primary" className="m-3">
                      {isEditMode ? "Update Delivery Boy" : "Add Delivery Boy"}
                    </Button>
                  )}
                  <Button
                    type="button"
                    variant="secondary"
                    className="m-3"
                    onClick={() => history.push("/delivery-list")}
                  >
                    {isEditMode ? "Cancel" : "Close"}
                  </Button>
                </form>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DeliveryBoyAdd;
