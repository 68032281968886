import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const AddFoodModal = ({ show, onHide, onConfirm, addCount }) => {
    const [quantity, setQuantity] = useState(addCount);

    useEffect(() => {
        if (show) {
            setQuantity(addCount); // Reset quantity when modal opens
        } else {
            setQuantity(0); // Reset quantity when modal closes
        }
    }, [show, addCount]);

    const handleIncrease = () => setQuantity(prevQuantity => prevQuantity + 1);
    const handleDecrease = () => {
        if (quantity > 0) {
            setQuantity(prevQuantity => prevQuantity - 1);
        }
    };

    const handleConfirm = () => {
        onConfirm(quantity);
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Add Extra Food</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to add extra food?</p>
                <div className="d-flex align-items-center text-center">
                    <Button className='m-2' variant="outline-danger" onClick={handleDecrease}>-</Button>
                    <span>{quantity}</span>
                    <Button className='m-2' variant="outline-success" onClick={handleIncrease}>+</Button>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleConfirm}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddFoodModal;
