import React, { useEffect, useState } from 'react';
import DateRangeSlider from './DateRangeSlider';
import { _get, _post } from '../../../apiClient';
import '../../../assets/scss/custom/components/menu-style/menulist.scss';
import FoodImage from '../../../assets/images/dosa.jpg';
import ConfirmCancelButton from './CancelFoodModel';
import AddFoodModal from './AddFoodModel';
import { Modal, Button, Form } from 'react-bootstrap';
import { FaStar, FaShoppingCart } from 'react-icons/fa';
import { ShimmerButton, ShimmerText, ShimmerBarChart } from 'shimmer-effects-react';

const MenuList1 = () => {
    useEffect(() => {
        foodList();
    }, []);

    const [quantity, setQuantity] = useState(1);
    const [count, setCount] = useState(0);
    const [foodDetails, setFoodDetails] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [cancelResult, setCancelResult] = useState(null);
    const [selectedMeal, setSelectedMeal] = useState(null);
    const [modalData, setModalData] = useState({ initialQuantity: 0, addCount: 0 });
    const [filteredMenu, setFilteredMenu] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);

    const [showAddModal, setShowAddModal] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [showResponseModal, setShowResponseModal] = useState(false);
    const [quantities, setQuantities] = useState({ breakfast: 0, lunch: 0, dinner: 0 });

    // State for handling show more/less
    const [showMore, setShowMore] = useState({ breakfast: false, lunch: false, dinner: false });

    // State for rating modal
    const [showRatingModal, setShowRatingModal] = useState(false);
    const [rating, setRating] = useState(0);
    const [ratingMeal, setRatingMeal] = useState('');
    const [ratingMessage, setRatingMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Reset rating and message when closing rating modal
        if (!showRatingModal) {
            setRating(0);
            setRatingMessage('');
        }
    }, [showRatingModal]);

    const handleDayOrderChange = (newDayOrder, year, month, day) => {
        const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
        setSelectedDate(formattedDate);
        setCount(newDayOrder);
        selectedMenu(formattedDate);
    };

    const handleIncrease = () => {
        setQuantity(quantity + 1);
    };

    const handleCancel = (meal) => {
        setDialogOpen(true);
        setSelectedMeal(meal);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleConfirmCancel = async () => {
        try {
            const formattedDate = filteredMenu?.date;
            let foodneeddate;
            if (formattedDate) {
                const [day, month, year] = formattedDate.split('/');
                foodneeddate = `${year}-${month}-${day}`;
            }
            const dayOrder = filteredMenu?.dayOrder;
            const cancelData = {
                foodneeddate,
                dayOrder,
                foodType: selectedMeal,
                addcount: quantities[selectedMeal],
                cancelled: true
            };
            const response = await _post('addfood', cancelData);
            setResponseMessage(`${response.data.msg}`);
            foodList();
            setShowResponseModal(true);
        } catch (error) {
            console.error(error);
        }
        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [selectedMeal]: 0
        }));
        setDialogOpen(false);
    };

    const foodList = async () => {
        try {
            const dateapi = new Date();

            const day1 = dateapi.getDate();
            const month2 = dateapi.getMonth() + 1;
            console.log('month2', month2)
            const year2 = dateapi.getFullYear();

            const formattedDate1 = `${day1}/${month2}/${year2}`;
            let apiPassDate;
            if (formattedDate1) {
                const [day, month, year] = formattedDate1.split('/');
                apiPassDate = `${month}`;
                console.log('apiPassDate', apiPassDate)
            }

            const response = await _get(`filtermenu/1?month=${apiPassDate}`);
            const responseData = response.data.data;
            console.log('responseData', responseData)
            setFoodDetails(responseData);

            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1;
            const day = currentDate.getDate();
            const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

            setFilteredMenu(responseData.find(menu => menu.date === formattedDate));
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const selectedMenu = (selectedDate1) => {
        try {
            setFilteredMenu(foodDetails.find(menu => menu.date === selectedDate1));
        } catch (error) {
            console.error(error);
        }
    };

    const openAddModal = (meal) => {
        setSelectedMeal(meal);
        setModalData({
            initialQuantity: 0,
            addCount: filteredMenu.foodDetails[meal]?.addCount || 0
        });
        setShowAddModal(true);
    };

    const handleAddConfirm = async (quantity) => {
        try {
            const formattedDate = filteredMenu?.date;
            let foodneeddate;
            if (formattedDate) {
                const [day, month, year] = formattedDate.split('/');
                foodneeddate = `${year}-${month}-${day}`;
            }
            const dayOrder = filteredMenu?.dayOrder;
            const addData = {
                foodneeddate,
                dayOrder,
                foodType: selectedMeal,
                addcount: quantity,
                cancelled: false
            };
            const response = await _post('addfood', addData);
            setResponseMessage(`${response.data.msg}`);
            foodList();
            setShowResponseModal(true);
        } catch (error) {
            console.error(error);
        }
        setShowAddModal(false);
        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [selectedMeal]: 0
        }));
    };

    const toggleShowMore = (meal) => {
        setShowMore((prevShowMore) => ({
            ...prevShowMore,
            [meal]: !prevShowMore[meal]
        }));
    };

    const renderFoodDetails = (meal) => {
        const items = filteredMenu?.foodDetails?.[meal]?.items || '';
        const isShowMore = showMore[meal];
        const shouldShowMore = items.length > 4;
        const truncatedText = shouldShowMore ? items.slice(0, 28) + '...' : items;

        return (
            <div>
                <p
                    className={`card-title mb-md-0 menu-card-title-lun`}
                    onClick={() => toggleShowMore(meal)}
                    style={{ cursor: 'pointer' }}
                >
                    {isShowMore ? items : truncatedText}
                </p>
            </div>
        );
    };


    const isBreakfastDisabled = () => {
        if (filteredMenu?.date) {
            const now = new Date();
            const [day, month, year] = filteredMenu.date.split('/').map(Number);
            const menuDate = new Date(year, month - 1, day);

            if (now.toDateString() === menuDate.toDateString()) {
                const breakfastCutoff = new Date(now);
                breakfastCutoff.setHours(6, 30, 0, 0);
                return now >= breakfastCutoff;
            }
        }
        return true;
    };

    const isLunchDisabled = () => {
        if (filteredMenu?.date) {
            const now = new Date();
            const [day, month, year] = filteredMenu.date.split('/').map(Number);
            const menuDate = new Date(year, month - 1, day);

            if (now.toDateString() === menuDate.toDateString()) {
                const lunchCutoff = new Date(now);
                lunchCutoff.setHours(11, 0, 0, 0);
                return now >= lunchCutoff;
            }
        }
        return true;
    };

    const isDinnerDisabled = () => {
        if (filteredMenu?.date) {
            const now = new Date();
            const [day, month, year] = filteredMenu.date.split('/').map(Number);
            const menuDate = new Date(year, month - 1, day);

            if (now.toDateString() === menuDate.toDateString()) {
                const dinnerCutoff = new Date(now);
                dinnerCutoff.setHours(17, 30, 0, 0);
                return now >= dinnerCutoff;
            }
        }
        return true;
    };

    const renderButtons = (meal) => {
        if (filteredMenu && filteredMenu.date) {
            const [day, month, year] = filteredMenu.date.split('/').map(Number);
            const menuDate = new Date(year, month - 1, day);
            const now = new Date();
    
            const addCount = filteredMenu.foodDetails[meal]?.addCount || 0;
            const feedback = filteredMenu.foodDetails[meal]?.feedback || null;
            const cancelled = filteredMenu.foodDetails[meal]?.cancelled || false;
    
            const isRated = feedback !== null;
    
            if (menuDate > now) {
                return (
                    <div className="d-flex align-items-center mt-auto mt-md-0">
                        <button 
                            className={`btn-cancel me-2 ${cancelled ? 'btn-canceld' : ''}`}
                            onClick={() => handleCancel(meal)}
                            disabled={cancelled}  // Optionally disable the button if cancelled is true
                        >
                            {cancelled ? 'Cancelled' : 'Cancel'}
                        </button>
                        <button
                            className={`btn-Add ${isRated ? 'btn-blurred' : 'btn-added'}`}
                            onClick={() => openAddModal(meal, addCount)}
                            disabled={isRated}
                        >
                            {isRated ? 'Rated' : 'Add Item'}
                        </button>
                    </div>
                );
            } else if (menuDate.toDateString() === now.toDateString()) {
                const cutoffTime = new Date(now);
                if (meal === 'breakfast') {
                    cutoffTime.setHours(6, 30, 0, 0);
                } else if (meal === 'lunch') {
                    cutoffTime.setHours(11, 0, 0, 0);
                } else if (meal === 'dinner') {
                    cutoffTime.setHours(17, 30, 0, 0);
                }
    
                if (now < cutoffTime) {
                    return (
                        <div className="d-flex align-items-center mt-auto mt-md-0">
                            <button 
                                className={`btn-cancel me-2 ${cancelled ? 'btn-canceld' : ''}`}
                                onClick={() => handleCancel(meal)}
                                disabled={cancelled}
                            >
                                {cancelled ? 'Cancelled' : 'Cancel'}
                            </button>
                            <button
                                className={`btn-Add ${isRated ? 'btn-blurred' : 'btn-added'}`}
                                onClick={() => openAddModal(meal, addCount)}
                                disabled={isRated}
                            >
                                {isRated ? 'Rated' : 'Add Item'}
                            </button>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <button
                                className={`btn-Add ${isRated ? 'btn-blurred' : ''}`}
                                onClick={() => openRatingModal(meal)}
                                disabled={isRated}
                            >
                                {isRated ? 'Rated' : 'Ratings'}
                            </button>
                        </div>
                    );
                }
            } else {
                return (
                    <div>
                        <button
                            className={`btn-Add ${isRated ? 'btn-blurred' : ''}`}
                            onClick={() => openRatingModal(meal)}
                            disabled={isRated}
                        >
                            {isRated ? 'Rated' : 'Ratings'}
                        </button>
                    </div>
                );
            }
        }
        return null;
    };
    
    

    const getCardClass = (meal) => {
        return filteredMenu?.foodDetails?.[meal]?.cancelled ? 'bg-canceled' : '';
    };

    const openRatingModal = (meal) => {
        setRatingMeal(meal);
        setShowRatingModal(true);
    };

    const handleRatingSubmit = async () => {
        try {
            const foodneeddate = filteredMenu?.date;
            const dayOrder = filteredMenu?.dayOrder;
            const ratingData = {
                dayOrder,
                foodType: ratingMeal,
                rating,
                msg: ratingMessage,
                items: filteredMenu?.foodDetails?.[ratingMeal]?.items,
                foodId: filteredMenu?.foodDetails?.[ratingMeal]?.foodId,
                deliveredAt: foodneeddate
            };
            const response = await _post('feedback', ratingData);
            foodList();
            setResponseMessage(`${response.data.msg}`);
            setShowResponseModal(true);
        } catch (error) {
            console.error(error);
        }
        setShowRatingModal(false);
        setRating(0);
        setRatingMessage('');
    };

    const renderStars = () => {
        return [...Array(5)].map((_, index) => {
            const ratingValue = index + 1;
            return (
                <FaStar
                    key={ratingValue}
                    size={30}
                    color={ratingValue <= rating ? '#ffc107' : '#e4e5e9'}
                    onClick={() => setRating(ratingValue)}
                    style={{ cursor: 'pointer', marginRight: '5px' }}
                />
            );
        });
    };

    return (
        <div>
            <ConfirmCancelButton isOpen={dialogOpen} onClose={handleDialogClose} onConfirm={handleConfirmCancel} />
            <AddFoodModal show={showAddModal} onHide={() => setShowAddModal(false)} onConfirm={handleAddConfirm} initialQuantity={modalData.initialQuantity} addCount={modalData.addCount} />
            <h3 className="menu-title mb-3">Today’s Menu</h3>
            <div>
                <div className="row">
                    <div className="col-12 col-md-3 order-1 order-md-2 mb-2 mb-md-0">
                        <DateRangeSlider onDayOrderChange={handleDayOrderChange} />
                    </div>
                    <div className="col-12 col-md-9 col-sm-12 d-flex flex-column flex-md-row order-2 order-md-1">
                        {/* Breakfast Card */}
                        <div className="col-12 col-md-4 col-sm-12 mb-2 me-md-2 d-flex align-items-stretch">
                            <div className={`card shadow rounded w-100 ${getCardClass('breakfast')}`}>
                                <div className="card-body flex-md-row align-items-start align-items-md-center food-body">
                                    <div className='day-food-img'>
                                        <img src={FoodImage} className="foodImg mb-3 mb-md-0" />
                                    </div>
                                    <div className="pt-2">
                                        <div className='d-flex'>
                                            <h5 className='food-types'>
                                                Breakfast Meal
                                                {filteredMenu?.foodDetails?.breakfast?.addCount > 0 && (
                                                    <span className="ms-2">
                                                        Extra:{filteredMenu.foodDetails.breakfast.addCount}
                                                    </span>
                                                )}
                                            </h5>
                                        </div>
                                        {renderFoodDetails('breakfast')}
                                        {renderButtons('breakfast', isBreakfastDisabled())}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Lunch Card */}
                        <div className="col-12 col-md-4 col-sm-12 mb-2 me-md-2 d-flex align-items-stretch">
                            <div className={`card shadow rounded w-100 ${getCardClass('lunch')}`}>
                                <div className="card-body flex-md-row align-items-start align-items-md-center food-body">
                                    <div className='day-food-img'>
                                        <img src={FoodImage} className="foodImg mb-3 mb-md-0" />
                                    </div>
                                    <div className="pt-2">
                                        <div className='d-flex'>
                                            <h5 className='food-types'>
                                                Lunch Meal
                                                {filteredMenu?.foodDetails?.lunch?.addCount > 0 && (
                                                    <span className="ms-2">
                                                         Extra:{filteredMenu.foodDetails.lunch.addCount}
                                                    </span>
                                                )}
                                            </h5>
                                        </div>
                                        {renderFoodDetails('lunch')}
                                        {renderButtons('lunch', isLunchDisabled())}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Dinner Card */}
                        <div className="col-12 col-md-4 col-sm-12 mb-2 d-flex align-items-stretch">
                            <div className={`card shadow rounded w-100 ${getCardClass('dinner')}`}>
                                <div className="card-body flex-md-row align-items-start align-items-md-center food-body">
                                    <div className='day-food-img'>
                                        <img src={FoodImage} className="foodImg mb-3 mb-md-0" />
                                    </div>
                                    <div className="pt-2">
                                        <div className='d-flex'>
                                            <h5 className='food-types'>
                                                Dinner Meal
                                                {filteredMenu?.foodDetails?.dinner?.addCount > 0 && (
                                                    <span className="ms-2">
                                                         Extra:{filteredMenu.foodDetails.dinner.addCount}
                                                    </span>
                                                )}
                                            </h5>
                                        </div>
                                        {renderFoodDetails('dinner')}
                                        {renderButtons('dinner', isDinnerDisabled())}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showResponseModal} onHide={() => setShowResponseModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Response</Modal.Title>
                </Modal.Header>
                <Modal.Body>{responseMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowResponseModal(false)}>Done</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showRatingModal} onHide={() => setShowRatingModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Food Ratings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-center">
                        {renderStars()}
                    </div>
                    <Form.Group controlId="ratingMessage" className="mt-3">
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={ratingMessage}
                            onChange={(e) => setRatingMessage(e.target.value)}
                            placeholder="Enter your feedback here..."
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowRatingModal(false)}>Close</Button>
                    <Button variant="primary" onClick={handleRatingSubmit}>Submit</Button>
                </Modal.Footer>
            </Modal>
        </div>

    );
};

export default MenuList1;
