import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const DeliveryActive = ({
  isSubscribed,
  onSubscribe,
  onUnsubscribe,
  userName,
  userPhone,
  onEditUser,
  errorMessage,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [action, setAction] = useState('');

  const handleToggleClick = () => {
    setShowPopup(true);
    setAction(isSubscribed ? 'unsubscribe' : 'subscribe');
  };

  const handleConfirmAction = async () => {
    try {
      if (action === 'subscribe') {
        await onSubscribe(setStatusMessage);
      } else if (action === 'unsubscribe') {
        await onUnsubscribe(setStatusMessage);
      }
    } catch (error) {
      setStatusMessage('Action failed. Please try again.');
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setStatusMessage('');
  };

  const handleEdit = () => {
    onEditUser();
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Form>
        <Form.Check 
          type="switch"
          id="custom-switch"
          checked={isSubscribed}
          readOnly
          onClick={handleToggleClick}
          style={{ display: 'flex', justifyContent: 'center' }}
        />
      </Form>
      <Modal show={showPopup} onHide={handleClosePopup} centered>
        <Modal.Header closeButton>
          <Modal.Title>{statusMessage ? 'Status' : 'Confirm Action'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {statusMessage ? (
            <>
              <p>{statusMessage}</p>
            </>
          ) : (
            <p>
              {action === 'subscribe'
                ? `Are you sure you want to Active this Delivery Boy? ${userName} - (${userPhone})?`
                : `Are you sure you want to Deactive this Delivery Boy? ${userName} - (${userPhone})?`}
            </p>
          )}
          {errorMessage && <p style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</p>}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          {!statusMessage && (
            <>
              <Button variant="primary" onClick={handleConfirmAction} className="me-2">
                {action === 'subscribe' ? 'Active' : 'Deactive'}
              </Button>
              <Button variant="secondary" onClick={handleClosePopup}>
                Cancel
              </Button>
            </>
          )}
          {statusMessage && !statusMessage.includes('Successfully') && (
            <Button variant="success" onClick={handleEdit}>
              Add
            </Button>
          )}
          {statusMessage && (
            <Button variant="secondary" onClick={handleClosePopup}>
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeliveryActive;
