import React, { useState, useEffect } from 'react';
import { Row, Col, Dropdown, Nav, Tab, Button, Modal, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Card from '../../../src/components/card';
import Chart from 'react-apexcharts';
// admin.js
import Loader from './Loader.js';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import { _get, _post } from '../../apiClient.js';
import { gsap } from 'gsap';
import Widgets from './widget/matrics.js';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { FaDownload } from "react-icons/fa";
import menu10 from '../../assets/images/menu/10.png';
SwiperCore.use([Navigation]);

const Admin = () => {
    const [data, setData] = useState([]);
    const [menu, setMenu] = useState([]);
    const [foodtype, setFoodType] = useState('');
    const [showDownloadModel, setshowDownloadModel] = useState(false);
    const [food, setFood] = useState([]);
    const [filter, setFilter] = useState('all');
    const itemsPerPageF = 8;
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 2;
    const [isLoading, setIsLoading] = useState(true); // Initialize loader state to true

    useEffect(() => {
        fetchData();
        fetchMenu();
        fetchFood();
    }, []);

    const fetchMenu = async () => {
        try {
            const response = await _get('/menu/1');
            const responseData = response.data.data; // Access the data property correctly
            if (Array.isArray(responseData)) {
                setMenu(responseData);
            } else {
                console.error('Expected an array but got:', responseData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedMenu = menu.slice(startIndex, startIndex + itemsPerPage);

    const fetchData = async () => {
        setIsLoading(true); // Show loader before fetching data
        try {
            const response = await _get('/today');
            const responseData = response.data.data || []; // Ensure responseData is an array
            setData(responseData);
            // console.log(responseData);
        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle errors
        } finally {
            setIsLoading(false); // Hide loader after fetching data
        }
    };

    const downloadPdf = async () => {
        setshowDownloadModel(false);
        try {
            const date = new Date().toLocaleDateString('en-GB').split('/').join('-'); // Format date as dd-MM-yyyy
            const response = await _post('/mealpdf', {
                date: date,
                foodType: foodtype,
            });
            console.log('response', response);

            if (response.data.status === 'success') {
                const pdfBlob = await fetch(response.data.data.link).then(res => res.blob());
                const blobUrl = URL.createObjectURL(pdfBlob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', `meal.pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Optionally, revoke the blob URL after use to free up memory
                URL.revokeObjectURL(blobUrl);
            } else {
                console.error('Failed to retrieve PDF URL:', response.data.msg);
            }
        } catch (error) {
            console.error('Error downloading the PDF:', error);
        }
    };

    const downloadTooltip = (
        <Tooltip id="download-tooltip">
            Download for the Daily Menu Counts
        </Tooltip>
    );

    const renderMealRow = (mealType, mealDetails, actual, showOtherMeals) => {
        if (!mealDetails) {
            return (
                <tr key={mealType}>
                    <td>{mealType}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{actual}</td>
                </tr>
            );
        }

        return (
            <tr key={mealType}>
                <td>{mealType}</td>
                <td>{actual}</td>
                <td>{mealDetails.addCount}</td>
                <td>{mealDetails.cancelled}</td>
                <td>{mealDetails.totalFoodCount}</td>
            </tr>
        );
    };

    const isMealDataEmpty = (foodDetails) => {
        return (
            !foodDetails.breakfast &&
            !foodDetails.lunch &&
            !foodDetails.dinner
        );
    };

    const fetchFood = async () => {
        try {
            const response = await _get('/food');
            const responseData = response.data.data; // Ensure responseData is an array
            setFood(responseData);
            // console.log(responseData);
        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle errors
        }
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => prevPage - 1);
    };

    const filteredFood = filter === 'all' ? food : food.filter(foodItem => foodItem.foodType.includes(filter));
    const totalPages = Math.ceil(filteredFood.length / itemsPerPageF);
    const startIndexA = (currentPage - 1) * itemsPerPageF;
    const currentPageItems = filteredFood.slice(startIndexA, startIndexA + itemsPerPageF);
    const hasMoreItems = startIndexA + itemsPerPageF < filteredFood.length;
    useEffect(() => {
        if (typeof gsap !== typeof undefined) {
            if (typeof ScrollTrigger !== typeof undefined) {
                gsap.registerPlugin(ScrollTrigger);
            }

            const gsapAnimate = {
                onStart: (elem) => {
                    const option = {
                        opacity: 0,
                        scale: 1,
                        position: {
                            x: 0,
                            y: 0,
                        },
                        ease: "",
                        duration: 1,
                        delay: .4,
                        rotate: 0
                    }

                    option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0)

                    option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0)

                    option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0)

                    option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1)

                    option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0)

                    option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, .4)

                    option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5)

                    option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '')

                    const setOption = { opacity: option.opacity, scale: option.scale, x: option.position.x, y: option.position.y, ease: option.ease, rotate: option.rotate, duration: option.duration, delay: option.delay }

                    if (typeof ScrollTrigger !== typeof undefined) {
                        if (elem.dataset.iqTrigger === 'scroll') {
                            const scrub = elem.dataset.iqScrollScrub === 'true' ? true : false

                            setOption.scrollTrigger = {
                                trigger: elem,
                                start: () => "top 95%",
                                scrub: scrub,
                                markers: false
                            }
                        }
                    }

                    gsap.from(elem, setOption)

                },

                validValue: (attr, defaultVal) => {
                    if (attr !== undefined && attr !== null) {
                        return attr
                    }
                    return defaultVal
                }
            }


            const gsapElem = document.querySelectorAll('[data-iq-gsap="onStart"]')

            Array.from(gsapElem, (elem) => {
                gsapAnimate.onStart(elem)
                return null
            })

            if (document.querySelectorAll('auth-login').length) {
                gsap.from('.first-img', { opacity: 0, scale: .8, rotate: -45, ease: "power1.out", duration: 1, delay: .4 })
                gsap.from('.second-img', { opacity: 0, scale: .8, rotate: -45, ease: "power1.out", duration: 1, delay: .6 })
                gsap.from('.third-img', { opacity: 0, scale: .8, rotate: -45, ease: "power1.out", duration: 1, delay: .8 })
                gsap.from('.fourth-img', { opacity: 0, scale: .8, rotate: -45, ease: "power1.out", duration: 1, delay: 1.2 })
                gsap.from('.fifth-img', { opacity: 0, scale: .8, rotate: -45, ease: "power1.out", duration: 1, delay: 1.4 })
                gsap.from('.six-img', { opacity: 0, scale: .8, rotate: -45, ease: "power1.out", duration: 1, delay: 1.6 })
                gsap.from('.seventh-img', { opacity: 0, scale: .8, rotate: -45, ease: "power1.out", duration: 1, delay: 1.6, })
                gsap.from('.eight-img', { opacity: 0, scale: .8, rotate: -45, ease: "power1.out", duration: 1, delay: 1.8, })
            }
        }


    }, []);
    const chart1 = {
        options: {
            chart: {
                id: "admin-chart-1",
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 800,
                    animateGradually: {
                        enabled: false,
                        delay: 150
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350
                    }
                },
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false
                }
            },
            tooltip: {
                enabled: true,
            },
            stroke: {
                width: [0, 2]
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [1],
                offsetX: 3.0,
                offsetY: -1.6,
                style: {
                    fontSize: '1px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'bold',
                },
                background: {
                    enabled: true,
                    foreColor: '#fff',
                    color: '#fff',
                    padding: 10,
                    borderRadius: 10,
                    borderWidth: 0,
                    borderColor: '#fff',
                    opacity: 1,
                }
            },
            colors: ["#EA6A12", "#EA6A12"],
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '16%',
                    endingShape: 'rounded',
                    borderRadius: 5,
                },
            },
            legend: {
                show: false,
                offsetY: -25,
                offsetX: -5
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                labels: {
                    minHeight: 20,
                    maxHeight: 20,
                }
            },
            yaxis: {
                labels: {
                    minWidth: 20,
                    maxWidth: 20,
                }
            },
        },
        series: [{
            type: 'column',
            data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
        }, {
            type: 'line',
            curve: 'smooth',
            data: [33, 42, 60, 120, 140, 170, 180, 140, 130, 110, 70, 80]
        }]
    }

    const currentDate = new Date().toISOString().split('T')[0];
    let downloadCurrent;
    if (currentDate) {
        const [day, month, year] = currentDate.split('-');
        downloadCurrent = `${year}/${month}/${day}`;
    }
    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (

                <div className='container'>
                    <section id="auth-login">
                        <Row>
                            <Col lg="6" xl='6'>
                                <Widgets />
                            </Col>
                            <Col lg='6' xl='6'>
                                <div className="card overflow-hidden" data-aos="fade-up" data-aos-delay="600" data-iq-gsap="onStart"
                                    data-iq-opacity="0"
                                    data-iq-position-y="-40"
                                    data-iq-duration=".6"
                                    data-iq-delay="1"
                                    data-iq-trigger="scroll"
                                    data-iq-ease="none">
                                    <div className="card-header border-0 pb-0">
                                        <div className="header-title">
                                            <h4 className="card-title text-center daily-tilte">Daily Menu</h4>
                                        </div>
                                    </div>
                                    <Card.Body className="py-0">
                                        <div className="table-responsive">
                                            {data.length === 0 ? (
                                                <p className="text-center no-data pt-5">No data found</p>
                                            ) : (
                                                Array.isArray(data) && data.map((dayItem, index) => (
                                                    !isMealDataEmpty(dayItem.foodDetails) && (
                                                        <div key={index}>
                                                            <div className='d-flex justify-content-between'>
                                                                <div>
                                                                    <h4 className='mb-0 date-show'>Date: {dayItem.date}&nbsp;&nbsp;&nbsp;</h4>
                                                                </div>
                                                                <div>
                                                                    {dayItem.date === downloadCurrent && (
                                                                        <OverlayTrigger placement="top" overlay={downloadTooltip}>
                                                                            <h4 className='download-btn' style={{cursor:'pointer'}}>
                                                                                <FaDownload onClick={() => setshowDownloadModel(true)} />
                                                                            </h4>
                                                                        </OverlayTrigger>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <table id="basic-table" className="table mb-0 iq-table user-list-table table-center" role="grid">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Food</th>
                                                                        <th>Actual</th>
                                                                        <th>Extra</th>
                                                                        <th>Cancel</th>
                                                                        <th>Total</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {renderMealRow('Breakfast', dayItem.foodDetails.breakfast, dayItem.actual)}
                                                                    {dayItem.date === downloadCurrent && (
                                                                        <>
                                                                            {renderMealRow('Lunch', dayItem.foodDetails.lunch, dayItem.actual)}
                                                                            {renderMealRow('Dinner', dayItem.foodDetails.dinner, dayItem.actual)}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )
                                                ))
                                            )}
                                        </div>
                                    </Card.Body>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="7" xl='8'>

                                <div className="card overflow-hidden" data-aos="fade-up" data-aos-delay="600" data-iq-gsap="onStart"
                                    data-iq-opacity="0"
                                    data-iq-position-y="-40"
                                    data-iq-duration=".6"
                                    data-iq-delay="1"
                                    data-iq-trigger="scroll"
                                    data-iq-ease="none">

                                    <Card.Header className="d-flex justify-content-between align-items-center">
                                        <h4 className="card-title list-main daily-tilte">Day Menu List</h4>
                                    </Card.Header>
                                    <Card.Body>
                                        {menu.length === 0 ? (
                                            <p className="text-center no-date">No data found</p>
                                        ) : (paginatedMenu.map((item) => (
                                            <div key={item.dayOrder}>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className='text-left'>
                                                        <h5>{`Day ${item.dayOrder}`}</h5>
                                                    </div>
                                                </div>
                                                <h6 className='pt-3'>Breakfast:</h6>
                                                <p>{item.foodDetails.breakfast.items}</p>
                                                <h6>Lunch:</h6>
                                                <p>{item.foodDetails.lunch.items}</p>
                                                <h6>Dinner:</h6>
                                                <p>{item.foodDetails.dinner.items}</p>
                                                <hr />
                                            </div>
                                        ))
                                        )}
                                    </Card.Body>
                                    <div className="text-center mb-5">
                                        <a href='/special-pages/menulist'>
                                            <Button className='text-center'>
                                                See All Menu
                                            </Button>
                                        </a>
                                    </div>

                                </div>
                                <div className="card"
                                    data-iq-gsap="onStart"
                                    data-iq-opacity="0"
                                    data-iq-position-y="-40"
                                    data-iq-duration=".6"
                                    data-iq-delay=".4"
                                    data-iq-trigger="scroll"
                                    data-iq-ease="none"
                                >
                                    <div className="card-header">
                                        <h4 className="card-title daily-tilte">Sales Figures</h4>
                                        <small>2023-2024</small>
                                    </div>
                                    <div className="card-body"
                                        data-iq-gsap="onStart"
                                        data-iq-opacity="0"
                                        data-iq-position-y="-40"
                                        data-iq-duration=".6"
                                        data-iq-delay=".6"
                                        data-iq-trigger="scroll"
                                        data-iq-ease="none">
                                        <Chart id="admin-chart-1" options={chart1.options} series={chart1.series} type="line" height="300" />
                                    </div>
                                </div>
                            </Col>
                            <Col lg="5" xl="4">
                                <div className="card" data-iq-gsap="onStart"
                                    data-iq-opacity="0"
                                    data-iq-position-y="-40"
                                    data-iq-duration=".6"
                                    data-iq-delay="1"
                                    data-iq-trigger="scroll"
                                    data-iq-ease="none">
                                    <div className="card-header">
                                        <h4 className="card-title daily-tilte">Food List</h4>
                                    </div>
                                    <Card.Body className="py-0">
                                        <ul className="list-inline chat-list-main1">
                                            {food.length === 0 ? (
                                                <p className="text-center no-date pt-5">No data found</p>
                                            ) : (Array.isArray(currentPageItems) && currentPageItems.map((foodItem, index) => (
                                                <li className="py-5 border-bottom border-soft-primary" key={index}>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <img src={menu10} className="img-fluid avatar avatar-40" alt="profile-img" />
                                                            <div className="d-flex justify-content-between ms-3">
                                                                <div>
                                                                    <h6 className="mb-1 fw-bolder heading-title">{foodItem.items}</h6>
                                                                    <small className="mb-0 foodtype">{foodItem.foodType.join(', ')}</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )))}
                                        </ul>
                                        <div className="text-center mb-3">
                                            <a href='/special-pages/menu'>
                                                <Button className='text-center'>
                                                    See All Food
                                                </Button>
                                            </a>
                                        </div>


                                    </Card.Body>
                                </div>
                            </Col>
                        </Row>

                        <Modal show={showDownloadModel} onHide={() => setshowDownloadModel(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Download Today List</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Card>
                                    <Card.Body>
                                        <Form onSubmit={(e) => { e.preventDefault(); downloadPdf(); }}>
                                            <Form.Group>
                                                <Form.Label>Choose Your Type</Form.Label>
                                                <Form.Select size="sm" onChange={(e) => setFoodType(e.target.value)}>
                                                    <option value="">Select Food Type</option>
                                                    <option value="breakfast">Breakfast</option>
                                                    <option value="lunch">Lunch</option>
                                                    <option value="dinner">Dinner</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <Button
                                                type="submit"
                                                className="btn btn-primary rounded-pill mt-3"
                                            >
                                                Download
                                            </Button>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Modal.Body>
                        </Modal>
                    </section>
                </div>
            )}
        </>
    );
}

export default Admin;
