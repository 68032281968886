import React, { useState, useEffect } from "react";
import { Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom";
import Card from "../../../components/card";
import { _get } from "../../../apiClient";
import Avatar from "./avatar/avatar";
import Loader from "../Loader";

const DeliveryAssign = () => {
    const [deliveryList, setDeliveryList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const history = useHistory();
    const token = localStorage.getItem("token");

    useEffect(() => {
        if (token) {
            fetchDelivery();
        }
    }, [token]);

    const editTooltip = (
        <Tooltip id="edit-tooltip">
            Edit
        </Tooltip>
    );
    const viewTooltip = (
        <Tooltip id="view-tooltip">
            View
        </Tooltip>
    );

    const fetchDelivery = async () => {
        setIsLoading(true);
        try {
            const response = await _get("delivery");
            if (response.data.status === "success") {
                const deliveryData = response.data.data.filter(
                    delivery => delivery.assignedUsersCount !== 0
                );
                setDeliveryList(deliveryData);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleEdit = (delivery, mode = "edit") => {
      history.push({
        pathname: "/app/add-delivery",
        state: { delivery, mode },
      });
    };

    const handleView = (delivery,mode ='view') =>{
        history.push({
            pathname:"/delivery-details",
            state:{delivery,mode}
        })
    }
   

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="container">
                    <Row>
                        <Col sm="12">
                            <Card>
                                <Card.Header className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                                    <div className="mb-3 mb-sm-0">
                                        <Card.Header.Title>
                                            <h4 className="card-title mb-0 daily-tilte">Delivery Boy List</h4>
                                        </Card.Header.Title>
                                    </div>
                                </Card.Header>
                                <Card.Body className="px-0">
                                    <div className="table-responsive">
                                        <table
                                            id="user-list-table"
                                            className="table"
                                            role="grid"
                                            data-toggle="data-table"
                                        >
                                            <thead>
                                                <tr className="ligth text-center">
                                                    <th>Profile</th>
                                                    <th>Name</th>
                                                    <th>Contact</th>
                                                    <th>Assign Delivery</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {deliveryList.length === 0 ? (
                                                    <tr>
                                                        <td colSpan="5" className="text-center no-date pt-5">
                                                            No data found
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    deliveryList.map((delivery, index) => (
                                                        <tr key={index} className="text-center">
                                                            <td style={{width:"5%",marginLeft:"auto",marginRight:'auto'}}>
                                                                <Avatar
                                                                    firstName={delivery.fname}
                                                                    lastName={delivery.lname}
                                                                />
                                                            </td>
                                                            <td>{delivery.fname + " " + delivery.lname}</td>
                                                            <td>{delivery.phone}</td>
                                                            <td>{delivery.assignedUsersCount}</td>
                                                            <td>
                                                                <div className="flex align-items-center list-user-action">
                                                                <OverlayTrigger placement="top" overlay={viewTooltip}>
                                                                    <Button className="btn btn-sm btn-icon btn-success m-1" data-toggle="tooltip" data-placement="top" onClick={() => handleView(delivery, "view")} >
                                                                        <span className="btn-inner">
                                                                            <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </Button>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={editTooltip}>
                                                                    <Button
                                                                        className="btn btn-sm btn-icon btn-warning m-1"
                                                                        data-toggle="tooltip"
                                                                        data-placement="top"
                                                                        onClick={() => handleEdit(delivery, "edit")}
                                                                    >
                                                                        <span className="btn-inner">
                                                                            <svg
                                                                                width="20"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                ></path>
                                                                                <path
                                                                                    fillRule="evenodd"
                                                                                    clipRule="evenodd"
                                                                                    d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.544L8.10212 16.912L8.82812 10.921Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                ></path>
                                                                                <path
                                                                                    d="M15.1655 4.60254L19.7315 9.16854"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                ></path>
                                                                            </svg>
                                                                        </span>
                                                                    </Button>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default DeliveryAssign;
