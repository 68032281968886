import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Modal, InputGroup } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useLocation, useHistory } from "react-router-dom";
import Card from "../../../components/card";
import { _get, _post, _put } from "../../../apiClient";

const UserAdd = () => {
  const location = useLocation();
  const { user, mode } = location.state || {};
  const isEditMode = mode === "edit";
  const isReadMode = mode === "read";
  const [pincode, setPincode] = useState("");
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [messageClass, setMessageClass] = useState("");
  const [message, setMessage] = useState("");
  const [tagMessage, setTagMessage] = useState("");
  const [modelMessage, setModelMessage] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phone, setPhone] = useState("");
  const [hno, setHno] = useState("");
  const [areaname, setAreaname] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [landmark, setLandmark] = useState("");
  const [password, setPassword] = useState("");
  const [repass, setRepass] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const history = useHistory();

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/; // Simple validation for a 10-digit phone number
    return phoneRegex.test(phone);
  };

  const handleVerifyPincode = async () => {
    try {
      const response = await _get(`/checkpin/${pincode}`);
      if (response.data.status === "success") {
        setMessage(response.data.msg);
        setMessageClass("text-success");
        return;
      }
      setMessage(response.data.msg);
      setMessageClass("text-danger");
    } catch (error) {
      console.error("Error verifying pincode:", error);
      setMessage("Error verifying pincode");
    }
  };

  const handlePincodeChange = (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, "");
    setPincode(numericValue);
    setShowVerifyButton(numericValue !== "");
    setMessage("");
  };

  useEffect(() => {
    if ((isEditMode || isReadMode) && user) {
      setFname(user.fname || "");
      setLname(user.lname || "");
      setPhone(user.phone || "");
      if (user.address.length > 0) {
        setHno(user.address[0].doorNo || "");
        setPincode(user.address[0].pincode || "");
        setAreaname(user.address[0].street || "");
        setCity(user.address[0].city || "");
        setState(user.address[0].state || "");
        setCountry(user.address[0].country || "");
        setLandmark(user.address[0].landMark || "");
      }
    }
  }, [isEditMode, isReadMode, user]);

  const addAddress = async (e) => {
    e.preventDefault();
    setValidationMessage("");
    if (isEditMode) {
      if (
        !phone ||
        !pincode ||
        !hno ||
        !areaname ||
        !city ||
        !state ||
        !country
      ) {
        setValidationMessage("All fields are required");
        return;
      }
      if (!validatePhone(phone)) {
        setValidationMessage("Please enter a valid 10-digit phone number");
        return;
      }
    } else {
      if (
        !phone ||
        !pincode ||
        !hno ||
        !areaname ||
        !city ||
        !state ||
        !country ||
        !password ||
        !repass
      ) {
        setValidationMessage("All fields are required");
        return;
      }
      if (!validatePhone(phone)) {
        setValidationMessage("Please enter a valid 10-digit phone number");
        return;
      }
    }

    try {
      const newData = {
        doorNo: hno,
        landMark: landmark,
        street: areaname,
        city,
        state,
        pincode,
        country,
        userId: isEditMode ? user.userId : "",
      };
      if (!isReadMode && password !== repass) {
        setValidationMessage("Passwords do not match");
        return;
      }
      const adduserData = {
        fname,
        lname,
        phone,
        password,
        doorNo: hno,
        landMark: landmark,
        street: areaname,
        city,
        state,
        pincode,
        country,
        primary: true,
      };
      console.log('*****&&&&&', adduserData)
      if (isEditMode) {
        const primaryAddress = user.address.find((addr) => addr.primary === true);
        const addressId = primaryAddress ? primaryAddress.addressId : null;
        const endpoint = `address?addressId=${addressId}`;
        const response = await _post(endpoint, newData);
        console.log('response', response)
        setShowModal(true);
        setTagMessage(response.data.status);
        setModelMessage(response.data.msg);
      }
      else {
        const endpoint = "signup";
        const response = await _post(endpoint, adduserData)
        console.log('response', response)
        setShowModal(true);
        setTagMessage(response.data.status);
        setModelMessage(response.data.msg);
        // console.log("Address added successfully:", response.data.msg);
      }
    } catch (error) {
      console.error("Error adding address:", error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    if (tagMessage.toLowerCase() === "success") {
      history.push("/app/user-list");
    }
  };


  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handlePhoneInput = (e) => {
    const value = e.target.value;
    const regex = /^[0-9\b]+$/; // Allow only numbers and backspace
    if (value === "" || regex.test(value)) {
      setPhone(value);
    }
  };

  return (
    <div className="container">
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">
                  {isReadMode
                    ? "Read User"
                    : isEditMode
                      ? "Edit User"
                      : "Add New User"}
                </h4>
              </div>
              {isReadMode && (
                <Button
                  className="col-md-2"
                  onClick={() =>
                    history.push({
                      pathname: location.pathname,
                      state: { user, mode: "edit" },
                    })
                  }
                >
                  Edit
                </Button>
              )}
            </Card.Header>
            <Card.Body>
              <Col lg="12" className="d-flex justify-content-center">
                {validationMessage && (
                  <p className="text-danger">{validationMessage}</p>
                )}
              </Col>
              <div className="new-user-info">
                <form onSubmit={addAddress}>
                  <div className="row">
                    <Form.Group className="col-md-6 mb-2">
                      <Form.Label htmlFor="fname">First Name:</Form.Label>
                      <Form.Control
                        type="text"
                        id="fname"
                        placeholder="First Name"
                        value={fname}
                        onChange={(e) => setFname(e.target.value)}
                        readOnly={isReadMode || isEditMode}
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6 mb-2">
                      <Form.Label htmlFor="lname">Last Name:</Form.Label>
                      <Form.Control
                        type="text"
                        id="lname"
                        placeholder="Last Name"
                        value={lname}
                        onChange={(e) => setLname(e.target.value)}
                        readOnly={isReadMode || isEditMode}
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6 mb-2">
                      <Form.Label htmlFor="mobno">Mobile Number:</Form.Label>
                      <Form.Control
                        type="text"
                        id="mobno"
                        placeholder="Mobile Number"
                        value={phone}
                        maxLength={10}
                        onChange={handlePhoneInput}
                        readOnly={isReadMode || isEditMode}
                      />
                    </Form.Group>
                    <Form.Group
                      className="col-md-6 mb-2"
                    >
                      <Form.Label htmlFor="pno">Pincode:</Form.Label>
                      <Form.Control
                        type="text"
                        id="pno"
                        placeholder="Pincode"
                        maxLength={6}
                        value={pincode}
                        onChange={handlePincodeChange}
                        readOnly={isReadMode}
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6 mb-2">
                      <Form.Label htmlFor="hnumber">House No:</Form.Label>
                      <Form.Control
                        type="text"
                        id="hnumber"
                        placeholder="House No., Building Name"
                        value={hno}
                        onChange={(e) => setHno(e.target.value)}
                        readOnly={isReadMode}
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6 mb-2">
                      <Form.Label htmlFor="areaname">Area Name:</Form.Label>
                      <Form.Control
                        type="text"
                        id="areaname"
                        placeholder="Road name, Area, Colony"
                        value={areaname}
                        onChange={(e) => setAreaname(e.target.value)}
                        readOnly={isReadMode}
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6 mb-2">
                      <Form.Label htmlFor="city">City:</Form.Label>
                      <Form.Control
                        type="text"
                        id="city"
                        placeholder="City Name"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        readOnly={isReadMode}
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6 mb-2">
                      <Form.Label htmlFor="state">State:</Form.Label>
                      <Form.Control
                        type="text"
                        id="state"
                        placeholder="State name"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        readOnly={isReadMode}
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6 mb-2">
                      <Form.Label htmlFor="country">Country:</Form.Label>
                      <Form.Control
                        type="text"
                        id="country"
                        placeholder="Country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        readOnly={isReadMode}
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6 mb-2">
                      <Form.Label htmlFor="landmark">Landmark:</Form.Label>
                      <span>(optional)</span>
                      <Form.Control
                        type="text"
                        id="landmark"
                        placeholder="Landmark"
                        value={landmark}
                        onChange={(e) => setLandmark(e.target.value)}
                        readOnly={isReadMode}
                      />
                    </Form.Group>
                  </div>
                  {!isEditMode && !isReadMode && (
                    <>
                      <hr />
                      <h5 className="mb-3">Security</h5>
                      <div className="row">
                        <Form.Group className="col-md-6 ">
                          <Form.Label htmlFor="password">Password:</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type={passwordVisible ? "text" : "password"}
                              className="form-control-sm"
                              id="password"
                              aria-describedby="password"
                              placeholder="Enter a Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <InputGroup.Text
                              onClick={togglePasswordVisibility}
                              style={{ cursor: "pointer" }}
                            >
                              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group className="col-md-6">
                          <Form.Label htmlFor="rpass">
                            Repeat Password:
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type={passwordVisible ? "text" : "password"}
                              className="form-control-sm"
                              id="confirm-password"
                              placeholder="Enter a Confirm Password"
                              value={repass}
                              onChange={(e) => setRepass(e.target.value)}
                            />
                            <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </div>
                      {validationMessage && (
                        <p className="text-danger">{validationMessage}</p>
                      )}
                    </>
                  )}
                  {!isReadMode && (
                    <Button type="submit" variant="primary" className="m-3">
                      {isEditMode ? "Edit User" : "Add New User"}
                    </Button>
                  )}
                  <Button
                    type="button"
                    variant="secondary"
                    className="m-3"
                    onClick={() => history.push("/app/user-list")}
                  >
                    {isEditMode ? "Cancel" : "Close"}
                  </Button>
                </form>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{tagMessage}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className={messageClass}>{modelMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeModal}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserAdd;
