import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Table, Modal } from 'react-bootstrap';
import Card from '../../../components/card'; // Assuming this is your custom Card component
import { _get, _post, _delete } from '../../../apiClient';

const PincodeList = () => {
    
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
   
    const [responseModalShow, setResponseModalShow] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const token = localStorage.getItem("token");

    useEffect(() => {
        if (token) {
          
        }
    }, [token]);

    
    const handleResponseModalClose = () => {
        setResponseModalShow(false);
        setResponseMessage('');
    };

    return (
        <>
            <Row>
                <Col lg='12' md='12'>
                    <Card>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <h4 className="card-title list-main">Subscription Request List</h4>
                        </Card.Header>
                        <Card.Body>
                            <div className="table-responsive">
                                <table
                                    id="user-list-table"
                                    className="table "
                                    role="grid"
                                    data-toggle="data-table"
                                >
                                    <thead>
                                        <tr className="ligth text-center">
                                            <th className="text-center">Name</th>
                                            <th className="text-center">Phone</th>
                                            <th className="text-center">Plan</th>
                                            <th className="text-center">Start Date</th>
                                            <th className="text-center">End Date</th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       
                                                <tr >
                                                    <td className="text-center">wd</td>
                                                    <td className="text-center">d</td>
                                                    <td className="text-center">dw</td>
                                                    <td className="text-center">d</td>
                                                    <td className="text-center">dw</td>
                                                    <td className="d-flex justify-content-center">
                                                        <Button
                                                            className="btn btn-sm btn-icon btn-success m-1"
                                                            title="Accept"
                                                          
                                                        >
                                                            <span className="btn-inner">
                                                                <svg
                                                                    width="20"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    stroke="currentColor"
                                                                >
                                                                    <path
                                                                        d="M5 13l4 4L19 7"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </Button>
                                                        <Button
                                                            className="btn btn-sm btn-icon btn-danger m-1"
                                                            title="Reject"
                                                            
                                                        >
                                                            <span className="btn-inner">
                                                                <svg
                                                                    width="20"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    stroke="currentColor"
                                                                >
                                                                    <path
                                                                        d="M6 18L18 6M6 6l12 12"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            
                                          
                                    
                                    </tbody>
                                </table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Create Pincode Model */}
           

            

            {/* Accept Confirmation Modal */}
            <Modal show={showAcceptModal} onHide={() => setShowAcceptModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Accept</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to accept this pincode request?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAcceptModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="success" >
                        Accept
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Reject Confirmation Modal */}
            <Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Reject</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to reject this pincode request?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowRejectModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" >
                        Reject
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Response Message Modal */}
            <Modal show={responseModalShow} onHide={handleResponseModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Response</Modal.Title>
                </Modal.Header>
                <Modal.Body>{responseMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleResponseModalClose}>
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PincodeList;
