import React, {  useState } from "react";
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { _post } from "../../../apiClient";
import Card from "../../../components/card";

// gsap
// import { gsap } from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";

// img
import auth01 from "../../../assets/images/auth/01.png";
import auth02 from "../../../assets/images/auth/02.png";
import auth03 from "../../../assets/images/auth/03.png";
import auth04 from "../../../assets/images/auth/04.png";
import auth05 from "../../../assets/images/auth/05.png";
import auth06 from "../../../assets/images/auth/06.png";
import logo from "../../../assets/images/logo.svg";

const Recoverpw = () => {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/; // Simple validation for a 10-digit phone number
    return phoneRegex.test(phone);
  };

  // useEffect(() => {
  //    if(typeof gsap !== typeof undefined) {
  //        if (typeof ScrollTrigger !== typeof undefined) {
  //          gsap.registerPlugin(ScrollTrigger);
  //        }

  //        const gsapAnimate = {
  //          onStart : (elem) => {
  //            const option = {
  //              opacity: 0,
  //              scale: 1,
  //              position: {
  //                x: 0,
  //                y:0,
  //              },
  //              ease: "",
  //              duration: 1,
  //              delay: .4,
  //              rotate: 0
  //            }

  //            option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0)

  //            option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0)

  //            option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0)

  //            option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1)

  //            option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0)

  //            option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, .4)

  //            option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5)

  //            option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '')

  //            const setOption = {opacity: option.opacity, scale: option.scale, x: option.position.x, y: option.position.y, ease: option.ease, rotate: option.rotate, duration: option.duration, delay: option.delay}

  //            if (typeof ScrollTrigger !== typeof undefined) {
  //              if (elem.dataset.iqTrigger === 'scroll') {
  //                const scrub = elem.dataset.iqScrollScrub === 'true' ? true : false

  //                setOption.scrollTrigger = {
  //                  trigger: elem,
  //                  start: () => "top 95%",
  //                  // end: () => "bottom 10%",
  //                  scrub: scrub,
  //                  markers: false
  //                }
  //              }
  //            }

  //            gsap.from(elem, setOption)

  //          },

  //          validValue: (attr, defaultVal) => {
  //            if (attr !== undefined && attr !== null) {
  //              return attr
  //            }
  //            return defaultVal
  //          }
  //        }

  //        const gsapElem = document.querySelectorAll('[data-iq-gsap="onStart"]')

  //        Array.from(gsapElem, (elem) => {
  //          gsapAnimate.onStart(elem)
  //          return null
  //        })

  //        // Home Page 1 banner

  //        if(document.querySelectorAll('#auth-login').length) {
  //          gsap.from('.first-img', {opacity: 0, scale: .8, rotate: -45, ease: "power1.out", duration: 1, delay:.4})
  //          gsap.from('.second-img', {opacity: 0, scale: .8,rotate: -45, ease: "power1.out", duration: 1,delay:.6})
  //          gsap.from('.third-img', {opacity: 0, scale: .8,rotate: -45, ease: "power1.out", duration: 1,delay:.8})
  //          gsap.from('.fourth-img', {opacity: 0, scale: .8,rotate: -45, ease: "power1.out", duration: 1,delay:1.2})
  //          gsap.from('.fifth-img', {opacity: 0, scale: .8,rotate: -45, ease: "power1.out", duration: 1,delay:1.4})
  //          gsap.from('.six-img', {opacity: 0, scale: .8,rotate: -45, ease: "power1.out", duration: 1,delay:1.6})
  //          gsap.from('.seventh-img', {opacity: 0, scale: .8,rotate: -45, ease: "power1.out", duration: 1,delay:1.6,})
  //          gsap.from('.eight-img', { opacity: 0, scale: .8,rotate: -45, ease: "power1.out", duration: 1, delay:1.8,})
  //        }
  //    }
  //  },[]);

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    e.preventDefault();
    setValidationMessage("");

    if (!phone || !password || !confirmPassword) {
      setValidationMessage("All fields are required");
      return;
    }

    if (!validatePhone(phone)) {
      setValidationMessage("Please enter a valid 10-digit phone number");
      return;
    }

    if (password !== confirmPassword) {
      setValidationMessage("Passwords do not match");
      return;
    }

    try {
      const newData = { phone, password };
      const response = await _post("forgotpassword", newData);
      if (response.data.status === "success") {
        history.push("/auth/sign-in");
      } else {
        console.error("Password Change failed:", response.data.msg);
      }
    } catch (error) {
      console.error("Error during sign in:", error);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handlePhoneInput = (e) => {
   const value = e.target.value;
   const regex = /^[0-9\b]+$/; // Allow only numbers and backspace
   if (value === '' || regex.test(value)) {
     setPhone(value);
   }
 };
  let history = useHistory();

  return (
    <>
      <section className="container-fluid bg-circle" id="auth-login">
        <Row className="align-items-center">
          <Col md="12" lg="7" xl="4">
            <Row className="justify-content-center">
              <Col md="10">
                <div className="d-flex justify-content-center mb-0">
                  <Card.Body className="text-center">
                    <Link>
                      <img
                        src={logo}
                        className="img-fluid logo-img mb-4"
                        alt="img3"
                      />
                    </Link>
                    <h2 className="mb-2 text-center">Reset Password</h2>
                    {/* <p className=" text-center">Enter your email address and we'll send you an email with instructions to reset your password.</p> */}
                    <Form onSubmit={handleForgotPassword}>
                      <Row className="text-start">
                        <Col lg="12">
                          <Form.Group className="form-group floating-label">
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control
                              type="phone"
                              className="form-control-sm"
                              id="phone"
                              aria-describedby="phone"
                              placeholder="Enter a Mobile Number "
                              value={phone}
                              onChange={handlePhoneInput}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="text-start">
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="password">Password</Form.Label>
                            <InputGroup>
                              <Form.Control
                                type={passwordVisible ? "text" : "password"}
                                className="form-control-sm"
                                id="password"
                                aria-describedby="password"
                                placeholder="Enter a password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <InputGroup.Text
                                onClick={togglePasswordVisibility}
                                style={{ cursor: "pointer" }}
                              >
                                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                              </InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="text-start">
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="confirm-password">
                              Confirm Password
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                type={passwordVisible ? "text" : "password"}
                                className="form-control-sm"
                                id="confirm-password"
                                placeholder="Enter a Confirm Password"
                                value={confirmPassword}
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                              <InputGroup.Text
                                onClick={togglePasswordVisibility}
                                style={{ cursor: "pointer" }}
                              >
                                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                              </InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Col lg="12" className="d-flex justify-content-center">
                        {validationMessage && (
                          <p className="text-danger">{validationMessage}</p>
                        )}
                      </Col>
                      <Button type="submit" variant="primary">
                        Reset
                      </Button>
                      <p className="mt-3 text-center">
                        Already have Remember my Password{" "}
                        <Link to="/auth/sign-in" className="text-underline">
                          Sign In
                        </Link>
                      </p>
                    </Form>
                  </Card.Body>
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            md="12"
            lg="5"
            xl="8"
            className="d-lg-block d-none vh-100 overflow-hidden"
          >
            <div>
              <img
                src={auth01}
                className="hover-img rounded-circle first-img"
                alt="images"
              />
              <img
                src={auth02}
                className="hover-img rounded-circle second-img"
                alt="images"
              />
              <img
                src={auth03}
                className="hover-img rounded-circle third-img"
                alt="images"
              />
              <img
                src={auth04}
                className="hover-img rounded-circle fourth-img"
                alt="images"
              />
              <img
                src={auth05}
                className="hover-img rounded-circle fifth-img"
                alt="images"
              />
              <img
                src={auth06}
                className="hover-img rounded-circle six-img"
                alt="images"
              />
              <img
                src={auth01}
                className="hover-img rounded-circle seventh-img"
                alt="images"
              />
              <img
                src={auth02}
                className="hover-img rounded-circle eight-img"
                alt="images"
              />
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Recoverpw;
