import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Index from '../views/dashboard/index';
import Admin from '../views/dashboard/admin';
import UserProfile from '../views/dashboard/app/user-profile';
import UserAdd from '../views/dashboard/app/user-add';
import UserList from '../views/dashboard/app/user-list';
import DeliveryAssignAdd from '../views/dashboard/app/add-delivery';
import DeliveryAssign from '../views/dashboard/app/delivery-assign';
import userProfileEdit from '../views/dashboard/app/user-privacy-setting';
import Widgetbasic from '../views/dashboard/widget/widgetbasic';
import Widgetcard from '../views/dashboard/widget/widgetcard';
import Widgetchart from '../views/dashboard/widget/widgetchart';
import Solid from '../views/dashboard/icons/solid';
import Outline from '../views/dashboard/icons/outline';
import DualTone from '../views/dashboard/icons/dual-tone';
import FormElement from '../views/dashboard/from/form-element';
import FormValidation from '../views/dashboard/from/form-validation';
import FormWizard from '../views/dashboard/from/form-wizard';
import BootstrapTable from '../views/dashboard/table/bootstrap-table';
import TableData from '../views/dashboard/table/table-data';
import Food from '../views/dashboard/special-pages/menu';
import MenuList from '../views/dashboard/special-pages/menulist';
import Vector from '../views/dashboard/maps/vector';
import Google from '../views/dashboard/maps/google';
import PrivacyPolicy from '../views/dashboard/extra/privacy-policy';
import TermsofService from '../views/dashboard/extra/terms-of-service';
import Avatars from '../views/dashboard/ui-elements/avatars';
import Colors from '../views/dashboard/ui-elements/colors';
import Cards from '../views/dashboard/ui-elements/cards';
import Carousel from '../views/dashboard/ui-elements/carousel';
import Modals from '../views/dashboard/ui-elements/modal';
import Notifications from '../views/dashboard/ui-elements/notifications';
import Pagination from '../views/dashboard/ui-elements/pagination';
import Tooltips from '../views/dashboard/ui-elements/tooltip';
import PincodeList from '../views/dashboard/pincode/pincode-list';
import SubscriptionList from '../views/subscription/subscription-list';
import MenuList1 from '../views/dashboard/MenuList/menuList';
import FullMenu from '../views/dashboard/MenuList/FullMenu';
import UnsubscriptionList from '../views/subscription/unsubscription-list';
import FeedBackList from '../views/dashboard/FeedBack/feedbackList';
import DeliveryBoyAdd from '../views/dashboard/deliveryBoy/deliveryBoy' ;
import DeliveryAllBoyList from '../views/dashboard/deliveryBoy/deliveryBoyList';
import DeliveryDetails from '../views/dashboard/app/delivery-details';
import Trip from '../views/dashboard/app/trip';
import TripAssign from '../views/dashboard/app/trip-detail';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem('role');
    
    return (
        <Route
            {...rest}
            render={props =>
                token && role === 'Admin' ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    );
};

const PublicRoute = ({ component: Component, ...rest }) => {
    const role = localStorage.getItem('role');
    
    return (
        <Route
            {...rest}
            render={props =>
                role !== 'Admin' ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/admin" />
                )
            }
        />
    );
};

const DefaultRouter = () => {
    return (
        <TransitionGroup>
            <CSSTransition classNames="fadein" timeout={300}>
                <Switch>
                    <PrivateRoute path="/admin" component={Admin} />
                    <PublicRoute path="/app/user-profile" component={UserProfile} />
                    <PrivateRoute path="/app/user-add" component={UserAdd} />
                    <PrivateRoute path="/app/add-delivery" component={DeliveryAssignAdd} />
                    <PrivateRoute path="/app/user-list" component={UserList} />
                    <PrivateRoute path="/app/delivery-assign" component={DeliveryAssign} />
                    <PrivateRoute path="/app/user-privacy-setting" component={userProfileEdit} />
                    <PrivateRoute path="/widget/widgetbasic" component={Widgetbasic} />
                    <PrivateRoute path="/widget/widgetcard" component={Widgetcard} />
                    <PrivateRoute path="/widget/widgetchart" component={Widgetchart} />
                    <PrivateRoute path="/icon/solid" component={Solid} />
                    <PrivateRoute path="/icon/outline" component={Outline} />
                    <PrivateRoute path="/icon/dual-tone" component={DualTone} />
                    <PrivateRoute path="/pincode/pincode-list" component={PincodeList} />
                    <PrivateRoute path="/subcription/subcription-list" component={SubscriptionList}/>
                    <PrivateRoute path="/subcription/unsubcription-list" component={UnsubscriptionList}/>
                    <PrivateRoute path="/form/form-element" component={FormElement} />
                    <PrivateRoute path="/form/form-validation" component={FormValidation} />
                    <PrivateRoute path="/form/form-wizard" component={FormWizard} />
                    <PrivateRoute path="/table/bootstrap-table" component={BootstrapTable} />
                    <PrivateRoute path="/table/table-data" component={TableData} />
                    <PrivateRoute path="/special-pages/menu" component={Food} />
                    <PrivateRoute path="/special-pages/menulist" component={MenuList} />
                    <PrivateRoute path="/map/vector" component={Vector} />
                    <PrivateRoute path="/map/google" component={Google} />
                    <PrivateRoute path="/extra/privacy-policy" component={PrivacyPolicy} />
                    <PrivateRoute path="/extra/terms-of-service" component={TermsofService} />
                    <PrivateRoute path="/avatars" component={Avatars} />
                    <PrivateRoute path="/colors" component={Colors} />
                    <PublicRoute path="/cards" component={Cards} />
                    <PrivateRoute path="/carousel" component={Carousel} />
                    <PrivateRoute path="/modal" component={Modals} />
                    <PrivateRoute path="/notifications" component={Notifications} />
                    <PrivateRoute path="/pagination" component={Pagination} />
                    <PrivateRoute path="/tooltips" component={Tooltips} />
                    <PrivateRoute path="/feedback" component={FeedBackList}/>
                    <PrivateRoute path="/delivery-add" component={DeliveryBoyAdd}/>
                    <PrivateRoute path="/trip" component={Trip}/>
                    <PrivateRoute path="/trip-detail" component={TripAssign}/>
                    <PrivateRoute path="/delivery-details" component={DeliveryDetails} />
                    <PrivateRoute path="/delivery-list" component={DeliveryAllBoyList}/>
                    <PublicRoute path="/menu" component={MenuList1} />
                    <PublicRoute path="/fullmenu" component={FullMenu}/>
                    <PublicRoute exact path="/" component={Index} />
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    );
};

export default DefaultRouter;
