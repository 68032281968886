import { format } from "date-fns";

const dateFormat = "dd-MMM-yyyy";

function formatDate(dateString) {
  if (!dateString) return "--"; 

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return "--";

  return format(date, dateFormat);
}

const DateFormats = ({ date }) => {
  const formattedDate = formatDate(date);
  return (
    <div>{formattedDate}</div>
  );
};

export default DateFormats;
