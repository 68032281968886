import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import DashboardCharts from "./DashboardCharts";
import { _get } from "../../../apiClient";
import Loader from '../Loader';
const Widgets = () => {
  const [metric, setMetric] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Initialize loader state to true
  let history = useHistory();

  useEffect(() => {
    fetchMetric();
  }, []);

  const fetchMetric = async () => {
    setIsLoading(true);

    try {
      const response = await _get('/admin/dashboard');
      const responseData = response.data.data;
      console.log("dashboard_check", responseData);
      if (response.data.status === 'success') {
        const structuredMetrics = [
          {
            label: "Total",
            value: responseData.total.totalUsers.no,
            percentage: responseData.total.totalUsers.percentage,
            color: "#0b985d" // Set appropriate color
          },
          {
            label: "Subscribed users",
            value: responseData.total.subscribedUsers.no,
            percentage: responseData.total.subscribedUsers.percentage,
            color: "#343a40" // Set appropriate color
          },
          {
            label: "Unsubscribed users",
            value: responseData.total.unsubscribedUsers.no,
            percentage: responseData.total.unsubscribedUsers.percentage,
            color: "#ffa600" // Set appropriate color
          },
          {
            label: "Add Food Count",
            value: responseData.total.addcount.no,
            percentage: responseData.total.addcount.percentage,
            color: "#17a2b8" // Set appropriate color
          },
          {
            label: "Cancel Food Count",
            value: responseData.total.foodcancel.no,
            percentage: responseData.total.foodcancel.percentage,
            color: "#ff4c60" // Set appropriate color
          },
          {
            label: "Low FeedBack",
            value: responseData.total.feedback.no,
            percentage: responseData.total.feedback.percentage,
            color: "#fd7e14",
            isFeedback: true 
          }
          // {
          //   label: "Monthly Subscribed Users",
          //   value: responseData.monthly.subscribedUsers.no,
          //   percentage: responseData.monthly.subscribedUsers.percentage,
          //   color: "#6f42c1" // Set appropriate color
          // },
          // {
          //   label: "Monthly Unsubscribed Users",
          //   value: responseData.monthly.unsubscribedUsers.no,
          //   percentage: responseData.monthly.unsubscribedUsers.percentage,
          //   color: "#fd7e14" // Set appropriate color
          // },
          // {
          //   label: "Monthly Add Count",
          //   value: responseData.monthly.addcount.no,
          //   percentage: responseData.monthly.addcount.percentage,
          //   color: "#20c997" // Set appropriate color
          // },
          // {
          //   label: "Monthly Food Cancel",
          //   value: responseData.monthly.foodcancel.no,
          //   percentage: responseData.monthly.foodcancel.percentage,
          //   color: "#dc3545" // Set appropriate color
          // }
        ];
        setMetric(structuredMetrics);
      } else {
        console.error('Expected an array but got:', responseData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }finally {
      setIsLoading(false); // Hide loader after fetching data
  }
  };

  const handleCardClick = (widget) => {
    if (widget.isFeedback) {
      history.push('/feedback'); // Use history.push for navigation
    }
  };

  return (
    <React.Fragment>
      <Col xl={12}>
        <div className="d-flex flex-column h-100">
        {isLoading ? (
            <Loader />
        ) : (
          <Row>
         
            {metric.map((widget, key) => (
              <Col xl={6} md={6} key={key}>
                <Card className="card-animate overflow-hidden"
                 onClick={() => handleCardClick(widget)} 
                 style={{ cursor: widget.isFeedback ? 'pointer' : 'default' }}>
                  <div
                    className="position-absolute start-0"
                    style={{ zIndex: "0" }}
                  >
                    <svg
                      version="1.2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 200 120"
                      width="200"
                      height="140"
                    >
                      <path
                        id="Shape 8"
                        style={{ opacity: ".2", fill: widget.color }}
                        d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                      />
                    </svg>
                  </div>
                  <CardBody style={{ zIndex: "1" }}>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 overflow-hidden">
                        <p className="text-uppercase fw-medium text-muted  mb-3" style={{fontSize:"12px"}}>
                          {widget.label}
                        </p>
                        <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                          <span className="counter-value">
                            {widget.value}
                          </span>
                        </h4>
                      </div>
                      <div className="flex-shrink-0">
                        <DashboardCharts
                          seriesData={widget.percentage}
                          colors={widget.color}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
       
          </Row>
        )}
        </div>
      </Col>
    </React.Fragment>
  );
};

export default Widgets;
