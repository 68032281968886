import React, { useState } from "react";
import { Row, Col, Button, Modal, Form, Card,OverlayTrigger, Tooltip } from "react-bootstrap";
import "../../assets/scss/custom/components/pricing/PricingSection.scss";
import { _post } from "../../apiClient";
import { DatePicker } from "react-rainbow-components";

import priceFrame from '../../assets/images/User_Dashboard/Frame.png'
import App from "../../assets/images/MenuList/app-display.png"

const PricingSection = () => {
    const [showPlanModal, setShowPlanModal] = useState(false);
    const [showPlanResModal, setShowPlanResModal] = useState(false);
    const [planRes, setPlanRes] = useState("");
    const [selectedPlan, setSelectedPlan] = useState("");
    const today = new Date();
    const tomorrow = new Date(today.setDate(today.getDate() + 1));
    const [startDate, setStartDate] = useState(tomorrow);
    const [endDate, setEndDate] = useState(tomorrow);
    const [loading, setLoading] = useState(false);

    const handleOptionChange = (e) => {
        setSelectedPlan(e.target.value);
    };

    const handleSubmit = async () => {
        let payload = {
            startFood: "breakfast",
            endFood: "dinner"
        };

        switch (selectedPlan) {
            case "Monthly":
                payload = {
                    ...payload,
                    planType: "Monthly"
                };
                break;
            case "Daily":
                payload = {
                    ...payload,
                    planType: "Daily"
                };
                break;
            case "Custom":
                payload = {
                    ...payload,
                    planType: "Custom",
                    startDate: startDate.toISOString().split("T")[0],
                    endDate: endDate.toISOString().split("T")[0]
                };
                break;
            default:
                console.error("Invalid plan type selected");
                return;
        }

        console.log("payload:", payload);

        try {
            const response = await _post("subscriberequest", payload);
            setPlanRes(response.data.msg);
            setShowPlanResModal(true);
            setShowPlanModal(false); // Close the plan modal

            if (response.data.status === "success") {
                console.log("Subscription successful:", response.data);
                // Optionally handle success actions here
            } else {
                console.error("Subscription failed:", response.data.msg);
                // Optionally handle failure actions here
            }

            // Reset dates to the next day
            const resetTomorrow = new Date();
            resetTomorrow.setDate(resetTomorrow.getDate() + 1);
            setStartDate(resetTomorrow);
            setEndDate(resetTomorrow);
        } catch (error) {
            console.error("Error:", error);
            setPlanRes("An error occurred while processing your request.");
            setShowPlanModal(false); // Close the plan modal
            setShowPlanResModal(true); // Show the response modal
        }
    };

    const openPlanModal = (planType) => () => {
        setSelectedPlan(planType);
        setShowPlanModal(true);
    };

    const closePlanModal = () => {
        setShowPlanModal(false);
        setSelectedPlan("");
    };

    const closePlanResModal = () => {
        setShowPlanResModal(false);
        setPlanRes("");
    };

    const getDaysInMonthFromToday = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth();
        const lastDayOfMonth = new Date(year, month + 1, 0);
        const daysRemaining = lastDayOfMonth.getDate() - today.getDate();
        return daysRemaining;
    };

    const calculatePrice = () => {
        const daysRemaining = getDaysInMonthFromToday();
        return daysRemaining * 375;
    };
    const viewTooltip = (
        <Tooltip id="view-tooltip">
            Comimg Soon!!
        </Tooltip>
    );

    const monthlyPrice = calculatePrice();

    return (
        <>
            <Row>
                <Col md="12" lg="9" sm="12">
                    <div className="pricing-section">
                        <div className="Price_Frame col-lg-3 col-md-4 col-sm-12">
                            <img src={priceFrame} alt="Pricing Frame"/>
                        </div>
                        <div className="pricing-card col-lg-3 col-md-4 col-sm-12">
                            <h3>Monthly Package</h3>
                            <p className="price">₹{monthlyPrice}</p>
                            <sub>*Free Delivery</sub>
                            <ul>
                                <li>From Tomorrow to End of this month</li>
                            </ul>
                            <button
                                className="subscribe-button"
                                onClick={openPlanModal("Monthly")}
                            >
                                Subscribe Now
                            </button>
                            <p className="terms">*T&C Applies</p>
                        </div>
                        <div className="pricing-card col-lg-3 col-md-4 col-sm-12">
                            <div>
                                <h3>Daily Package</h3>
                                <p className="price">₹375</p>
                                <sub>+ ₹150 Delivery Charges</sub>
                                <ul>
                                    <li>One Day Menu Plan</li>
                                </ul>
                            </div>
                            <div>
                                <button
                                    className="subscribe-button"
                                    onClick={openPlanModal("Daily")}
                                >
                                    Subscribe Now
                                </button>
                                <p className="terms">*T&C Applies</p>
                            </div>
                        </div>
                        <div className="pricing-card col-lg-3 col-md-4 col-sm-12">
                            <h3>Custom Package</h3>
                            <p className="price">₹125</p>
                            <sub>Per meal X No. of days</sub>
                            <ul>
                                <li>Selected Days</li>
                            </ul>
                            <button
                                className="subscribe-button"
                                onClick={openPlanModal("Custom")}
                            >
                                Subscribe Now
                            </button>
                            <p className="terms">*T&C Applies</p>
                        </div>
                    </div>
                    <Modal show={showPlanModal} onHide={closePlanModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Subscription Request</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h6>
                                This request will be sent to admin! After your payment
                                verification, Your plan will be extended
                            </h6>
                            {selectedPlan === "Custom" && (
                                <div className="d-flex m-3">
                                    <Form.Group className="form-group m-1">
                                        <Form.Label htmlFor="startDate">
                                            Start Date
                                        </Form.Label>
                                        <DatePicker
                                            value={startDate}
                                            onChange={setStartDate}
                                            minDate={tomorrow}
                                        />
                                    </Form.Group>
                                    <Form.Group className="form-group m-1">
                                        <Form.Label htmlFor="endDate">
                                            End Date
                                        </Form.Label>
                                        <DatePicker
                                            value={endDate}
                                            onChange={setEndDate}
                                            minDate={tomorrow}
                                        />
                                    </Form.Group>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleSubmit}>
                                Request
                            </Button>
                            <Button variant="secondary" onClick={closePlanModal}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showPlanResModal} onHide={closePlanResModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Message</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{planRes}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closePlanResModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Col>

                <Col lg="3">
                    <div>
                        <Card className="app-pro1" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                            <div className="app-body1">
                                <p className="text-center">
                                    Try out our new mobile app
                                </p>
                                <div className="app-banner1">
                                    <img src={App} alt="Mobile App Banner"/>
                                </div>
                                <div className="btn-div">
                                <OverlayTrigger placement="top" overlay={viewTooltip}>
                                    <a href="/">
                                        <Button className="app-btn1" data-toggle="tooltip" data-placement="top" >Explore</Button>
                                    </a>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default PricingSection;
