import React from 'react';

function getStatus(status) {
  switch (status) {
    case true:
      return { initials: 'Active', backgroundColor: '#329C6B' }; 
    case false:
      return { initials: 'Not Subscribed', backgroundColor: '#EA1212' }; 
    default:
      return { initials: 'Unknown', backgroundColor: '#808080' }; 
  }
}

const Active = ({ status }) => {
  const { initials, backgroundColor } = getStatus(status);

  return (
    <div style={{ ...styles.back, backgroundColor }}>
      {initials}
    </div>
  );
};

const styles = {
  back: {
    width: '15%',
    marginRight: 'auto',
    borderRadius:'10px',
    marginLeft: '1%',
    height: '25px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '13px',
    fontWeight: '300',
  },
  
};

export default Active;
