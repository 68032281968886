import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Button, Modal, OverlayTrigger, Tooltip  } from "react-bootstrap";
import { _get, _post } from "../../../apiClient";
import { FaDownload } from "react-icons/fa";
import "../../../assets/scss/custom/components/menu-style/fullmenu.scss";
import Loader from "../Loader";
// images
import BreakFast from "../../../assets/images/MenuList/dosa.png";
import Lunch from "../../../assets/images/MenuList/meals.png";
import Dinner from "../../../assets/images/MenuList/idly.png";
import App from "../../../assets/images/MenuList/app-display.png";

const FullMenu = () => {
  const [menu, setMenu] = useState([]);
  const [today, setToday] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [selectedMeal, setSelectedMeal] = useState('');
  const [selectedFoodType, setSelectedFoodType] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [quantities, setQuantities] = useState({
    breakfast: 0,
    lunch: 0,
    dinner: 0
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const [isLoading, setIsLoading] = useState(true); // Initialize loader state to true

  const nextPageRef = useRef(null);

  useEffect(() => {
    fetchMenu();
  }, []);

  useEffect(() => {
    // Set default date when menu is fetched
    if (menu.length > 0) {
      setToday({
        dayOrder: menu[0].dayOrder,
        date: menu[0].date
      });
    }
  }, [menu]);

  const fetchMenu = async () => {
    setIsLoading(true);

    try {
      const response = await _get("/menu/1");
      console.log('response', response);
      const responseData = response.data.data;
      if (Array.isArray(responseData)) {
        setMenu(responseData);
      } else {
        console.error("Expected an array but got:", responseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally {
      setIsLoading(false); // Hide loader after fetching data
  }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    if (nextPageRef.current) {
      nextPageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(menu.length / itemsPerPage)) {
      handlePageChange(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedMenu = menu.slice(startIndex, startIndex + itemsPerPage);

  const downloadMenuPdf = async () => {
    try {
      const response = await _get("/menupdf");
      const menuPdfUrl = response.data.data.menuPdfUrl;

      // Fetch the PDF as a blob
      const pdfResponse = await fetch(menuPdfUrl);
      const blob = await pdfResponse.blob();

      // Create a temporary anchor element to initiate the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "menu.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error fetching PDF URL:", error);
    }
  };

  const handleIncrement = (item) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [item]: prevQuantities[item] + 1
    }));
  };

  const handleDecrement = (item) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [item]: Math.max(prevQuantities[item] - 1, 0)
    }));
  };

  const handleCancel = async () => {
    try {
      const cancelData = {
        addcount: quantities[selectedFoodType],
        dayOrder: today.dayOrder,
        foodType: selectedFoodType,
        cancelled: true,
        foodneeddate: today.date
      };
      const response = await _post('addfood', cancelData);
      setResponseMessage(`${response.data.msg}`);
      setShowResponseModal(true);
    } catch (error) {
      console.error(error);
    }
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [selectedFoodType]: 0
    }));
    closeCancelModal();
  };

  const addFoods = async () => {
    try {
      const addData = {
        addcount: quantities[selectedFoodType],
        dayOrder: today.dayOrder,
        foodType: selectedFoodType,
        cancelled: false,
        foodneeddate: today.date
      };
      const response = await _post('addfood', addData);
      setResponseMessage(`${response.data.msg}`);
      setShowResponseModal(true);
    } catch (error) {
      console.error(error);
    }
    closeModal();
  };

  const openModal = (item, foodType) => {
    setSelectedMeal(item);
    setSelectedFoodType(foodType);
    setToday({
      dayOrder: item.dayOrder,
      date: item.date
    });
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [foodType]: 0,
    }));
    setShowAddModal(true);
  };

  const closeModal = () => {
    setShowAddModal(false);
    setSelectedMeal("");
    setSelectedFoodType("");
  };

  const openCancelModal = (item, foodType) => {
    setSelectedMeal(item);
    setSelectedFoodType(foodType);
    setToday({
      dayOrder: item.dayOrder,
      date: item.date
    });
    setShowCancelModal(true);
  };

  const closeCancelModal = () => {
    setShowCancelModal(false);
    setSelectedMeal("");
    setSelectedFoodType("");
  };

  const closeResponseModal = () => {
    setShowResponseModal(false);
    setResponseMessage("");
  };
  const viewTooltip = (
    <Tooltip id="view-tooltip">
        Comimg Soon!!
    </Tooltip>
);
const downTooltip = (
  <Tooltip id="view-tooltip">
      Download Menu!!
  </Tooltip>
);

  return (
    <>
    {isLoading ? (
     <Loader />
 ) : (
    <div className="container">
      <Row>
        <Col lg="8">
          <div ref={nextPageRef}>
            <div className="d-flex justify-content-between mb-5">
              <div>
                <h4 className="card-title list-main daily-tilte">Day Menu List</h4>
              </div>
              <div>
              <OverlayTrigger placement="top" overlay={downTooltip}>
                <h4 className="download-icon"  data-toggle="tooltip" data-placement="top" onClick={downloadMenuPdf}>
                  <FaDownload />
                </h4>
                </OverlayTrigger>
              </div>
            </div>

            {menu.length === 0 ? (
              <p className="text-center no-date">No data found</p>
            ) : (
              paginatedMenu.map((item) => (
                <div key={item.dayOrder}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-left">
                      <h3 className="day-order">{`Day ${item.dayOrder}`}</h3>
                    </div>
                  </div>
                  <Card style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                    <Card.Body>
                      <div className="main-list">
                        <div>
                          <img src={BreakFast} alt="BreakFast" />
                        </div>
                        <div className="brek-p">
                          <li>BreakFast</li>
                          <p>{item.foodDetails.breakfast.items}</p>
                        </div>
                        <div className="dinner-sec">
                        
                        </div>
                      </div>
                      <hr />
                      <div className="main-list">
                        <div>
                          <img src={Lunch} alt="Lunch" />
                        </div>
                        <div className="brek-p">
                          <li>Lunch</li>
                          <p className="ellipsis-text">{item.foodDetails.lunch.items}</p>
                        </div>
                        <div className="dinner-sec">
                          
                        </div>
                      </div>
                      <hr />
                      <div className="main-list">
                        <div>
                          <img src={Dinner} alt="Dinner" />
                        </div>
                        <div className="brek-p">
                          <li>Dinner</li>
                          <p>{item.foodDetails.dinner.items}</p>
                        </div>
                        <div className="dinner-sec">
                        
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))
            )}

            <div className="d-flex justify-content-between align-items-center mb-3">
              <Button
                variant="primary"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                Prev
              </Button>
              <span>{`${currentPage} / ${Math.ceil(
                menu.length / itemsPerPage
              )}`}</span>
              <Button
                variant="primary"
                onClick={handleNextPage}
                disabled={currentPage === Math.ceil(menu.length / itemsPerPage)}
              >
                Next
              </Button>
            </div>
          </div>
        </Col>
        <Col lg="4" className='ryt-sec'>
          <div>
            <Card className="app-pro" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
              <div className="app-body">
                <p className="text-center">
                  Try out our new mobile app
                </p>
                <div className="app-banner">
                  <img src={App} alt="App Banner" />
                </div>
                <OverlayTrigger placement="top" overlay={viewTooltip}>
                <a href="/">
                  <Button className="app-btn" data-toggle="tooltip" data-placement="top">Explore</Button>
                </a>
                </OverlayTrigger>
              </div>
            </Card>
          </div>
        </Col>
      </Row>

      <Modal show={showAddModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Extra Food</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Are you sure confirm add Extra Food?</h6>
          <div className="d-flex m-3">
          <Button
              variant="outline-danger"
              className="ms-2 me-2"
              onClick={() => handleDecrement(selectedFoodType)}
            >
              <i className="fas fa-minus"></i>
            </Button>
            <span className="p-2">{quantities[selectedFoodType]}</span>
            <Button
              variant="outline-success"
              className="me-2"
              onClick={() => handleIncrement(selectedFoodType)}
            >
              <i className="fas fa-plus"></i>
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={addFoods}>
            Confirm
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCancelModal} onHide={closeCancelModal}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Food</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Are you sure you want to cancel the food?</h6>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCancel}>
            Confirm
          </Button>
          <Button variant="secondary" onClick={closeCancelModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showResponseModal} onHide={closeResponseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{responseMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeResponseModal}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
     )}  
        </>
  );
};

export default FullMenu;
