import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Card from "../../../components/card";
import { _get, _post } from "../../../apiClient";
import Avatar from "./avatar/avatar";
import DateFomats from "./Pipe/dateFormater";
import Status from "./Pipe/badge";
import Toggle from "./Pipe/subcription";
import Loader from "../Loader";
import { Padding } from "@mui/icons-material";

const UserList = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [filter, setFilter] = useState('all');
  const [message, setMessage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPlantype, setSelectedPlan] = useState('Monthly'); // Default plan type


  const history = useHistory();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  useEffect(() => {
    filterUsers(data);
    setCurrentPage(1);
  }, [filter]);

  useEffect(() => {
    paginate(currentPage);
  }, [filteredData]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await _get("userlist");
      if (response.data.status === "success") {
        const responseData = response.data.data || [];
        console.log('responseData', responseData)
        responseData.sort((a, b) => {
          const dateA = new Date(a.expirydate);
          const dateB = new Date(b.expirydate);
          return dateA - dateB;
        });
        setData(responseData);
        filterUsers(responseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterUsers = (data) => {
    let filtered = [...data];
    if (filter !== 'all') {
      filtered = filtered.filter(user => user.status === filter);
    }
    setFilteredData(filtered);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    const indexOfLastRecord = pageNumber * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    setUserList(filteredData.slice(indexOfFirstRecord, indexOfLastRecord));
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleSubscribe = async (index, setMessage, selectedPlantype, startDate, endDate) => {
    const updatedUsers = [...userList];
    if (updatedUsers[index]) {
      try {
        const payload = {
          value: true,
          phone: updatedUsers[index].phone,
          userId: updatedUsers[index].userId,
          planType: selectedPlantype.plan,
          startfood: 'breakfast',
          expiryfood: 'dinner',
          startdate:selectedPlantype.startDate,
          expirydate:selectedPlantype.endDate
        };

        if (selectedPlantype === 'Custom') {
          payload.startdate = startDate;
          payload.expirydate = endDate;
        }

        const subscribe = await _post("suscribe", payload);
        if (subscribe.data.status === "success") {
          fetchData();
          updatedUsers[index].issubscribed = true;
          setUserList(updatedUsers);
          setMessage("Successfully subscribed!");
        } else {
          setMessage("Subscription failed: " + subscribe.data.msg);
        }
      } catch (error) {
        setMessage("Error subscribing user: " + error.message);
      }
    } else {
      setMessage("User not found at index: " + index);
    }
  };

  const handleUnsubscribe = async (index, setMessage, selectedPlantype) => {
    const updatedUsers = [...userList];
    if (updatedUsers[index]) {
      try {
        const unsubscribe = await _post("suscribe", {
          value: false,
          phone: updatedUsers[index].phone,
          userId: updatedUsers[index].userId,
          planType: 'Monthly'
        });
        if (unsubscribe.data.status === "success") {
          updatedUsers[index].issubscribed = false;
          fetchData();
          setUserList(updatedUsers);
          setMessage("Successfully unsubscribed!");
        } else {
          setMessage("Unsubscription failed: " + unsubscribe.data.message);
        }
      } catch (error) {
        setMessage("Error unsubscribing user: " + error.message);
      }
    } else {
      setMessage("User not found at index: " + index);
    }
  };

  const handleEditUser = (user, mode = "edit") => {
    history.push({
      pathname: "/app/user-add",
      state: { user, mode },
    });
  };

  const prevPage = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const nextPage = () => {
    const totalPages = Math.ceil(filteredData.length / recordsPerPage);
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  const editTooltip = (
    <Tooltip id="edit-tooltip">
      Edit
    </Tooltip>
  );
  const viewTooltip = (
    <Tooltip id="view-tooltip">
      View
    </Tooltip>
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container">
          <Row>
            <Col sm="12">
              <Card>
                <Card.Header className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                  <div className="mb-3 mb-sm-0">
                    <Card.Header.Title>
                      <h4 className="card-title mb-0 daily-tilte">User List</h4>
                    </Card.Header.Title>
                  </div>
                  <div className="d-flex align-items-center">
                    <Form.Select onChange={handleFilterChange} className="w-auto me-3 mb-3 mb-sm-0">
                      <option value="all">All</option>
                      <option value="New user">New user</option>
                      <option value="Expired user">Expired user</option>
                      <option value="Subscribed user">Subscribed user</option>
                      <option value="Unsubscribed user">Unsubscribed user</option>
                    </Form.Select>
                    <Link
                      className="btn btn-sm btn-icon btn-success"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Add"
                      to="/app/user-add"
                      style={{
                        color: '#fff',
                        padding: '0.5rem 1rem',
                        borderRadius: '0.25rem',
                        textDecoration: 'none',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      +Add
                    </Link>
                  </div>
                </Card.Header>
                <Card.Body className="px-0">
                  <div className="table-responsive">
                    <table
                      id="user-list-table"
                      className="table "
                      role="grid"
                      data-toggle="data-table"
                    >
                      <thead>
                        <tr className="ligth text-center">
                          <th>Profile</th>
                          <th>Name</th>
                          <th>Contact</th>
                          <th>Status</th>
                          <th>Expiry Date</th>
                          <th>Expiry Food</th>
                          <th>Join Date</th>
                          <th>Subscription</th>
                          <th min-width="100px">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userList.length === 0 ? (
                          <tr>
                            <td colSpan="9" className="text-center no-date pt-5">No data found</td>
                          </tr>
                        ) : (
                          userList.map((user, index) => (
                            <tr key={index} className="text-center">
                              <td>
                                <Avatar
                                  firstName={user.fname}
                                  lastName={user.lname}
                                />
                              </td>
                              <td>{user.fname + " " + user.lname}</td>
                              <td>{user.phone}</td>
                              <td>
                                <Status status={user.status} />
                              </td>
                              <td>
                                <DateFomats date={user.expirydate} />
                              </td>
                              <td>{user.expiryfood ? user.expiryfood : "--"}</td>
                              <td>
                                <DateFomats date={user.createdAt} />
                              </td>
                              <td>
                                <Toggle
                                  isSubscribed={user.issubscribed}
                                  onSubscribe={(setMessage, selectedPlan, startDate, endDate) => handleSubscribe(index, setMessage, selectedPlan, startDate, endDate)}
                                  onUnsubscribe={(setMessage) => handleUnsubscribe(index, setMessage)}
                                  userName={user.fname + " " + user.lname}
                                  userPhone={user.phone}
                                  onEditUser={() => handleEditUser(user)}
                                  errorMessage={message}
                                  selectedPlanoption={selectedPlantype}
                                  onPlanChange={(plan) => setSelectedPlan(plan)}
                                />


                              </td>
                              <td>
                                <div className="flex align-items-center list-user-action">
                                  <OverlayTrigger placement="top" overlay={viewTooltip}>
                                    <Button
                                      className="btn btn-sm btn-icon btn-success m-1"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      onClick={() => handleEditUser(user, "read")}
                                    >
                                      <span className="btn-inner">
                                        <svg
                                          width="32"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                        </svg>
                                      </span>
                                    </Button>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement="top" overlay={editTooltip}>
                                    <Button
                                      className="btn btn-sm btn-icon btn-warning m-1"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      onClick={() => handleEditUser(user, "edit")}
                                    >
                                      <span className="btn-inner">
                                        <svg
                                          width="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.544L8.10212 16.912L8.82812 10.921Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            d="M15.1655 4.60254L19.7315 9.16854"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                        </svg>
                                      </span>
                                    </Button>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
                <Card.Footer>
                  {filteredData.length > recordsPerPage && (
                    <div className="d-flex justify-content-center">
                      <Button onClick={prevPage} disabled={currentPage === 1} className="m-2">
                        Prev
                      </Button>
                      <div className="text-center m-2 p-2">
                        {currentPage}/{Math.ceil(filteredData.length / recordsPerPage)}
                      </div>
                      <Button
                        onClick={nextPage}
                        disabled={currentPage === Math.ceil(filteredData.length / recordsPerPage)}
                        className="m-2"
                      >
                        Next
                      </Button>
                    </div>
                  )}
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default UserList;
