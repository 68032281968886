import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const MapComponent = () => {
  const center = { lat: 11.025216962892983, lng: 76.99718378484249 };
  const radius = 8000; // 8 km radius

  const [currentLocation, setCurrentLocation] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCurrentLocation(userLocation);

          if (window.google && window.google.maps) {
            const centerLatLng = new window.google.maps.LatLng(center.lat, center.lng);
            const userLatLng = new window.google.maps.LatLng(userLocation.lat, userLocation.lng);
            const distance = window.google.maps.geometry.spherical.computeDistanceBetween(centerLatLng, userLatLng);

            if (distance <= radius) {
               toast.success(`Delivery boy successfully)`)
            } else {
              toast.error(`Delivery not Available your location`)
            }
          } else {
            toast.success(`Delivery boy successfully`)
          }
        },
        () => {
          NotificationManager.error('Unable to retrieve your location.');
        }
      );
    }
  }, []);

  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.1619310363867!2d77.00495337387295!3d11.02647468913791!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8590271d10a13%3A0xb820ae3fa508a2ed!2sPelamadu!5e0!3m2!1sen!2sin!4v1722334031207!5m2!1sen!2sin"
        width="100%"
        height="500px"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      {currentLocation && (
        <div>
          <p>Your location: {currentLocation.lat}, {currentLocation.lng}</p>
        </div>
      )}
            <ToastContainer />
    </div>
  );
};

export default MapComponent;
