import React from 'react';
// Import routing components from react-router
import { Switch, Route, Redirect } from 'react-router';
// Import layout components
import Default from '../layouts/dashboard/default';
import Simple from '../layouts/dashboard/simple';

const IndexRouters = () => {
  // Check for the token in localStorage
  const token = localStorage.getItem('token');

  return (
    <Switch>
      {/* If there is no token, redirect to the /auth route */}
      {!token && <Redirect from="/" to="/auth/sign-in" exact />}

      {/* Define routes for your application */}
      <Route path="/auth" component={Simple}></Route>
      <Route path="/errors" component={Simple}></Route>

      {/* If there is a token, show the Default layout */}
      {token && <Route path="/" component={Default}></Route>}

      {/* Optionally, add a catch-all route for undefined routes */}
      {!token && <Redirect to="/auth/sign-in" />}
    </Switch>
  );
};

export default IndexRouters;
