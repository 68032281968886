import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Table, Modal, Form } from 'react-bootstrap';
import Card from '../../components/card'; // Assuming this is your custom Card component
import { _get, _post } from '../../apiClient';
import Loader from '../dashboard/Loader';
import Avatar from '../dashboard/app/avatar/avatar';

const UnsubscriptionList = () => {
    const [unsubscriptions, setUnsubscriptions] = useState([]);
    const [filteredUnsubscriptions, setFilteredUnsubscriptions] = useState([]);
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [selectedUnsubscription, setSelectedUnsubscription] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [responseModalShow, setResponseModalShow] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [filter, setFilter] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const token = localStorage.getItem("token");

    useEffect(() => {
        fetchUnsubscription();
    }, [token, currentPage]);

    const fetchUnsubscription = async () => {
        setIsLoading(true);

        if (token) {
            _get(`/unsubscribereq?page=${currentPage}&limit=${itemsPerPage}`, token)
                .then(response => {
                    console.log('response', response)
                    if (response.data.status === 'success') {
                        setUnsubscriptions(response.data.data);
                        setFilteredUnsubscriptions(response.data.data); // Initialize filteredUnsubscriptions
                    } else {
                        setResponseMessage('Failed to fetch unsubscriptions');
                        setResponseModalShow(true);
                    }
                })
                .catch(() => {
                    setResponseMessage('Failed to fetch unsubscriptions');
                    setResponseModalShow(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleFilterChange = (event) => {
        const selectedFilter = event.target.value;
        setFilter(selectedFilter);
        if (selectedFilter === 'all') {
            setFilteredUnsubscriptions(unsubscriptions);
        } else {
            setFilteredUnsubscriptions(unsubscriptions.filter(sub => sub.planType === selectedFilter));
        }
        setCurrentPage(1); // Reset to first page when filter changes
    };

    const handleAcceptClick = (unsubscription) => {
        setSelectedUnsubscription(unsubscription);
        setShowAcceptModal(true);
    };

    const handleRejectClick = (unsubscription) => {
        setSelectedUnsubscription(unsubscription);
        setShowRejectModal(true);
    };

    const handleAccept = async () => {
        try {
            const response = await _post('/suscribe', { value: false, phone: selectedUnsubscription.user.phone, unSubscribeId: selectedUnsubscription.unSubscribeId, status: 'accept' }, token);
            if (response.data.status = 'success') {
                setResponseMessage(response.data.msg);
                // Optionally refresh the list
                setUnsubscriptions(unsubscriptions.filter(sub => sub.requestId !== selectedUnsubscription.requestId));
                setFilteredUnsubscriptions(filteredUnsubscriptions.filter(sub => sub.requestId !== selectedUnsubscription.requestId));
            } else {
                setResponseMessage(response.data.msg);
            }
        } catch (error) {
            setResponseMessage('Failed to accept unsubscription');
        } finally {
            setShowAcceptModal(false);
            setResponseModalShow(true);
        }
    };

    const handleReject = async () => {
        try {
            await _post('suscribe', { value: false, phone: selectedUnsubscription.user.phone, unSubscribeId: selectedUnsubscription.unSubscribeId, status: 'reject' }, token);
            setResponseMessage('unsubscription rejected successfully');
            // Optionally refresh the list
            setUnsubscriptions(unsubscriptions.filter(sub => sub.requestId !== selectedUnsubscription.requestId));
            setFilteredUnsubscriptions(filteredUnsubscriptions.filter(sub => sub.requestId !== selectedUnsubscription.requestId));
        } catch (error) {
            setResponseMessage('Failed to reject unsubscription');
        } finally {
            setShowRejectModal(false);
            setResponseModalShow(true);
        }
    };

    const handleResponseModalClose = () => {
        setResponseModalShow(false);
        fetchUnsubscription();
        setResponseMessage('');
    };

    const totalPages = Math.ceil(filteredUnsubscriptions.length / itemsPerPage);
    const currentData = filteredUnsubscriptions.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="container">
                <Row>
                    <Col lg='12' md='12'>
                        <Card>
                            <Card.Header className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                                <div className="mb-3 mb-sm-0">
                                    <Card.Header.Title>
                                        <h4 className="card-title mb-0 daily-tilte">Unsubscription Request List</h4>
                                    </Card.Header.Title>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table id="user-list-table" className="table" role="grid" data-toggle="data-table">
                                        <thead>
                                            <tr className="ligth text-center">
                                                <th className="text-center">Profile</th>
                                                <th className="text-center">Name</th>
                                                <th className="text-center">Phone</th>
                                                <th className="text-center">Reason</th>
                                                <th className="text-center">Other</th>
                                                <th className="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                              <tr>
                                                <td colSpan="6" className="text-center">
                                                  <Loader /> {/* Loader component */}
                                                </td>
                                              </tr>
                                            ) :currentData.length === 0 ? (
                                                <tr>
                                                    <td colSpan="9" className="text-center no-date pt-5">No Requests found</td>
                                                </tr>
                                            ) : (
                                                currentData.map(unsubscription => (
                                                    <tr key={unsubscription.requestId}>
                                                          <td className="text-center" style={{width:"5%",marginLeft:"auto",marginRight:'auto'}}>
                                                                <Avatar
                                                                    firstName={unsubscription.user.fname}
                                                                    lastName={unsubscription.user.lname}
                                                                />
                                                            </td>
                                                        <td className="text-center">
                                                            {unsubscription.user ? `${unsubscription.user.fname} ${unsubscription.user.lname}` : '-'}
                                                        </td>
                                                        <td className="text-center">
                                                            {unsubscription.user ? unsubscription.user.phone : 'N/A'}
                                                        </td>
                                                        <td className="text-center">{unsubscription.reasons ? `${unsubscription.reasons}`:'-'}</td>
                                                        <td className="text-center">{unsubscription.others ? `${unsubscription.others}` : '-'}</td>
                                                        <td className="d-flex justify-content-center">
                                                            <Button
                                                                className="btn btn-sm btn-icon btn-success m-1"
                                                                title="Accept"
                                                                onClick={() => handleAcceptClick(unsubscription)}
                                                            >
                                                                <span className="btn-inner">
                                                                    <svg
                                                                        width="20"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        stroke="currentColor"
                                                                    >
                                                                        <path
                                                                            d="M5 13l4 4L19 7"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            </Button>
                                                            <Button
                                                                className="btn btn-sm btn-icon btn-danger m-1"
                                                                title="Reject"
                                                                onClick={() => handleRejectClick(unsubscription)}
                                                            >
                                                                <span className="btn-inner">
                                                                    <svg
                                                                        width="20"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        stroke="currentColor"
                                                                    >
                                                                        <path
                                                                            d="M6 18L18 6M6 6l12 12"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </Table>
                                    <Card.Footer>
                                        {filteredUnsubscriptions.length > itemsPerPage && (
                                            <div className="d-flex justify-content-center">
                                                <Button onClick={prevPage} disabled={currentPage === 1} className="m-2">
                                                    Prev
                                                </Button>
                                                <div className="text-center m-2 p-2">
                                                    {currentPage}/{totalPages}
                                                </div>
                                                <Button
                                                    onClick={nextPage}
                                                    disabled={currentPage === totalPages}
                                                    className="m-2"
                                                >
                                                    Next
                                                </Button>
                                            </div>
                                        )}
                                    </Card.Footer>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                </div>
            )}

            {/* Accept Confirmation Modal */}
            <Modal show={showAcceptModal} onHide={() => setShowAcceptModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Accept</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to accept this unsubscription request?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAcceptModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={handleAccept}>
                        Accept
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Reject Confirmation Modal */}
            <Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Reject</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to reject this unsubscription request?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowRejectModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleReject}>
                        Reject
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Response Message Modal */}
            <Modal show={responseModalShow} onHide={handleResponseModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Response Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>{responseMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleResponseModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UnsubscriptionList;
