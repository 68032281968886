import React, { useEffect, useState } from 'react'
import { Row, Col, Card, } from 'react-bootstrap'
import SwiperCore, { Navigation } from 'swiper';
import { _get, _post } from '../../apiClient';
import MenuList1 from './MenuList/menuList';
import DateFormats from './app/Pipe/dateFormater';
import Active from './app/Pipe/avtive';
import 'swiper/swiper-bundle.css';

import totalIcon from '../../assets/images/User_Dashboard/icon Delivered.png'
import cancelIcon from '../../assets/images/User_Dashboard/Icon_Order.png'
import ExtraIcon from '../../assets/images/User_Dashboard/Group 148.png'
import Loader from './Loader';
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import PricingSection from '../../components/users/pricingSection';
import Cards from './ui-elements/cards';


SwiperCore.use([Navigation]);


const Index = () => {
    const [isLoading, setIsLoading] = useState(true); // Initialize loader state to true
    const [today, setToday] = useState([]);
    const [menu, setMenu] = useState([]);
    const [subscribe, setSubscribe] = useState([]);
    const [dashboard, setDashboard] = useState([]);
    const [address, setAddress] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showResponseModal, setShowResponseModal] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [selectedMeal, setSelectedMeal] = useState('');
    const [expanded, setExpanded] = useState(false);
    const [quantities, setQuantities] = useState({
        breakfast: 0,
        lunch: 0,
        dinner: 0
    });



    useEffect(() => {
        foodList();
        subscribeDetails();
        dashboardDetails();
        addressUser();
        if (typeof gsap !== typeof undefined) {
            if (typeof ScrollTrigger !== typeof undefined) {
                gsap.registerPlugin(ScrollTrigger);
            }

            const gsapAnimate = {
                onStart: (elem) => {
                    const option = {
                        opacity: 0,
                        scale: 1,
                        position: {
                            x: 0,
                            y: 0,
                        },
                        ease: "",
                        duration: 1,
                        delay: .4,
                        rotate: 0
                    }

                    option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0)

                    option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0)

                    option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0)

                    option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1)

                    option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0)

                    option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, .4)

                    option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5)

                    option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '')

                    const setOption = { opacity: option.opacity, scale: option.scale, x: option.position.x, y: option.position.y, ease: option.ease, rotate: option.rotate, duration: option.duration, delay: option.delay }

                    if (typeof ScrollTrigger !== typeof undefined) {
                        if (elem.dataset.iqTrigger === 'scroll') {
                            const scrub = elem.dataset.iqScrollScrub === 'true' ? true : false

                            setOption.scrollTrigger = {
                                trigger: elem,
                                start: () => "top 95%",

                                scrub: scrub,
                                markers: false
                            }
                        }
                    }

                    gsap.from(elem, setOption)

                },

                validValue: (attr, defaultVal) => {
                    if (attr !== undefined && attr !== null) {
                        return attr
                    }
                    return defaultVal
                }
            }


            const gsapElem = document.querySelectorAll('[data-iq-gsap="onStart"]')

            Array.from(gsapElem, (elem) => {
                gsapAnimate.onStart(elem)
                return null
            })


            if (document.querySelectorAll('auth-login').length) {
                gsap.from('.first-img', { opacity: 0, scale: .8, rotate: -45, ease: "power1.out", duration: 1, delay: .4 })
                gsap.from('.second-img', { opacity: 0, scale: .8, rotate: -45, ease: "power1.out", duration: 1, delay: .6 })
                gsap.from('.third-img', { opacity: 0, scale: .8, rotate: -45, ease: "power1.out", duration: 1, delay: .8 })
                gsap.from('.fourth-img', { opacity: 0, scale: .8, rotate: -45, ease: "power1.out", duration: 1, delay: 1.2 })
                gsap.from('.fifth-img', { opacity: 0, scale: .8, rotate: -45, ease: "power1.out", duration: 1, delay: 1.4 })
                gsap.from('.six-img', { opacity: 0, scale: .8, rotate: -45, ease: "power1.out", duration: 1, delay: 1.6 })
                gsap.from('.seventh-img', { opacity: 0, scale: .8, rotate: -45, ease: "power1.out", duration: 1, delay: 1.6, })
                gsap.from('.eight-img', { opacity: 0, scale: .8, rotate: -45, ease: "power1.out", duration: 1, delay: 1.8, })
            }
        }


    }, []);

    const foodList = async () => {
        setIsLoading(true);
        try {
            const response = await _get('today');
            const responseData = response.data.data;
            setToday(responseData);
            setMenu(responseData.foodDetails);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false); // Hide loader after fetching data
        }
    };

    const subscribeDetails = async () => {
        try {
            const response = await _get('user');
            if (response.data.status === 'success') {
                const responseData = response.data.data;
                console.log('responseData', responseData)
                setSubscribe(responseData)
            }
        }
        catch {

        }
    }

    const dashboardDetails = async () => {
        try {
            const response = await _get('dashboard');
            if (response.data.status === 'success') {
                const responseData = response.data.data;
                setDashboard(responseData)
            }
        }
        catch {

        }
    }

    const addressUser = async () => {
        try {
            const response = await _get('address');
            if (response.data.status === 'success') {
                const responseData = response.data.data;
                setAddress(responseData[0])
            }
        }
        catch (error) {
            console.error('error', error)
        }
    }

    const toggleExpansion = () => {
        setExpanded(prevExpanded => !prevExpanded);
    };

    // Function to render truncated or full address based on expansion state
    const renderAddress = () => {
        const fullAddress = `${address.doorNo ? address.doorNo + ", " : ""}${address.street ? address.street + ", " : ""
        }${address.landMark ? address.landMark + ", " : ""}${address.city ? address.city + ", " : ""
        }${address.state ? address.state + ", " : ""}${address.pincode ? address.pincode : ""
        }`;

        if (expanded) {
            return (
                <p onClick={toggleExpansion} style={{ cursor: 'pointer' }}>
                    {fullAddress}
                </p>
            );
        } else {
            const maxLength = 38;
            const truncatedText = fullAddress.length > maxLength ? fullAddress.substring(0, maxLength - 3) + "..." : fullAddress;
            return (
                <p onClick={toggleExpansion} style={{ cursor: 'pointer' }}>
                    {truncatedText}
                </p>
            );
        }
    };
    
    const handleIncrement = (meal) => {
        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [meal]: prevQuantities[meal] + 1
        }));
    };

    const handleDecrement = (meal) => {
        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [meal]: Math.max(prevQuantities[meal] - 1, 0)
        }));
    };

    const handleCancel = async (meal) => {
        try {
            const foodneeddate = today.date;
            const dayOrder = today.dayOrder;
            const cancelData = {
                foodneeddate,
                dayOrder,
                foodType: meal,
                addcount: quantities[meal],
                cancelled: true
            };
            const response = await _post('addfood', cancelData);
            setResponseMessage(`${(response.data.msg)}`);
            setShowResponseModal(true);
        } catch (error) {
            console.error(error);
        }
        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [meal]: 0
        }));
    };

    const addFoods = async () => {
        try {
            const foodneeddate = today.date;
            const dayOrder = today.dayOrder;
            const addData = {
                foodneeddate,
                dayOrder,
                foodType: selectedMeal,
                addcount: quantities[selectedMeal],
                cancelled: false
            };
            const response = await _post('addfood', addData);
            setResponseMessage(`${response.data.msg}`);
            setShowResponseModal(true);
        } catch (error) {
            console.error(error);
        }
        closeModal();
    }

    const openModal = (meal) => {
        setSelectedMeal(meal);
        setShowAddModal(true);
    };

    const closeModal = () => {
        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [selectedMeal]: 0,
        }));
        setShowAddModal(false);
        setSelectedMeal("");
    };

    const closeResponseModal = () => {
        setShowResponseModal(false);
        setResponseMessage("");
    };




    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className='container'>
                    <Row>
                        <Col md="12" lg="8">
                            <h4 className="text-primary d-flex align-items-center mb-5">
                                <small className="me-2 sub-plan">Your Subscription Plan</small>
                                <Active status={subscribe.issubscribed} />
                            </h4>
                            <Row>
                                <Col lg="4" md="4" sm="12" className="banner-item">
                                    <Card style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                        <Card.Body className='food-det'>
                                            <div className='d-flex'>
                                                <div className="me-1 delivery-sec">
                                                    <img src={totalIcon} alt="Delivered Icon" />
                                                </div>
                                                <div className='mt-3 dash-detail'>
                                                    <Card.Title className='dash-num'>{dashboard.ordered}/{dashboard.total}</Card.Title>
                                                    <small><DateFormats date={dashboard.expirydate} /></small>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="4" md="4" sm="12" className="banner-item">
                                    <Card style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                        <Card.Body className='food-det'>
                                            <div className='d-flex'>
                                                <div className="me-3 delivery-sec">
                                                    <img src={cancelIcon} alt="Delivered Icon" />
                                                </div>
                                                <div className='mt-3 dash-detail'>
                                                    <Card.Title className='dash-num'>{dashboard.cancelCount}</Card.Title>
                                                    <small>Cancel</small>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg="4" md="4" sm="12" className="banner-item">
                                    <Card style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                        <Card.Body className='food-det'>
                                            <div className='d-flex'>
                                                <div className="me-3 delivery-sec">
                                                    <img src={ExtraIcon} alt="Delivered Icon" />
                                                </div>
                                                <div className='mt-3 dash-detail'>
                                                    <Card.Title className='dash-num'>{dashboard.addMore}</Card.Title>
                                                    <small>Extra Food</small>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="4" md="6" sm="12">
                            <Card style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                <Card.Body className="detail-sec" style={{ padding: "1.1rem 1.5rem" }}>
                                    <h4 className="text-primary d-flex align-items-center">
                                        <p>{subscribe.fname + " " + subscribe.lname}</p>
                                    </h4>
                                    <p>{subscribe.phone}</p>
                                    {renderAddress()}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <MenuList1 />
                    <Cards />
                    <PricingSection />
                </div>
            )}
        </>
    );
}

export default Index
