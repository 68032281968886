import React, { useState, useEffect, useRef } from 'react';
import RatingStar from '../../../components/partials/components/rating-star'
import DateFormats from '../app/Pipe/dateFormater';
import Avatar from '../app/avatar/avatar';
import { Row, Col, Card, Button, Form, Toast, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap'; // Import Alert from react-bootstrap
import { _get, _post, _put, _delete } from '../../../apiClient';
import { Link } from 'react-router-dom';
import Loader from '../Loader';
import { FaDownload } from "react-icons/fa";

// Images
import userp02 from '../../../assets/images/User-profile/02.png';
// import {Link} from 'react-router-dom';
// img
import cust1 from '../../../assets/images/menu/1.png'
import cust2 from '../../../assets/images/menu/2.png'
import cust3 from '../../../assets/images/menu/3.png'
import layout1 from '../../../assets/images/layouts/1.png'
import userp5 from '../../../assets/images/User-profile/5.png'
import { error } from 'jquery';

const MenuList = () => {
    const [menu, setMenu] = useState([]);
    const [availableFeedback, setAvailableFeedback] = useState([]);
    const [feedback, setFeedback] = useState([]);
    const [foodOptions, setFoodOptions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [feedcurrentPage, setFeedCurrentPage] = useState(1);
    const [feedbackPerPage] = useState(10);
    const itemsPerPage = 6;
    const [formData, setFormData] = useState({
        breakfast: '',
        lunch: '',
        dinner: ''
    });
    const [currentEditDayOrder, setCurrentEditDayOrder] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [successMessage, setSuccessMessage] = useState(''); // Define successMessage state
    const nextPageRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true); // Initialize loader state to true
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    useEffect(() => {
        fetchMenu();
        // feedBackList();
        fetchFoodOptions();
    }, []);

    const fetchMenu = async () => {
        setIsLoading(true);
        try {
            const response = await _get('/menu/1');
            const responseData = response.data.data;
            if (Array.isArray(responseData)) {
                setMenu(responseData);
            } else {
                console.error('Expected an array but got:', responseData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false); // Hide loader after fetching data
        }
    };

    const downloadMenuPdf = async () => {
        try {
            const response = await _get("/menupdf");
            const menuPdfUrl = response.data.data.menuPdfUrl;

            // Fetch the PDF as a blob
            const pdfResponse = await fetch(menuPdfUrl);
            const blob = await pdfResponse.blob();

            // Create a temporary anchor element to initiate the download
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "menu.pdf";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error fetching PDF URL:", error);
        }
    };


    const fetchFoodOptions = async () => {
        try {
            const response = await _get('/food');
            const responseData = response.data.data;
            if (Array.isArray(responseData)) {
                setFoodOptions(responseData);
            } else {
                console.error('Expected an array but got:', responseData);
            }
        } catch (error) {
            console.error('Error fetching food options:', error);
        }
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(menu.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
        if (nextPageRef.current) {
            nextPageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
        if (nextPageRef.current) {
            nextPageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const canSubmit = () => {
        return formData.breakfast && formData.lunch && formData.dinner;
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const newMenu = {
            menuName: 'Daily',
            menu: [
                {
                    menuDate: currentEditDayOrder,
                    breakfast: parseInt(formData.breakfast),
                    lunch: parseInt(formData.lunch),
                    dinner: parseInt(formData.dinner)
                }
            ]
        };
        setIsButtonLoading(true);

        try {
            if (currentEditDayOrder !== null) {
                // Update existing menu
                const response = await _put(`/menu/1`, newMenu);
                if (response.data.status === 'success') {
                    fetchMenu();
                    setCurrentEditDayOrder(null);
                    setSuccessMessage('Menu updated successfully'); // Set success message
                } else {
                    console.error('Failed to update menu:', response);
                }
            } else {
                // Create new menu
                const response = await _post('/menu', newMenu);
                if (response.data.status === 'success') {
                    fetchMenu();
                    setSuccessMessage('Menu created successfully'); // Set success message
                } else {
                    console.error('Failed to create menu:', response);
                }
            }
            // Reset form
            setFormData({
                breakfast: '',
                lunch: '',
                dinner: ''
            });
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
                setSuccessMessage('');
            }, 3000);
        } catch (error) {
            console.error('Error creating/updating menu:', error);
        }finally {
            setIsButtonLoading(false);
        }
    };

    const handleEditMenu = (date, item) => {
        setCurrentEditDayOrder(date);
        setFormData({
            breakfast: item.foodDetails.breakfast.foodId || '',
            lunch: item.foodDetails.lunch.foodId || '',
            dinner: item.foodDetails.dinner.foodId || ''
        });
        if (nextPageRef.current) {
            nextPageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleDeleteMenu = async (dayOrder) => {
        const userConfirmed = window.confirm("Are you sure you want to delete this food item?");
        if (userConfirmed) {
            setIsButtonLoading(true);
            try {
                const response = await _delete(`/menu/${dayOrder}`);
                if (response.data.status === 'success') {
                    alert('Food was deleted successfully');
                    fetchMenu(); // Refresh menu after deletion
                    setShowToast(true); // Optional: Show a success toast
                    setTimeout(() => setShowToast(false), 3000);
                } else {
                    console.error('Failed to delete menu:', response);
                }
            } catch (error) {
                console.error('Error deleting menu:', error);
            }finally {
                setIsButtonLoading(false);
            }
        }
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedMenu = menu.slice(startIndex, startIndex + itemsPerPage);

    const indexOfLastfeedback = feedcurrentPage * feedbackPerPage;
    const indexOfFirstfeedback = indexOfLastfeedback - feedbackPerPage;
    const currentFeedback = availableFeedback.slice(indexOfFirstfeedback, indexOfLastfeedback);

    const nextPage = () => {
        if (feedcurrentPage < Math.ceil(availableFeedback.length / feedcurrentPage)) {
            setFeedCurrentPage(feedcurrentPage + 1);
        }
    };

    const prevPage = () => {
        if (feedcurrentPage > 1) {
            setFeedCurrentPage(feedcurrentPage - 1);
        }
    };
    const downTooltip = (
        <Tooltip id="view-tooltip">
            Download Menu!!
        </Tooltip>
      );
      
    // Filter food options by type
    const breakfastOptions = foodOptions.filter(option => option.foodType.includes('breakfast'));
    const lunchOptions = foodOptions.filter(option => option.foodType.includes('lunch'));
    const dinnerOptions = foodOptions.filter(option => option.foodType.includes('dinner'));

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className='container'>
                    <Row>
                        <Col lg="6">
                            <div ref={nextPageRef}>
                                <Card>
                                    <Card.Header className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h4 className="card-title list-main daily-tilte">Day Menu List</h4>
                                        </div>
                                        <div>
                                        <OverlayTrigger placement="top" overlay={downTooltip}>
                                            <h4 className="download-icon"  data-toggle="tooltip" data-placement="top" onClick={downloadMenuPdf}>
                                                <FaDownload />
                                            </h4>
                                        </OverlayTrigger>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        {menu.length === 0 ? (
                                            <p className="text-center no-date pt-5">No data found</p>
                                        ) : (paginatedMenu.map((item) => (
                                            <div key={item.dayOrder}>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className='text-left'>
                                                        <h5>{`Day ${item.dayOrder}`}</h5>
                                                    </div>
                                                    <div className='text-right'>
                                                        <Button className="btn btn-sm btn-icon btn-warning m-2" onClick={() => handleEditMenu(item.date, item)}>
                                                            <span className="btn-inner">
                                                                <svg width="20" viewBox="0 0 24 24" fill="none">
                                                                    <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                            </span>
                                                        </Button>
                                                        {/* <Button variant="danger" className="btn btn-sm btn-icon" onClick={() => handleDeleteMenu(item.dayOrder)}>
                                                            <span className="btn-inner">
                                                                <svg width="20" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                                                    <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                            </span>
                                                        </Button> */}
                                                    </div>
                                                </div>
                                                <h6 className='pt-3'>Breakfast:</h6>
                                                <p>{item.foodDetails.breakfast.items}</p>
                                                <h6>Lunch:</h6>
                                                <p>{item.foodDetails.lunch.items}</p>
                                                <h6>Dinner:</h6>
                                                <p>{item.foodDetails.dinner.items}</p>
                                                <hr />
                                            </div>
                                        ))
                                        )}
                                        <div className="d-flex justify-content-between align-items-center">
                                            <Button variant="primary" onClick={handlePrevPage} disabled={currentPage === 1}>
                                                Prev
                                            </Button>
                                            <span>{`${currentPage} / ${Math.ceil(menu.length / itemsPerPage)}`}</span>
                                            <Button variant="primary" onClick={handleNextPage} disabled={currentPage === Math.ceil(menu.length / itemsPerPage)}>
                                                Next
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col lg="6" className='ryt-sec'>
                            <Card style={{ background: `url(${userp02})`, backgroundSize: "cover" }}>
                                <Card.Body>
                                    <div className='text-center'>
                                        <h2 className="text-primary">{currentEditDayOrder ? 'Edit Menu' : 'Edit Menu'}</h2>
                                    </div>
                                    {successMessage && ( // Render Alert if successMessage is defined
                                        <Alert variant="success" dismissible onClose={() => setSuccessMessage('')}>
                                            {successMessage}
                                        </Alert>
                                    )}
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formBreakfast">
                                            <Form.Label>Breakfast</Form.Label>
                                            <Form.Control as="select" name="breakfast" value={formData.breakfast} onChange={handleChange} disabled={!canSubmit()}>
                                                <option value="">Select Breakfast</option>
                                                {breakfastOptions.map((option) => (
                                                    <option key={option.foodId} value={option.foodId}>{option.items}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="formLunch">
                                            <Form.Label>Lunch</Form.Label>
                                            <Form.Control as="select" name="lunch" value={formData.lunch} onChange={handleChange} disabled={!canSubmit()}>
                                                <option value="">Select Lunch</option>
                                                {lunchOptions.map((option) => (
                                                    <option key={option.foodId} value={option.foodId}>{option.items}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="formDinner">
                                            <Form.Label>Dinner</Form.Label>
                                            <Form.Control as="select" name="dinner" value={formData.dinner} onChange={handleChange} disabled={!canSubmit()}>
                                                <option value="">Select Dinner</option>
                                                {dinnerOptions.map((option) => (
                                                    <option key={option.foodId} value={option.foodId}>{option.items}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                        <Button variant="primary" type="submit" className='mt-3' disabled={!canSubmit() || isButtonLoading }>
                                            {isButtonLoading ? (
                                                    <span>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp; Loading...
                                                    </span>
                                                ) : (
                                                    currentEditDayOrder ? 'Update Menu' : 'Update Menu'
                                                )}
                                               
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default MenuList;
